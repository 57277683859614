import React from "react";
import { List, Datagrid, TextField } from "react-admin";
import { ExportButton } from "react-admin";
import Toolbar from "@material-ui/core/Toolbar";
import {
  Create,
  Edit,
  SimpleForm,
  DateField,
  CreateButton,
  EditButton,
  required,
  SelectInput,
  ReferenceInput,
  FormDataConsumer,
  Labeled,
  ShowController,
  FunctionField,
  TextInput,
  ImageField,
  ImageInput,
  Show,
  SimpleShowLayout,
} from "react-admin";

export const FrontList = (props) => {
  return (
    <React.Fragment>
      <List
        {...props}
        actions={<PostActions />}
        bulkActionButtons={false}
        title="Front Pages"
        sort={{ field: "priority", order: "ASC" }}
        //   filters={<PostFilter />}
      >
        <Datagrid>
          <TextField label="Title" source="title" />
          <TextField label="Subtitle" source="sub_title" />
          <FunctionField
            label="Description"
            render={(record) =>
              record.description.length > 25
                ? `${record.description.substring(0, 55)}...`
                : record.description
            }
          />
          <TextField label="Code" source="code" />
          <TextField label="Lang" source="lang" />

          <DateField
            locales="en-TT"
            label="Created"
            source="created_at"
            showTime
          />
          <DateField
            locales="en-TT"
            label="Updated"
            source="updated_at"
            showTime
          />
          <EditButton />
        </Datagrid>
      </List>
    </React.Fragment>
  );
};

const PostActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <Toolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

export const FrontCreate = (props) => (
  <Create {...props} title="Add Front Pages">
    <SimpleForm redirect="list">
      <TextInput label="Title" source="title" validate={[required()]} />
      <TextInput label="Subtitle" source="sub_title" validate={[required()]} />
      <TextInput
        label="Description"
        source="description"
        validate={[required()]}
        multiline
        fullWidth
      />
      <TextInput label="Code" source="code" />
      <SelectInput
        label="Language"
        source="lang"
        choices={[
          { id: "ENG", name: "English" },
          { id: "ID", name: "Indonesia" },
        ]}
      />
      <ImageInput
        source="pictures"
        label="Cover Image"
        accept="image/*"
        validate={[required()]}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <SelectInput
        label="Priority Position"
        source="position"
        choices={[
          { id: "before", name: "Before" },
          { id: "after", name: "After" },
        ]}
      />

      <ReferenceInput
        label="Priority Anchor"
        source="priorityValue"
        reference="front_pages"
        perPage={100000}
        sort={{ field: "priority", order: "ASC" }}
      >
        <SelectInput optionText="title" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const FrontEdit = (props) => {
  // console.log(props);
  return (
    <ShowController {...props}>
      {(controllerProps) => (
        <Edit
          {...props}
          {...controllerProps}
          undoable={false}
          title="Edit Front Pages"
        >
          <SimpleForm>
            <TextInput label="Title" source="title" validate={[required()]} />
            <TextInput
              label="Subtitle"
              source="sub_title"
              validate={[required()]}
            />
            <TextInput
              label="Description"
              source="description"
              validate={[required()]}
              multiline
              fullWidth
            />
            <TextInput label="Code" source="code" />
            <SelectInput
              label="Language"
              source="lang"
              choices={[
                { id: "ENG", name: "English" },
                { id: "ID", name: "Indonesia" },
              ]}
            />
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => {
                if (!formData.pictures) {
                  return (
                    <Labeled label="Current Cover Image">
                      <ImageField source="img_cover" {...rest} />
                    </Labeled>
                  );
                }
              }}
            </FormDataConsumer>
            <ImageInput source="pictures" label="Cover Image" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
            <SelectInput
              label="Priority Position"
              source="position"
              choices={[
                { id: "before", name: "Before" },
                { id: "after", name: "After" },
              ]}
            />

            <ReferenceInput
              label="Priority Anchor"
              source="priorityValue"
              reference="front_pages"
              perPage={100000}
              sort={{ field: "priority", order: "ASC" }}
            >
              <SelectInput optionText="title" />
            </ReferenceInput>
          </SimpleForm>
        </Edit>
      )}
    </ShowController>
  );
};

const UserField = ({ record = {} }) => (
  <span>{record.user_id === null ? "Anonymous" : record.users.name}</span>
);
UserField.defaultProps = { label: "User", addLabel: true };

export const PromotedShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <Show {...props} {...controllerProps} title="Detail Promoted Courses">
        <SimpleShowLayout>
          <TextField label="Title" source="courses.title" />
          <TextField label="Description" source="courses.description" />
        </SimpleShowLayout>
      </Show>
    )}
  </ShowController>
);
