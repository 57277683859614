import React, { useState } from "react";
import { List, Datagrid, TextField } from "react-admin";
import Text from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

// import Button from '@material-ui/core/Button';
import { CreateButton, ExportButton } from "react-admin";
import Toolbar from "@material-ui/core/Toolbar";
import {
  Edit,
  SimpleForm,
  TextInput,
  DateField,
  EditButton,
  ArrayField,
  useRedirect,
  Show,
  SimpleShowLayout,
  useDataProvider,
  Loading,
  Error,
  Filter,
  useNotify,
  ShowController,
} from "react-admin";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Button } from "@material-ui/core";

export const Category = (props) => (
  <List
    {...props}
    actions={<PostActions />}
    bulkActionButtons={false}
    title="Category"
    filters={<PostFilter />}
  >
    <Datagrid rowClick="show">
      <TextField label="Name" source="name" />
      <DateField locales="en-TT" label="Created" source="created_at" showTime />
      <DateField locales="en-TT" label="Updated" source="updated_at" showTime />
      <EditButton />
    </Datagrid>
  </List>
);

const PostActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <Toolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

export const CategoryCreate = (props) => {
  const dataProvider = useDataProvider();
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [select, setSelected] = useState("category");
  const [selectCat, setSelectedCat] = useState("");
  const redirect = useRedirect();
  const notify = useNotify();

  const handleSave = async (e) => {
    e.preventDefault();
    let payload;
    console.log("here");
    try {
      if (select === "category") {
        payload = {
          data: {
            name,
            type: select,
          },
        };
      } else {
        payload = {
          data: {
            name,
            category_id: selectCat,
            type: select,
          },
        };
      }
      const data = await dataProvider.create("category", payload);
      console.log(data);
      notify("Add Category Success", "info");
      redirect("/category");
    } catch (error) {
      console.log(error);
    }
  };

  const fetch = async () => {
    try {
      const data = await dataProvider.getList("categoryNoLimit", {
        pagination: { page: "1", perPage: "1000" },
        sort: { field: "id", order: "asc" },
        filter: {
          activity: "false",
        },
      });
      setCategory(data);
      setLoading(false);
      console.log(data);
    } catch (error) {
      setError(error);
    }
  };

  React.useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!category) return null;

  return (
    <Paper style={{ padding: "20px" }}>
      <form onSubmit={(e) => handleSave(e)}>
        <Grid container direction="column">
          <FormControl style={{ marginBottom: "10px", width: "30%" }}>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={select}
              onChange={(e) => setSelected(e.target.value)}
            >
              <MenuItem value={"category"}>Category</MenuItem>
              <MenuItem value={"subcategory"}>Subcategory</MenuItem>
            </Select>
          </FormControl>

          {select === "subcategory" && (
            <FormControl style={{ marginBottom: "10px", width: "30%" }}>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectCat}
                onChange={(e) => setSelectedCat(e.target.value)}
                required
              >
                {category.data.map((x) => (
                  <MenuItem value={x.id}>{x.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <Text
            source="name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ marginBottom: "10px", width: "30%" }}
            required
          />
        </Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: "10px" }}
        >
          save
        </Button>
      </form>
    </Paper>
  );
};

export const CategoryEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

export const ExpandShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <Show {...props} title="Category" {...controllerProps}>
        <SimpleShowLayout>
          <TextField label="Category" source="name" />
          <ArrayField source="sub_categories.data" label="Subcategory">
            <Datagrid>
              <TextField source="name" label="Name" />
              <EditButton />
            </Datagrid>
          </ArrayField>
        </SimpleShowLayout>
      </Show>
    )}
  </ShowController>
);

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by name" source="name" alwaysOn />
  </Filter>
);
