import gql from "graphql-tag";

export const allContentItemsList = gql`
  query allContentItemsList(
    $limit: Int
    $offset: Int
    $sort: ContentItemsSortEnum
    $order: PaginationOrderEnum
    $filter: String
  ) {
    allContentItemsList(
      limit: $limit
      offset: $offset
      sort: $sort
      order: $order
      filter: $filter
    ) {
      data {
        id
        title
        theory
        priority
        created_at
        updated_at
        video {
          data {
            raw_file
            id
            item_id
            drm_video_status
            drm_video_metadata
            drm_video_id
            active
            watermark_color
            created_at
          }
          count
        }
        notes_content_item_id_list {
          data {
            note
          }
        }
      }
      count
    }
  }
`;

export const allCoursesList = gql`
  query allCoursesList(
    $limit: Int
    $offset: Int
    $sort: CoursesSortEnum
    $order: PaginationOrderEnum
    $filter: String
    $where: CoursesWhereInput
  ) {
    allCoursesList(
      limit: $limit
      offset: $offset
      sort: $sort
      order: $order
      filter: $filter
      where: $where
    ) {
      data {
        id
        title
        price
        created_at
        updated_at
        duration
        draft
        use_master_schedules
        tutor_id
        reviews(limit: 9999) {
          data {
            rating
          }
          count
        }
        category_id_data {
          name
        }
        level_id_data {
          name
        }
        course_contents_list {
          data {
            id
            title
          }
          count
        }
      }
      count
    }
  }
`;

export const allContentsList = gql`
  query allContentsList(
    $limit: Int
    $offset: Int
    $sort: ContentsSortEnum
    $order: PaginationOrderEnum
    $filter: String
  ) {
    allContentsList(
      limit: $limit
      offset: $offset
      sort: $sort
      order: $order
      filter: $filter
    ) {
      data {
        id
        title
        course_id
        description
        priority
        created_at
        updated_at
        duration
      }
      count
    }
  }
`;

export const getContentsById = gql`
  query getContentsById($id: UUID!) {
    getContentsById(id: $id) {
      id
      created_at
      updated_at
      description
      course_id
      title
      priority
      duration
      content_items_list(sort: priority, order: ASC) {
        data {
          id
          content_id
          title
          theory
          priority
          created_at
          updated_at
          video {
            data {
              id
              raw_file
              item_id
              drm_video_status
              drm_video_metadata
              drm_video_id
              active
              watermark_color
              created_at
            }
            count
          }
        }
        count
      }
    }
  }
`;

export const getCoursesById = gql`
  query getCoursesById($id: UUID!) {
    getCoursesById(id: $id) {
      id
      created_at
      updated_at
      description
      tutor_id
      tutor_assistant_ids
      title
      cover_img
      category_id
      price
      duration
      use_master_schedules
      draft
      video_intro
      video_intro_metadata
      category_id_data {
        name
      }
      tutor_id_data {
        name
        email
      }
    }
  }
`;

export const getContentItemsById = gql`
  query getContentItemsById($id: UUID!) {
    getContentItemsById(id: $id) {
      id
      created_at
      updated_at
      content_id
      title
      theory
      priority
      video {
        data {
          id
          item_id
          raw_file
          drm_video_status
          drm_video_metadata
          drm_video_id
          active
          watermark_color
          created_at
        }
        count
      }
      content_id_data {
        title
        priority
        description
        id
      }
    }
  }
`;

//mutation

export const updateContentsById = gql`
  mutation updateContentsById($ids: UUID!, $input: ContentsUpdateInput!) {
    updateContentsById(ids: $ids, input: $input) {
      id
      created_at
      updated_at
      description
      course_id
      title
      priority
      duration
    }
  }
`;

export const updateContentVideosById = gql`
  mutation updateContentVideosById($ids: UUID!, $input: ContentsUpdateInput!) {
    updateContentVideosById(ids: $ids, input: $input) {
      id
      created_at
      updated_at
      item_id
      drm_video_id
      drm_video_status
      active
      raw_file
      drm_video_metadata
      item_id_data
    }
  }
`;
