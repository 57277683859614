import gql from "graphql-tag";

export const getUsersById = gql`
  query getUsersById($id: UUID!) {
    getUsersById(id: $id) {
      id
      created_at
      updated_at
      roles
      email
      password
      social_ids
      verified
      fcm_tokens
      name
      deactivated_at
      secret_2fa
      ledgers_user_id_list(limit: 1, order: DESC, sort: created_at) {
        data {
          ending_balance
        }
      }
      student_enrolments_list {
        data {
          course_id
          comment
          course_id_data {
            description
            title
          }
          order_id_data {
            paid_at
          }
          quiz_result_list {
            data {
              finish_at
              start_at
              point
              quiz_id_data {
                title
              }
            }
            count
          }
          enrollment_progress {
            data {
              enrollment_id
              finish_at
              content_item_id_data {
                title
              }
            }
            count
          }
          schedules_enrollment_id_list {
            data {
              start_date
              finish_date
            }
          }
        }
      }
      teaching_list {
        data {
          title
          price
          duration
          draft
          created_at
          updated_at
          category_id_data {
            name
          }
          level_id_data {
            name
          }
          reviews(limit: 999) {
            data {
              rating
            }
            count
          }
        }
      }
    }
  }
`;

export const allUsersList = gql`
  query allUsersList(
    $limit: Int
    $offset: Int
    $sort: UsersSortEnum
    $order: PaginationOrderEnum
    $filter: String
    $where: UsersWhereInput
  ) {
    allUsersList(
      limit: $limit
      offset: $offset
      sort: $sort
      order: $order
      filter: $filter
      where: $where
    ) {
      data {
        id
        name
        email
        created_at
        updated_at
        verified
      }
      count
    }
  }
`;
