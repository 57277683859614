import React from "react";
import { List, Datagrid, TextField } from "react-admin";
import { ExportButton } from "react-admin";
import Toolbar from "@material-ui/core/Toolbar";
import {
  Create,
  SimpleForm,
  DateField,
  AutocompleteInput,
  required,
  ReferenceInput,
  FunctionField,
  TextInput,
  useRefresh,
  useDataProvider,
} from "react-admin";
import formatRupiah from "../utils/priceFormat";

import Button from "@material-ui/core/Button";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import TextFieldMui from "@material-ui/core/TextField";

import Autocomplete from "@material-ui/lab/Autocomplete";
import InputIcon from "@material-ui/icons/Input";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import client from "../utils/mococlient";
import Alerta from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const TransferList = (props) => {
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [wrongOtp, setWrongOtp] = React.useState(false);
  const [userData, setUserData] = React.useState([]);
  const [token, setToken] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [selected, setSelected] = React.useState("");

  // const handleClickSnack = () => {
  //   setOpen(true);
  // };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleOverwrite = async () => {
    setWrongOtp(false);
    setLoading(true);
    const payload = {
      token: token,
      transfer_amount: parseInt(amount),
      recipient_email: selected.email,
    };
    console.log(payload);
    const transfer = await client.invokeApi("transfer-saldo", payload);
    console.log(transfer);

    if (transfer.error && transfer.error.message === "Token not valid!") {
      setToken("");
      setWrongOtp(true);
      setLoading(false);
    } else {
      setLoading(false);
      setOpenDialog(false);
      setAmount("");
      setToken("");
      refresh();
      setSuccess(true);
    }
  };

  React.useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUser = async () => {
    const user = await dataProvider.getList("users", {
      filter: { role: "Student" },
      pagination: { page: 1, perPage: 111000 },
      sort: { field: "name", order: "ASC" },
    });
    console.log(user.data);
    setUserData(user.data);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setWrongOtp(false);
    setAmount("");
    setToken("");
  };

  const PostActions = ({
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
  }) => (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {/* <CreateButton basePath={basePath} /> */}
      <Button
        variant="text"
        color="primary"
        startIcon={<InputIcon />}
        size="small"
        onClick={handleClickOpenDialog}
      >
        transfer
      </Button>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
    </Toolbar>
  );

  return (
    <React.Fragment>
      <List
        {...props}
        actions={<PostActions />}
        bulkActionButtons={false}
        title="Front Pages"
        sort={{ field: "created_at", order: "DESC" }}
        //   filters={<PostFilter />}
      >
        <Datagrid>
          <TextField label="Sender name" source="users__sender_id.name" />
          <TextField label="Sender email" source="users__sender_id.email" />

          <TextField label="Recipient name" source="users__recipient_id.name" />
          <TextField
            label="Recipient email"
            source="users__recipient_id.email"
          />

          {/* <TextField label="Type" source="type" /> */}
          <FunctionField
            label="Amount"
            render={(record) => formatRupiah(record.amount)}
          />
          <DateField
            locales="en-TT"
            label="Created"
            source="created_at"
            showTime
          />
          <DateField
            locales="en-TT"
            label="Updated"
            source="updated_at"
            showTime
          />
        </Datagrid>
      </List>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity="warning">
          Please retry later
        </Alert>
      </Snackbar>
      <Snackbar
        open={success}
        autoHideDuration={3000}
        onClose={() => setSuccess(false)}
      >
        <Alert onClose={() => setSuccess(false)} severity="success">
          Success transfer point to {selected.name}
        </Alert>
      </Snackbar>
      <Dialog
        open={openDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">Transfer point</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Transfer point user type student, token get from google auth
          </DialogContentText>
          <div>
            {userData.length !== 0 && userData[1].title}
            <Autocomplete
              options={userData}
              fullWidth
              required
              style={{ marginBottom: "15px" }}
              getOptionLabel={(option) => option.name + " - " + option.email}
              onChange={(event, newValue) => {
                console.log(newValue);
                setSelected(newValue);
              }}
              renderInput={(params) => (
                <TextFieldMui
                  {...params}
                  label="Recipient"
                  variant="standard"
                />
              )}
            />
            <TextFieldMui
              label="Amount"
              type="number"
              required
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            {wrongOtp && (
              <Alerta severity="error" style={{ marginBottom: "15px" }}>
                Token Invalid!
              </Alerta>
            )}
            <TextFieldMui
              label="Token"
              value={token}
              required
              onChange={(e) => setToken(e.target.value)}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            disabled={loading}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleOverwrite}
            color="primary"
            variant="contained"
            disabled={
              token === "" || amount === "" || selected === "" || loading
            }
          >
            {loading ? (
              <CircularProgress style={{ width: "25px", height: "25px" }} />
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export const TransferCreate = (props) => (
  <Create {...props} title="Add Front Pages">
    <SimpleForm redirect="list">
      <ReferenceInput
        label="Recipient"
        source="recipient_id"
        reference="users"
        filter={{
          role: "Student",
        }}
        perPage={100000}
        sort={{ field: "name", order: "ASC" }}
        validate={[required()]}
      >
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
      <TextInput label="Amount" source="amount" validate={[required()]} />
      <TextInput label="Code OTP" source="token" validate={[required()]} />
    </SimpleForm>
  </Create>
);
