import axios from "axios";
import { EventEmitter } from "events";
import jsClient from "../utils/mococlient";

async function uploadIntroWithProgress({ presignedUrl, file }) {
  const { data } = await jsClient.invokeApi("add-intro-video");
  console.log(data); //uid, uploadUrl
  const ee = new EventEmitter();
  const formData = new FormData();
  formData.append("file", file);
  ///
  axios
    .post(data.uploadUrl, formData, {
      // headers: { "Content-Type": file.type },
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: function (progressEvent) {
        const percent = Math.ceil(
          100 * (progressEvent.loaded / progressEvent.total)
        );
        ee.emit("progress", percent);
      },
    })
    .then(function (result) {
      ee.emit("done", result);
    })
    .catch(function (err) {
      ee.emit("error", err);
    });
  return { task: ee, uid: data.uid };
}

async function submitStatusJobCheck(uid, courseId) {
  console.log("job");
  console.log(courseId);
  return jsClient.event.send({
    channel: "custom.cloudflare-video-status",
    message: { uid, courseId },
    options: {
      delay: 10 * 1000,
      backoff: {
        attempts: 60,
        delay: 20 * 1000,
        type: "fixed",
      },
    },
  });
}

export { uploadIntroWithProgress, submitStatusJobCheck };
