import React from "react";
import { List, Datagrid, TextField } from "react-admin";

// import Button from '@material-ui/core/Button';
import { CreateButton, ExportButton } from "react-admin";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateField,
  BooleanField,
  SelectInput,
  FormDataConsumer,
  required,
  NumberInput,
  EditButton,
  ShowController,
  Show,
  FunctionField,
  // SimpleShowLayout,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  TabbedShowLayout,
  Tab,
  useInput,
  Filter,
} from "react-admin";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
export const Voucher = (props) => (
  <List
    {...props}
    actions={<PostActions />}
    bulkActionButtons={false}
    title="Vouchers"
    filters={<VoucherFilter />}
    sort={{ field: "created_at", order: "DESC" }}
    filterDefaultValues={{ dateSet: "limit" }}
  >
    <Datagrid rowClick="show">
      <TextField label="Code" source="code" />
      <TextField label="Type" source="type" />
      <TextField label="Title" source="title" />
      {/* <TextField label="Usage limit" source="usage_limit" /> */}
      <BooleanField label="Used" source="used" />
      <DateField locales="en-TT" label="Limit" source="limit_date" showTime />
      <DateField locales="en-TT" label="Created" source="created_at" showTime />
      <DateField locales="en-TT" label="Updated" source="updated_at" showTime />
      <EditButton />
    </Datagrid>
  </List>
);

const PostActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <Toolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);
const DateTimeInputCustom = (props) => {
  const fs = useInput(props);

  const [selectedDate, setSelectedDate] = React.useState(new Date());
  React.useState(() => {
    if (fs.input.value) {
      setSelectedDate(fs.input.value);
    } else {
      fs.input.onChange({
        target: { value: moment(Date.now()).format() },
      });
    }
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log(moment(date).format());
    fs.input.onChange({ target: { value: moment(date).format() } });
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container>
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label="Select Limit Date"
          format="DD/MM/yyyy"
          value={selectedDate}
          style={{ marginRight: "20px" }}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <KeyboardTimePicker
          margin="normal"
          id="time-picker"
          ampm={false}
          label="Select Limit Time"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
export const VoucherCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <SelectInput
        source="type"
        initialValue="COURSE"
        validate={[required()]}
        choices={[
          { id: "COURSE", name: "Course" },
          { id: "CASHBACK", name: "Cashback" },
          { id: "CASHBACK_PERCENT", name: "Cashback percent" },
        ]}
      />
      <TextInput label="Code" source="code" validate={[required()]} />
      <TextInput label="Title" source="title" validate={[required()]} />
      <TextInput
        label="Description"
        source="description"
        validate={[required()]}
      />
      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) =>
          formData.type === "CASHBACK" && (
            <NumberInput
              label="Cashback Price "
              source="discount"
              style={{ width: "255px" }}
              step={1}
              min={1}
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) =>
          formData.type === "CASHBACK_PERCENT" && (
            <NumberInput
              label="Cashback Percent %"
              source="discount_percent"
              style={{ width: "255px" }}
              step={1}
              min={1}
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) =>
          (formData.type === "CASHBACK" ||
            formData.type === "CASHBACK_PERCENT") && (
            <NumberInput
              label="Minimum Topup"
              source="minimum_topup"
              style={{ width: "255px" }}
              step={1}
              min={0}
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) =>
          formData.type === "COURSE" && (
            <SelectInput
              source="all_course"
              initialValue={false}
              validate={[required()]}
              style={{ width: "255px" }}
              onChange={(e) => {
                console.log(rest);
              }}
              choices={[
                { id: true, name: "All course" },
                { id: false, name: "Select course" },
              ]}
            />
          )
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) =>
          formData.all_course === false &&
          formData.type === "COURSE" && (
            <ReferenceArrayInput
              label="Course"
              source="course_ids"
              reference="courses"
              validate={[required()]}
              perPage={100000}
              fullWidth
            >
              <AutocompleteArrayInput optionText="title" />
            </ReferenceArrayInput>
          )
        }
      </FormDataConsumer>
      <SelectInput
        source="all_user"
        initialValue={false}
        validate={[required()]}
        choices={[
          { id: true, name: "All User" },
          { id: false, name: "Select User" },
        ]}
      />

      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) =>
          formData.all_user === false && (
            <ReferenceArrayInput
              label="User"
              source="user_ids"
              reference="users"
              filter={{
                role: "Student",
              }}
              perPage={100000}
              fullWidth
              sort={{ field: "name", order: "DESC" }}
              validate={[required()]}
            >
              <AutocompleteArrayInput optionText="name" />
            </ReferenceArrayInput>
          )
        }
      </FormDataConsumer>
      <NumberInput
        label="Voucher usage limit"
        source="usage_limit"
        step={1}
        min={1}
        initialValue={1}
        validate={[required()]}
      />
      <NumberInput
        label="User usage limit"
        source="user_usage_limit"
        step={1}
        min={1}
        initialValue={1}
        validate={[required()]}
      />
      {/* <DateTimeInput source="limit_date" validate={[required()]} /> */}
      <DateTimeInputCustom
        onChange={(value) => console.log(value)}
        source="limit_date"
        label="Limit Date"
        validate={[required()]}
      />
    </SimpleForm>
  </Create>
);

export const VoucherEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <SelectInput
        source="type"
        initialValue="COURSE"
        validate={[required()]}
        choices={[
          { id: "COURSE", name: "Course" },
          { id: "CASHBACK", name: "Cashback" },
          { id: "CASHBACK_PERCENT", name: "Cashback percent" },
        ]}
      />
      <TextInput label="Code" source="code" validate={[required()]} />
      <TextInput label="Title" source="title" validate={[required()]} />
      <TextInput
        label="Description"
        source="description"
        validate={[required()]}
      />
      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) =>
          formData.type === "CASHBACK" && (
            <NumberInput
              label="Cashback Price "
              source="discount"
              style={{ width: "255px" }}
              step={1}
              min={1}
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) =>
          formData.type === "CASHBACK_PERCENT" && (
            <NumberInput
              label="Cashback Percent %"
              source="discount_percent"
              style={{ width: "255px" }}
              step={1}
              min={1}
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) =>
          (formData.type === "CASHBACK" ||
            formData.type === "CASHBACK_PERCENT") && (
            <NumberInput
              label="Minimum Topup"
              source="minimum_topup"
              style={{ width: "255px" }}
              step={1}
              min={0}
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) =>
          formData.type === "COURSE" && (
            <SelectInput
              source="all_course"
              initialValue={false}
              validate={[required()]}
              style={{ width: "255px" }}
              choices={[
                { id: true, name: "All course" },
                { id: false, name: "Select course" },
              ]}
            />
          )
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) =>
          formData.all_course === false &&
          formData.type === "COURSE" && (
            <ReferenceArrayInput
              label="Course"
              source="course_ids"
              perPage={100000}
              reference="courses"
              validate={[required()]}
              fullWidth
            >
              <AutocompleteArrayInput optionText="title" />
            </ReferenceArrayInput>
          )
        }
      </FormDataConsumer>
      <SelectInput
        source="all_user"
        initialValue={false}
        validate={[required()]}
        choices={[
          { id: true, name: "All User" },
          { id: false, name: "Select User" },
        ]}
      />

      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) =>
          formData.all_user === false && (
            <ReferenceArrayInput
              label="User"
              source="user_ids"
              reference="users"
              filter={{
                role: "Student",
              }}
              fullWidth
              perPage={100000}
              sort={{ field: "name", order: "DESC" }}
              validate={[required()]}
            >
              <AutocompleteArrayInput optionText="name" />
            </ReferenceArrayInput>
          )
        }
      </FormDataConsumer>
      <NumberInput
        label="Voucher usage limit"
        source="usage_limit"
        step={1}
        min={1}
        initialValue={1}
        validate={[required()]}
      />
      <NumberInput
        label="User usage limit"
        source="user_usage_limit"
        step={1}
        min={1}
        initialValue={1}
        validate={[required()]}
      />
      {/* <DateTimeInput source="limit_date" validate={[required()]} /> */}
      <DateTimeInputCustom
        onChange={(value) => console.log(value)}
        source="limit_date"
        label="Limit Date"
        validate={[required()]}
      />
    </SimpleForm>
  </Edit>
);

export const VoucherShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <Show {...props} title="Category" {...controllerProps}>
        <TabbedShowLayout>
          <Tab label="info">
            <TextField label="Code" source="code" />
            <TextField label="Type" source="type" />
            <TextField label="Title" source="title" />
            <TextField label="Description" source="description" />
            {controllerProps.record && controllerProps.record.all_course ? (
              <FunctionField
                label="Course list"
                render={(record) =>
                  record.all_course === true ? "All Course" : ""
                }
              />
            ) : (
              <ReferenceArrayField
                label="Course list"
                source="course_ids"
                reference="courses"
              >
                <SingleFieldList>
                  <ChipField source="title" />
                </SingleFieldList>
              </ReferenceArrayField>
            )}

            {controllerProps.record && controllerProps.record.all_user ? (
              <FunctionField
                label="User list"
                render={(record) =>
                  record.all_course === true ? "All User" : ""
                }
              />
            ) : (
              <ReferenceArrayField
                label="User list"
                source="user_ids"
                reference="users"
              >
                <SingleFieldList>
                  <ChipField source="name" />
                </SingleFieldList>
              </ReferenceArrayField>
            )}

            {controllerProps.record &&
              controllerProps.record.user_id !== null && (
                <TextField label="Email" source="users.email" />
              )}
            <TextField label="Usage limit" source="usage_limit" />
            <TextField label="User usage limit" source="user_usage_limit" />
          </Tab>
          <Tab label="price">
            {controllerProps.record &&
              controllerProps.record.type === "CASHBACK" && (
                <TextField label="Cashback" source="discount" />
              )}
            {/* <TextField label="Usage limit" source="usage_limit" /> */}
            {controllerProps.record &&
              controllerProps.record.type === "CASHBACK_PERCENT" && (
                <TextField label="Cashback percent" source="discount_percent" />
              )}
            {controllerProps.record &&
              controllerProps.record.type !== "COURSE" && (
                <TextField label="Minimum Topup" source="minimum_topup" />
              )}
            <BooleanField label="Used" source="used" />
          </Tab>
          <Tab label="date">
            <DateField
              locales="en-TT"
              label="Limit date"
              source="limit_date"
              showTime
            />
            <DateField
              locales="en-TT"
              label="Created"
              source="created_at"
              showTime
            />
            <DateField
              locales="en-TT"
              label="Updated"
              source="updated_at"
              showTime
            />
          </Tab>
        </TabbedShowLayout>
      </Show>
    )}
  </ShowController>
);

const VoucherFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Search by code voucher"
      source="search"
      alwaysOn
      style={{ width: "250px" }}
    />
    <SelectInput
      alwaysOn
      source="dateSet"
      label="Filter date"
      choices={[
        { id: "limit", name: "Limit Date" },
        { id: "showall", name: "Show All" },
      ]}
    />
  </Filter>
);
