import React from "react";
import { List, Datagrid, TextField } from "react-admin";
import { ExportButton } from "react-admin";
import Toolbar from "@material-ui/core/Toolbar";
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateField,
  BooleanField,
  FileInput,
  FileField,
  DeleteButton,
  Filter,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";

export const VideoList = (props) => {
  const PostFilter = (props) => {
    // console.log(props);
    return (
      <Filter {...props}>
        <ReferenceInput
          label="Select Course"
          source="course_id"
          alwaysOn
          reference="courses"
          sort={{ field: "title", order: "ASC" }}
          onChange={(e) => {
            if (e === null) {
              // localStorage.setItem("selectCourse", "");
              props.setFilters({
                content_id: "",
              });
            } else {
              props.setFilters({
                content_id: "",
                course_id: e,
              });
            }
          }}
        >
          <AutocompleteInput
            optionText="title"
            style={{ background: "white" }}
          />
        </ReferenceInput>
        {/* <div>{props.filterValues.id ? "ada" : "tidak"}</div> */}
        {props.filterValues.course_id && (
          <ReferenceInput
            label="Select content"
            source="content_id"
            reference="contents"
            filter={{ id: props.filterValues.course_id }}
            sort={{ field: "priority", order: "ASC" }}
            alwaysOn
          >
            <AutocompleteInput
              optionText="title"
              style={{ background: "white" }}
            />
          </ReferenceInput>
        )}
      </Filter>
    );
  };
  return (
    <List
      {...props}
      actions={<PostActions />}
      bulkActionButtons={false}
      title="Videos"
      sort={{ field: "created_at", order: "DESC" }}
      filter={{ used: true }}
      filters={<PostFilter />}
    >
      <Datagrid>
        <TextField label="Name" source="raw_file" />
        <ContentField />
        <BooleanField source="active" />
        <TextField label="Status" source="drm_video_status" />
        <DateField
          locales="en-TT"
          label="Created"
          source="created_at"
          showTime
        />
        <DateField
          locales="en-TT"
          label="Updated"
          source="updated_at"
          showTime
        />
        <DeleteButton undoable={false} />
      </Datagrid>
    </List>
  );
};

const PostActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <Toolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    {/* <CreateButton basePath={basePath} /> */}
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

export const VideoCreate = (props) => (
  <Create {...props} title="Add Video">
    <SimpleForm redirect="list">
      <FileInput source="files" label="Video" multiple={false} accept="video/*">
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);

export const PostEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <TextInput source="nama" />
      <TextInput source="pengarang" />
    </SimpleForm>
  </Edit>
);

const ContentField = ({ record = {} }) => (
  <span>
    {record.item_id === null
      ? "-"
      : record.item_id_data
      ? record.item_id_data.title
      : "-"}
  </span>
);
ContentField.defaultProps = { label: "Content item", addLabel: true };
