import React, { useRef, useState, Fragment, useEffect } from "react";
// import { useDataProvider } from "react-admin";
import moment from "moment";
import { useStopwatch } from "react-timer-hook";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import grey from "@material-ui/core/colors/grey";
import InfoIcon from "@material-ui/icons/Info";
import jsClient from "../utils/mococlient";

function DrmVideoPlayer(props) {
  //   console.log("drm-vid");
  //   console.log(props);
  const { drmId } = props;
  const elementRef = useRef();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!drmId) return;
    jsClient.invokeApi("video-otp", { id: drmId }).then(({ data }) => {
      console.log(data);
      new window.VdoPlayer({
        otp: data.otp,
        playbackInfo: btoa(JSON.stringify({ videoId: drmId })),
        theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
        container: elementRef.current,
      });
      setIsLoading(false);
    });
    return () => {};
  }, [drmId]);

  return (
    <Fragment>
      {isLoading && <LinearProgress />}
      <div
        ref={elementRef}
        style={{ width: "100%", height: "auto", marginBottom: "10px" }}
      ></div>
    </Fragment>
  );
}

function humanizedTime(datetime) {
  if (moment().subtract(3, "days").isAfter(moment(datetime))) {
    return moment(datetime).format("DD MMM YYYY HH:mm");
  }
  // otherwise
  const momentDiff = moment(datetime).subtract(moment());
  return moment.duration(momentDiff).humanize(true);
}

function VideoItemRenderer({ data }) {
  //   console.log("vid-item");
  //   console.log(data);
  //   const { itemId } = data;
  //   const dataProvider = useDataProvider();
  const [activeVideo, setActiveVideo] = useState(null);
  const [recentVideo, setRecentVideo] = useState(null);

  const { pause, start } = useStopwatch({ autoStart: true });

  useEffect(() => {
    console.log("fetch");
    // console.log("effect called: ", minutes);
    // if (itemId) {
    //   dataProvider
    //     .getList("content_videos", {
    //       filter: { item_id: itemId },
    //       pagination: { page: 1, perPage: 10 },
    //       sort: { field: "created_at", order: "DESC" },
    //     })
    //     .then((result) => {
    const videoList = data.video.data;
    const activeElement = videoList.find((val) => val.active === true);
    // setActiveVideo({ ...activeElement, id: 12311312 } || null);
    setActiveVideo(activeElement || null);
    setRecentVideo(videoList.length > 0 ? videoList[0] : null);
    console.log({ activeElement, recentVideo: videoList[0] });
    // });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const hasRecentVideo =
    recentVideo && activeVideo
      ? activeVideo.id !== recentVideo.id
      : recentVideo;
  // controlling auto update / check recently updated video status
  if (!hasRecentVideo) {
    pause();
  } else {
    start();
  }

  return (
    <Box>
      {activeVideo && <DrmVideoPlayer drmId={activeVideo.drm_video_id} />}
      {activeVideo ? (
        <Typography variant="subtitle1">
          Uploaded at {humanizedTime(activeVideo.created_at)} <b>(active)</b>
        </Typography>
      ) : (
        <Box p={3} style={{ backgroundColor: grey[100] }} mt={1}>
          <Typography variant="subtitle2">
            No video is currently associated to this item
          </Typography>
        </Box>
      )}
      {hasRecentVideo && (
        <Box display="flex" flexDirection="row" alignItems="center" mt={1}>
          <Box mr={1} display="flex">
            {/* <CircularProgress size={22} thickness={5} /> */}
            <InfoIcon color="primary" />
          </Box>
          <Typography variant="subtitle1">
            Newly uploaded video is {recentVideo.drm_video_status || "Queued"} (
            <b>{humanizedTime(recentVideo.created_at)} </b>)
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export { DrmVideoPlayer, VideoItemRenderer };
