import React, { useEffect, useState } from "react";
import { FormWithRedirect, useAuthenticated } from "react-admin";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { useNotify } from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
// import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
// import MomentUtils from "@date-io/moment";
import isProd from "../../utils/variabels";
// import { ProfilePicture } from "./ProfilePicture";
import jsClient from "../../utils/mococlient";
// import { SignatureImage } from "./SignatureImage";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import forceLogout from "../../utils/forceLogout";

export default function BiographyViewer(props) {
  useAuthenticated();
  const [photoUrl, setPhotoUrl] = useState("");
  const [newImageFile, setNewImageFile] = useState(null);
  const [nameFile, setNameFile] = useState("");
  const [cropper, setCropper] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [load, setLoad] = useState(false);
  const notifier = useNotify();
  const fileInputRef = React.useRef();

  useEffect(() => {
    fetchImg();
  }, []);

  const fetchImg = async () => {
    console.log("fetch");
    setLoadingFetch(true);
    try {
      const table = jsClient.getTable("front_pages");
      const fetch = await table
        .filter(table.composeFilter().eq("priority", 0).toOData())
        .read();
      console.log(fetch);
      setPhotoUrl(fetch.data[0].img_cover);
      setLoadingFetch(false);
      setLoad(false);
    } catch (e) {
      console.log(e);
      setLoadingFetch(false);
      setLoad(false);
      await forceLogout(e);
    }
  };

  const cleanup = () => {
    setNewImageFile(null);
    setShowDialog(false);
    setUploading(false);
    setNameFile("");
    setProgress(0);
    fetchImg();
  };

  const handleFileChange = (event) => {
    const name = event.target.files[0].name;
    setNameFile(name);
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => setNewImageFile(reader.result),
      false
    );
    reader.readAsDataURL(event.target.files[0]);
  };

  // on Pressing
  const submitCrop = () => {
    setUploading(true);
    cropper
      .getCroppedCanvas({ width: "1451", height: "462" })
      .toBlob(async (blob) => {
        console.log(blob);
        try {
          const uploadResult = await jsClient.storage.upload({
            file: blob,
            progressCallback: (progressVal) => setProgress(progressVal),
            params: {
              filename: `bg/${nameFile}`,
              bucket: isProd.publicBucket,
            },
          });
          console.log(uploadResult);
          const download = await jsClient.storage.downloadUrl({
            params: {
              bucket: isProd.publicBucket,
            },
            fileId: uploadResult.data.id,
          });

          console.log(download);
          const urlImg = download.data.url.split("?");
          console.log(urlImg);

          //check
          const table = jsClient.getTable("front_pages");
          const check = await table
            .filter(table.composeFilter().eq("priority", 0).toOData())
            .read();
          console.log(check);

          if (check.total === 0) {
            const create = await table.insert({ img_cover: urlImg[0] });
            console.log(create);
          } else {
            const dataFixUpdate = {
              id: check.data[0].id,
              data: { img_cover: urlImg[0] },
            };
            const update = await table.update(dataFixUpdate);
            console.log(update);
          }
          notifier("Change background success");
          cleanup();
        } catch (e) {
          console.warn(e);
          notifier("Upload failed: exception");
          await forceLogout(e);
          cleanup();
        }
      }, "image/jpeg");
  };

  const shouldClose = () => {
    if (uploading) return;
    cleanup();
  };

  return (
    <FormWithRedirect
      //   resource="user_biography"
      //   defaultValue={biodata}
      // redirect={redirect}
      //   save={onSave}
      //   saving={saving}
      //   onCancel={props.onClose}
      render={(formProps) => {
        return (
          <form>
            <Box p={1} mt={2} mb={5}>
              <Paper elevation={8}>
                <Box p={3}>
                  <Typography variant="h6">Current background image</Typography>
                  <Box
                    display="flex"
                    justifyContent="center"
                    style={{
                      width: "100%",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    {loadingFetch ? (
                      <CircularProgress
                        style={{
                          width: "70px",
                          height: "70px",
                          margin: "20px",
                        }}
                      />
                    ) : (
                      <React.Fragment>
                        {!load && (
                          <div>
                            <CircularProgress
                              style={{
                                width: "70px",
                                height: "70px",
                                margin: "20px 10px",
                              }}
                            />
                            <div style={{ fontWeight: "Bold" }}>Load image</div>
                          </div>
                        )}
                        <Tooltip title="Click to change background image">
                          <Box onClick={() => setShowDialog(true)}>
                            <img
                              src={
                                photoUrl === ""
                                  ? "https://ideas.or.id/wp-content/themes/consultix/images/no-image-found-360x250.png"
                                  : photoUrl
                              }
                              alt="bg"
                              style={
                                load ? { width: "100%" } : { display: "none" }
                              }
                              onLoad={() => {
                                console.log("loaded");
                                setLoad(true);
                              }}
                            />
                          </Box>
                        </Tooltip>
                      </React.Fragment>
                    )}
                  </Box>
                </Box>
                <Dialog
                  open={uploading || showDialog}
                  onClose={() => shouldClose()}
                >
                  <DialogTitle>Upload background image</DialogTitle>
                  {newImageFile && (
                    <Box p={2} pb={0} pt={0}>
                      <Cropper
                        src={newImageFile}
                        // Cropper.js options
                        aspectRatio={1451 / 462}
                        viewMode={2}
                        guides={true}
                        scalable={false}
                        onInitialized={(cropperInstance) =>
                          setCropper(cropperInstance)
                        }
                      />
                    </Box>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {nameFile}
                  </div>
                  {progress !== 0 && (
                    <div style={{ padding: "5px 20px" }}>
                      <LinearProgress
                        variant="determinate"
                        value={progress}
                        fullWidth
                      />
                      <div>{`Upload process : ${progress}%`}</div>
                    </div>
                  )}
                  <input
                    type="file"
                    hidden
                    ref={fileInputRef}
                    onChange={(e) => handleFileChange(e)}
                    accept="image/*"
                  />
                  <Box
                    p={2}
                    display="flex"
                    justifyContent="space-between"
                    minWidth={400}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => fileInputRef.current.click()}
                      disabled={uploading}
                    >
                      Select new image
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => submitCrop()}
                      disabled={uploading}
                    >
                      {uploading ? (
                        <CircularProgress
                          style={{
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      ) : (
                        "upload"
                      )}
                    </Button>
                  </Box>
                </Dialog>
              </Paper>
            </Box>
          </form>
        );
      }}
    />
  );
}
