import gql from "graphql-tag";

export const getCategoryById = gql`
  query getCategoryById($id: UUID!) {
    getCategoryById(id: $id) {
      id
      created_at
      updated_at
      name
      category_id
      sub_categories {
        data {
          name
          category_id
          id
        }
      }
    }
  }
`;

export const allCategoryList = gql`
  query allCategoryList(
    $limit: Int
    $offset: Int
    $sort: CategorySortEnum
    $order: PaginationOrderEnum
    $filter: String
    $where: CategoryWhereInput
  ) {
    allCategoryList(
      limit: $limit
      offset: $offset
      sort: $sort
      order: $order
      filter: $filter
      where: $where
    ) {
      data {
        id
        created_at
        updated_at
        name
        category_id
        sub_categories {
          data {
            name
            category_id
            id
          }
        }
      }
      count
    }
  }
`;
