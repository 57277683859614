import gql from "graphql-tag";
export const allContentVideosList = gql`
  query allContentVideosList(
    $limit: Int
    $offset: Int
    $sort: ContentVideosSortEnum
    $order: PaginationOrderEnum
    $filter: String
    $where: ContentVideosWhereInput
  ) {
    allContentVideosList(
      limit: $limit
      offset: $offset
      sort: $sort
      order: $order
      filter: $filter
      where: $where
    ) {
      data {
        id
        created_at
        updated_at
        item_id
        drm_video_id
        drm_video_status
        drm_video_metadata
        active
        raw_file
        item_id_data {
          id
          content_id
          title
        }
      }
      count
    }
  }
`;
