import client from "../utils/mococlient";
import { allCoursesList } from "../graphql/content";
import { allUsersList } from "../graphql/users";

import getListMethod from "./Request/getList";
import getOneMethod from "./Request/getOne";
import updateMethod from "./Request/update";
import createMethod from "./Request/create";
import forceLogout from "../utils/forceLogout";

export default {
  getList: async (resource, params) => {
    const request = await getListMethod(resource, params);
    return request;
  },
  getOne: async (resource, params) => {
    const request = await getOneMethod(resource, params);
    return request;
  },
  update: async (resource, params) => {
    const request = await updateMethod(resource, params);
    return request;
  },
  create: async (resource, params) => {
    const request = await createMethod(resource, params);
    return request;
  },
  getMany: async (resource, params) => {
    try {
      let fetchData;
      if (resource === "activity_logs") {
        if (params.ids) {
          fetchData = await client.invokeApi("get-activity-filters", {
            field: "action",
          });

          const manipulate = fetchData.data.map((x, index) => ({
            ...x,
            id: index,
          }));
          fetchData = { data: manipulate, total: manipulate.length };
        }
      } else if (resource === "users") {
        const query = {
          query: allUsersList,
          variables: {
            where: { id: { _in: params.ids } },
          },
        };

        const gql = await client.gql.query(query);
        console.log(gql);
        fetchData = {
          data: gql.allUsersList.data,
          total: gql.allUsersList.count,
        };
      } else {
        const query = {
          query: allCoursesList,
          // query: allContentItemsList,
          variables: {
            where: { id: { _in: params.ids } },
          },
        };
        const gql = await client.gql.query(query);
        // fetchData = {
        //   data: gql.allContentItemsList.data,
        //   total: gql.allContentItemsList.count,
        // };
        console.log("getmany");
        console.log(gql);
        fetchData = {
          data: gql.allCoursesList.data,
          total: gql.allCoursesList.count,
        };
      }
      return fetchData;
    } catch (e) {
      await forceLogout(e);
    }
  },
  delete: async (resource, params) => {
    console.log(resource, params);
    try {
      if (resource === "content_items") {
        console.log(params.previousData);
        if (params.previousData.video.data[0]) {
          console.log("have video");
          const id = params.previousData.video.data[0].id;
          const tableVid = client.getTable("content_videos");
          // eslint-disable-next-line no-unused-vars
          const deleteVideo = await tableVid.del({ id });
        }
        const table = client.getTable(resource);
        // eslint-disable-next-line no-unused-vars
        const deleteData = await table.del({ id: params.id });
        return { data: params.previousData };
      } else if (resource === "users") {
        const date = new Date().toISOString();
        const table = client.getTable(resource);
        const dataFixUpdate = {
          id: params.id,
          data: {
            ...params.previousData,
            deactivated_at: date,
          },
        };
        const update = await table.update(dataFixUpdate);
        console.log(update);
        return { data: params.previousData };
      } else {
        // console.log({ data: params.previousData });
        const table = client.getTable(resource);
        // eslint-disable-next-line no-unused-vars
        const deleteData = await table.del({ id: params.id });
        return { data: params.previousData };
      }
    } catch (e) {
      await forceLogout(e);
    }
  },
};
