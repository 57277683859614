// in src/Dashboard.js
import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import logo from "../gca_1.png";
import CircularProgress from "@material-ui/core/CircularProgress";
// import forceLogout from "../utils/forceLogout";
// import client from "../utils/mococlient";

const Dashboard = () => {
  const [profile, setProfile] = React.useState({ name: "" });
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = React.useState(false);

  const fetchProfile = async () => {
    // setLoading(true);
    // const profile = await client.user.get();
    // console.log(profile);

    // if (profile.error && profile.error.title === "Authentication required") {
    //   await forceLogout(profile);
    // } else if (
    //   profile.error &&
    //   profile.error.title === "The token has been revoked."
    // ) {
    //   await forceLogout(profile);
    // } else {
    //   setProfile(profile.data);
    // }
    // setLoading(false);
    const profile = JSON.parse(localStorage.getItem("permission"));
    console.log(profile);
    setProfile(profile);
  };

  React.useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <Card>
      <img src={logo} alt="logo" style={{ width: "260px", margin: "15px" }} />
      <CardContent>
        {loading ? (
          <CircularProgress style={{ width: "20px", height: "20px" }} />
        ) : (
          <div>Kamu login sebagai {profile.name || profile.email || ""} </div>
        )}
      </CardContent>
    </Card>
  );
};

export default Dashboard;
