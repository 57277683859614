import gql from "graphql-tag";

export const allEnrollmentsList = gql`
  query allEnrollmentsList(
    $limit: Int
    $offset: Int
    $sort: EnrollmentsSortEnum
    $order: PaginationOrderEnum
    $filter: String
    $where: EnrollmentsWhereInput
  ) {
    allEnrollmentsList(
      limit: $limit
      offset: $offset
      sort: $sort
      order: $order
      filter: $filter
      where: $where
    ) {
      data {
        id
        revoked
        refunded
        locked
        created_at
        student_id_data {
          name
          email
        }
        course_id_data {
          title
          price
        }
        quiz_result_list {
          data {
            finish_at
            start_at
            point
            quiz_id_data {
              title
            }
            pass
          }
          count
        }
        order_id_data {
          ordered_at
          paid_at
          voucher_id
          course_price_snapshoot
        }
        enrollment_progress {
          data {
            enrollment_id
            finish_at
            start_at
            content_item_id_data {
              title
            }
          }
          count
        }
      }
      count
    }
  }
`;
