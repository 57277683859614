import auth from "../Provider/authProvider";
import swal from "sweetalert";
const forceLogout = async (e, gql) => {
  console.log(e.response);

  if (e.error?.title === "refresh token expired") {
    console.log("rt exp");
    const logout = await auth.logout();
    console.log(logout);
    window.location.replace("/login");
  } else if (e.errors && e.errors[0].title === "jwt malformed") {
    console.log("jwt malformed");
    const logout = await auth.logout();
    console.log(logout);
    window.location.replace("/login");
  } else if (e.errors && e.errors[0].title === "refresh token expired") {
    console.log("rt exp");
    const logout = await auth.logout();
    console.log(logout);
    window.location.replace("/login");
  } else if (
    e.response &&
    e.response?.data?.errors[0]?.title === "refresh token expired"
  ) {
    console.log("jwt malformed");
    const logout = await auth.logout();
    console.log(logout);
    window.location.replace("/login");
  } else if (e.error?.title === "The token has been revoked.") {
    console.log("jwt malformed");
    const logout = await auth.logout();
    console.log(logout);
    window.location.replace("/login");
  } else {
    if (
      e.response?.data?.errors[0]?.detail ===
      'delete from "courses" where "id" = $1 returning "id" - update or delete on table "courses" violates foreign key constraint "fk_orders_course_id_courses_id" on table "orders"'
    ) {
      console.log("delete course");
      swal("Please remove All Content first before delete Course");
      // window.location.reload();
    } else if (
      e.response?.data?.errors[0]?.detail ===
      'delete from "contents" where "id" = $1 returning "id" - update or delete on table "contents" violates foreign key constraint "fk_content_items_content_id_contents_id" on table "content_items"'
    ) {
      console.log("delete course");
      swal("Please remove All Content Item first before delete Content");
      // window.location.reload();
    }
    return Promise.reject({
      status: 400,
      message: "error",
      json: e,
    });
  }
};

export default forceLogout;
