import client from "../../utils/mococlient";
import isProd from "../../utils/variabels";
import { getContentItemsById, getContentsById } from "../../graphql/content";
import {
  uploadIntroWithProgress,
  submitStatusJobCheck,
} from "../VideoProvider";
import forceLogout from "../../utils/forceLogout";

const update = async (resource, params) => {
  try {
    if (resource === "contents") {
      console.log("edit contentss");
      let dataUpdate = {
        ...params.data,
        priority: parseInt(params.data.priority),
      };
      console.log(params);

      // console.log(data);
      if (params.data.priorityValue) {
        const { position } = params.data;
        const query = {
          query: getContentsById,
          variables: {
            id: params.data.priorityValue,
          },
        };
        const gql = await client.gql.query(query);
        const prioversus = gql.getContentsById.priority;
        // console.log(gql.getContentsById);
        // console.log("PRIO VERSUS : " + prioversus);

        const modifier = position ? (position === "before" ? -1 : 1) : 0;
        const modScale = Math.ceil(Math.random() * 5);
        dataUpdate = {
          ...params.data,
          priority: prioversus + Number(modifier * modScale),
        };
      }
      //   if (priority !== prioversus) {
      //     //ex : A,B,C => 1,2,3
      //     if (position === "before") {
      //       // BEFORE
      //       const prioChange = prioversus - 1;
      //       dataUpdate = { ...params.data, priority: prioChange };
      //     } else if (position === "after") {
      //       // AFTER
      //       const prioChange = prioversus + 1;
      //       dataUpdate = { ...params.data, priority: prioChange };
      //     }
      //   }
      // }
      const table = client.getTable(resource);
      const dataFixUpdate = {
        id: params.id,
        data: dataUpdate,
      };
      const update = await table.update(dataFixUpdate);
      return update;
    } else if (resource === "promoted_courses") {
      const table = client.getTable(resource);
      let dataUpdate = {
        ...params.data,
        priority: parseInt(params.data.priority),
      };
      console.log(params);

      // console.log(data);
      if (params.data.priorityValue) {
        const { position } = params.data;

        const get = await table.lookup(params.data.priorityValue);
        const prioversus = get.data.priority;

        const modifier = position ? (position === "before" ? -1 : 1) : 0;
        const modScale = Math.ceil(Math.random() * 5);
        dataUpdate = {
          ...params.data,
          priority: prioversus + Number(modifier * modScale),
        };
      }
      const dataFixUpdate = {
        id: params.id,
        data: dataUpdate,
      };
      const update = await table.update(dataFixUpdate);
      return update;
    } else if (resource === "vouchers") {
      const table = client.getTable(resource);
      let dataUpdate = params.data;
      if (params.data.all_user === true) {
        dataUpdate = {
          ...dataUpdate,
          user_ids: null,
        };
      }
      if (params.data.all_course === true) {
        dataUpdate = {
          ...dataUpdate,
          course_ids: null,
        };
      }
      const dataFixUpdate = {
        id: params.id,
        data: dataUpdate,
      };
      const update = await table.update(dataFixUpdate);
      return update;
    } else if (resource === "master_schedules") {
      const table = client.getTable(resource);
      let dataUpdate = params.data;
      if (params.data.course_type === "all") {
        dataUpdate = {
          ...dataUpdate,
          course_ids: null,
        };
      }
      const dataFixUpdate = {
        id: params.id,
        data: dataUpdate,
      };
      const update = await table.update(dataFixUpdate);
      return update;
    } else if (resource === "category_front_pages") {
      const table = client.getTable(resource);
      let dataUpdate = {
        ...params.data,
        priority: parseInt(params.data.priority),
      };
      console.log(params);

      // console.log(data);
      if (params.data.priorityValue) {
        const { position } = params.data;

        const get = await table.lookup(params.data.priorityValue);
        const prioversus = get.data.priority;

        const modifier = position ? (position === "before" ? -1 : 1) : 0;
        const modScale = Math.ceil(Math.random() * 5);
        dataUpdate = {
          ...params.data,
          priority: prioversus + Number(modifier * modScale),
        };
      }
      const dataFixUpdate = {
        id: params.id,
        data: dataUpdate,
      };
      const update = await table.update(dataFixUpdate);
      return update;
    } else if (resource === "users") {
      console.log(params);
      const table = client.getTable(resource);
      // const rolesUpdate =
      //   typeof params.data.roles === "string"
      //     ? params.data.roles.split(" ")
      //     : params.data.roles;
      const dataFixUpdate = {
        id: params.id,
        data: { ...params.data },
      };
      console.log(dataFixUpdate);
      const update = await table.update(dataFixUpdate);
      console.log(update);
      return update;
    } else if (resource === "content_items") {
      console.log("edit content_items");
      console.log(params);
      let dataUpdate = {
        ...params.data,
        priority: parseInt(params.data.priority),
      };

      // console.log(data);
      if (params.data.priorityValue) {
        const { position } = params.data;
        const query = {
          query: getContentItemsById,
          variables: {
            id: params.data.priorityValue,
          },
        };
        const gql = await client.gql.query(query);
        const prioversus = gql.getContentItemsById.priority;
        // console.log(gql.getContentsById);
        // console.log("PRIO VERSUS : " + prioversus);

        const modifier = position ? (position === "before" ? -1 : 1) : 0;
        const modScale = Math.ceil(Math.random() * 5);
        dataUpdate = {
          ...params.data,
          priority: prioversus + Number(modifier * modScale),
        };
      }

      if (params.data.files) {
        const table = client.getTable("content_videos");

        console.log("Remove previous video");
        const removePrevData = {
          id: params.data.video.data[0].id,
          data: { item_id: null },
        };
        const removePREV = await table.update(removePrevData);
        console.log(removePREV);

        console.log("Replace ");
        const upload = await client.storage.upload({
          params: {
            filename: params.data.files.title,
            bucket: isProd.privateBucket,
            expire: "600",
          },
          file: params.data.files.rawFile,
          progressCallback: (progressVal) => console.log(progressVal),
        });
        console.log(upload);

        // const dataFixUpdate = {
        //   id: params.data.selectVideo,
        //   data: { item_id: add.data.id },
        // };
        const dataFixCreate = {
          item_id: params.id,
          raw_file: upload.data.id,
        };
        // const updateVid = await table.update(dataFixUpdate);
        const createVid = await table.insert(dataFixCreate);
        console.log(createVid);
        //   //REMOVE PREVIOUS VIDEO
        //   const removePrevData = {
        //     id: params.data.video.data[0].id,
        //     data: { item_id: null },
        //   };
        //   const removePREV = await table.update(removePrevData);
        //   console.log(removePREV);

        //   //ATTACH NEW VIDEO
        //   const dataFixUpdate = {
        //     id: params.data.selectVideo,
        //     data: { item_id: params.data.id },
        //   };
        //   const updateVid = await table.update(dataFixUpdate);
        //   console.log(updateVid);
        // } else if (params.data.action === "attach" && params.data.selectVideo) {
        //   console.log("NEW ATTACH");
        //   const table = client.getTable("content_videos");
        //   const dataFixUpdate = {
        //     id: params.data.selectVideo,
        //     data: { item_id: params.data.id },
        //   };
        //   const updateVid = await table.update(dataFixUpdate);
        //   console.log(updateVid);
        // } else if (
        //   params.data.action === "detach" &&
        //   params.data.video.count === 1
        // ) {
        //   console.log("DETACH VIDEO");
        //   const table = client.getTable("content_videos");
        //   const dataFixUpdate = {
        //     id: params.data.video.data[0].id,
        //     data: { item_id: null },
        //   };
        //   const updateVid = await table.update(dataFixUpdate);
        //   console.log(updateVid);
      }

      const table = client.getTable(resource);
      const dataFixUpdate = {
        id: params.id,
        data: dataUpdate,
      };
      const update = await table.update(dataFixUpdate);
      return update;
    } else if (resource === "front_pages") {
      let dataEdit = params.data;
      const table = client.getTable(resource);

      if (params.data.priorityValue) {
        const { position } = params.data;
        const get = await table.lookup(params.data.priorityValue);
        const prioversus = get.data.priority;

        const modifier = position ? (position === "before" ? -1 : 1) : 0;
        const modScale = Math.ceil(Math.random() * 5);
        dataEdit = {
          ...dataEdit,
          priority: prioversus + Number(modifier * modScale),
        };
      }

      if (params.data.pictures) {
        console.log("new pic");
        const upload = await client.storage.upload({
          params: {
            filename: `frontpages/${params.data.pictures.title}`,
            bucket: isProd.publicBucket,
            expire: "600",
          },
          file: params.data.pictures.rawFile,
        });
        console.log(upload);

        const download = await client.storage.downloadUrl({
          params: {
            bucket: isProd.publicBucket,
          },
          fileId: upload.data.id,
        });

        console.log(download);
        const urlImg = download.data.url.split("?");
        dataEdit = {
          ...dataEdit,
          img_cover: urlImg[0],
        };
      }

      const dataUpdate = {
        id: params.id,
        data: dataEdit,
      };
      const update = await table.update(dataUpdate);
      return update;
    } else if (resource === "courses") {
      let dataEdit = params.data;
      if (params.data.pictures) {
        console.log("new pic");
        const upload = await client.storage.upload({
          params: {
            filename: `cover_img/${params.data.pictures.title}`,
            bucket: isProd.publicBucket,
            expire: "600",
          },
          file: params.data.pictures.rawFile,
        });
        console.log(upload);

        const download = await client.storage.downloadUrl({
          params: {
            bucket: isProd.publicBucket,
          },
          fileId: upload.data.id,
        });

        console.log(download);
        const urlImg = download.data.url.split("?");
        dataEdit = {
          ...dataEdit,
          cover_img: urlImg[0],
        };
      }

      if (params.data.intro) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        console.log(params.data);
        const { task: uploadTask, uid } = await uploadIntroWithProgress({
          file: params.data.intro.rawFile,
        });
        console.log(uploadTask);
        console.log(uid);
        const proses = await new Promise((resolve, reject) => {
          uploadTask.on("done", (val) => {
            // console.log("done");
            // console.log(val);
            resolve(val);
            submitStatusJobCheck(uid, params.data.id);
            // setIsUploading(false);
            console.log(
              "Video has been uploaded. It may take 5 minutes to process."
            );
            // closeAndResetAll();
          });
          uploadTask.on("progress", (val) => {
            // console.log("listener progress: ", val);
            // setUploadProgress(val);
            console.log(val);
          });

          uploadTask.on("error", (val) => {
            // console.log("error");
            // console.log(val);
            reject(val);
            // setIsUploading(false);
            console.log("Upload video failed");
          });
        });
        console.log(proses);
      }

      const table = client.getTable(resource);
      const dataUpdate = {
        id: params.id,
        data: dataEdit,
      };
      const update = await table.update(dataUpdate);
      return update;
    } else {
      console.log(params);
      const table = client.getTable(resource);
      const dataUpdate = {
        id: params.id,
        data: params.data,
      };
      const update = await table.update(dataUpdate);
      console.log(dataUpdate);
      console.log("update");
      console.log(update);
      return update;
    }
  } catch (e) {
    console.log(e);
    await forceLogout(e);
  }
};
export default update;
