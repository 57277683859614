import React from "react";
import { AppBar, UserMenu, MenuItemLink } from "react-admin";

import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import QRCode from "qrcode.react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import client from "../utils/mococlient";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
// import forceLogout from "../utils/forceLogout";
// import Logo from "./Logo";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
});

const CustomAppBar = (props) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openDialogConfirm, setOpenDialogConfirm] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [showQR, setShowQR] = React.useState(false);
  const [uri, setUri] = React.useState("");
  const [profile, setProfile] = React.useState({ name: "-" });

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpenSnack(false);
    setPassword("");
  };

  const handleClickOpenDialogConfirm = () => {
    setOpenDialogConfirm(true);
  };

  const handleCloseDialogConfirm = () => {
    setOpenDialogConfirm(false);
  };

  const handleCloseShowQR = () => {
    setShowQR(false);
  };

  const resetOtp = async () => {
    setLoading(true);
    const otp = await client.invokeApi("otp-generate-uri", { password });
    console.log(otp);
    setLoading(false);

    if (otp.error && otp.error.message === "Invalid Password!") {
      setPassword("");
      setOpenDialogConfirm(false);
      setOpenSnack(true);
    } else {
      setPassword("");
      setOpenDialogConfirm(false);
      setOpenDialog(false);
      setUri(otp.data);
      setShowQR(true);
    }
  };

  const fetchProfile = async () => {
    // try {
    //   const profile = await client.user.get();
    //   if (profile.error) {
    //     await forceLogout(profile);
    //   } else {
    //     setProfile(profile.data);
    //   }
    // } catch (e) {
    //   await forceLogout(e);
    // }
    const profile = JSON.parse(localStorage.getItem("permission"));
    console.log(profile);
    setProfile(profile);
  };

  React.useEffect(() => {
    fetchProfile();
  }, []);

  const CustomUserMenu = (props) => {
    return (
      <UserMenu {...props}>
        <MenuItem disabled>{profile?.name || profile?.email || "-"}</MenuItem>
        <MenuItemLink
          to={"/profile"}
          primaryText={"Edit Profile"}
          leftIcon={<EditIcon />}
        />
      </UserMenu>
    );
  };

  return (
    <React.Fragment>
      <AppBar
        {...props}
        elevation={1}
        // style={{ backgroundColor: "#eaeaea", color: "black" }}
        userMenu={<CustomUserMenu profile={profile} />}
      >
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
        {/* <Logo /> */}
        <span className={classes.spacer} />
        <Tooltip title="Reset OTP" aria-label="barcode">
          <IconButton aria-label="Barcode" onClick={handleClickOpenDialog}>
            <FontAwesomeIcon icon={faQrcode} style={{ color: "white" }} />
          </IconButton>
        </Tooltip>
      </AppBar>
      <Dialog
        open={openDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        // fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          Reset OTP secret permission
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            alignItems="center"
            alignContent="center"
            // style={{ height: "200px", marginTop: "200px" }}
          >
            {openSnack && (
              <Alert
                severity="error"
                style={{ width: "88%", marginBottom: "10px" }}
              >
                Invalid Password
              </Alert>
            )}

            <TextField
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="outlined-basic"
              label="Password"
              variant="outlined"
              type="password"
              fullWidth
              style={{ marginBottom: "10px" }}
            />

            <Button
              onClick={handleCloseDialog}
              color="primary"
              variant="contained"
              // eslint-disable-next-line react/jsx-no-duplicate-props
              onClick={handleClickOpenDialogConfirm}
              fullWidth
              disabled={password === ""}
            >
              reset Qrcode
            </Button>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogConfirm}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        // fullWidth={true}
      >
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid
              container
              direction="column"
              alignItems="center"
              alignContent="center"
            >
              <Grid item>Are you sure to reset secret?</Grid>

              <div style={{ marginTop: "10px" }}>
                <Button
                  onClick={resetOtp}
                  color="primary"
                  variant="contained"
                  style={{ background: "#00c129", marginRight: "10px" }}
                >
                  Yes
                </Button>
                <Button
                  onClick={handleCloseDialogConfirm}
                  color="primary"
                  variant="contained"
                  style={{ background: "#ff3535" }}
                >
                  No
                </Button>
              </div>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
      <Dialog
        open={showQR}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        // fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          Scan this QRcode with Google Authenticator
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            alignItems="center"
            alignContent="center"
            // style={{ height: "200px", marginTop: "200px" }}
          >
            <QRCode value={uri} />,
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseShowQR} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default CustomAppBar;
