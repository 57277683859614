import client from "../../utils/mococlient";
import { getUsersById } from "../../graphql/users";
import { getCategoryById } from "../../graphql/category";
import {
  getContentItemsById,
  getContentsById,
  getCoursesById,
  // allCoursesList,
} from "../../graphql/content";

import forceLogout from "../../utils/forceLogout";
// import { GraphQLList } from "graphql";

const getOne = async (resource, params) => {
  let gql;
  try {
    console.log("get one " + resource);
    const table = client.getTable(resource);
    console.log(params);
    let get;
    if (resource === "users") {
      const query = {
        query: getUsersById,
        variables: {
          id: params.id,
        },
      };
      gql = await client.gql.query(query);
      console.log(gql);

      get = { data: gql.getUsersById };
      // get = await table.include("courses,ledgers").lookup(params.id);
    } else if (resource === "certificate") {
      console.log(params);
      const getOne = await client.invokeApi("get-certificate", {
        enrollment_id: params.id,
      });
      get = { data: { ...getOne.data, id: params.id } };
      console.log(getOne);
    } else if (resource === "contents") {
      console.log("hereeee");
      const query = {
        query: getContentsById,
        variables: {
          id: params.id,
        },
      };
      gql = await client.gql.query(query);
      get = { data: gql.getContentsById };
    } else if (resource === "category") {
      const query = {
        query: getCategoryById,
        variables: {
          id: params.id,
        },
      };
      gql = await client.gql.query(query);
      get = { data: gql.getCategoryById };
    } else if (resource === "content_items") {
      const query = {
        query: getContentItemsById,
        variables: {
          id: params.id,
        },
      };
      gql = await client.gql.query(query);
      get = { data: gql.getContentItemsById };
    } else {
      if (resource === "vouchers") {
        get = await table.include(["courses", "users"]).lookup(params.id);
      } else if (resource === "promoted_courses") {
        get = await table.include(["courses"]).lookup(params.id);
      } else if (resource === "courses") {
        // get = await table.include("enrollments").lookup(params.id);
        const query = {
          query: getCoursesById,
          variables: {
            id: params.id,
          },
        };
        gql = await client.gql.query(query);
        get = { data: gql.getCoursesById };
      } else if (resource === "reviews") {
        get = await table.include(["courses", "users"]).lookup(params.id);
      } else {
        get = await table.lookup(params.id);
      }
    }

    console.log(get);

    return get;
  } catch (e) {
    await forceLogout(e);
  }
};

export default getOne;
