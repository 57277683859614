/* eslint-disable no-unused-vars */
// import { fetchUtils } from 'react-admin';
// import { stringify } from 'query-string';
// const apiUrl = 'https://my.api.com/';
// const httpClient = fetchUtils.fetchJson;
// const mocosdk = require("@mocobaas/client-js");
// const client = new mocosdk.init("http://mooc-test-api.hash.id");
import client from "../utils/mococlient";

export default {
  login: async (params) => {
    try {
      console.log(params);
      const login = await client.auth.login("local", params);
      console.log(login);
      const role = await client.user.get();
      localStorage.setItem("permission", JSON.stringify(role));
      return login;
    } catch (error) {
      console.log("error");

      return error;
    }
  },
  checkAuth: () => {
    // console.log("check");
    return localStorage.getItem("MBaaS.auth.accessToken")
      ? Promise.resolve()
      : Promise.reject();
  },
  getPermissions: () => {
    const data = localStorage.getItem("permission");
    return data ? Promise.resolve(data) : Promise.reject();
  },
  logout: async () => {
    console.log("logout");
    const logout = await client.user.logout();
    localStorage.removeItem("permission");
    return Promise.resolve();
  },
};
