import React from "react";
import { List, Datagrid, TextField } from "react-admin";
import { ExportButton } from "react-admin";
import Toolbar from "@material-ui/core/Toolbar";
import {
  Edit,
  SimpleForm,
  DateField,
  EditButton,
  required,
  ReferenceInput,
  ShowController,
  FunctionField,
  BooleanField,
  BooleanInput,
  Filter,
  SaveButton,
  AutocompleteInput,
} from "react-admin";

export const ReviewList = (props) => {
  return (
    <React.Fragment>
      <List
        {...props}
        actions={<PostActions />}
        bulkActionButtons={false}
        title="Front Pages"
        sort={{ field: "pin", order: "DESC" }}
        filters={<PostFilter />}
        empty={false}
      >
        <Datagrid>
          <TextField label="Title" source="courses.title" sortable={false} />
          <TextField label="User" source="users.name" sortable={false} />
          <FunctionField
            label="Rating"
            render={(record) => record.rating + " ⭐"}
          />
          <FunctionField
            label="Comment"
            render={(record) =>
              record.comment === null ? "-" : record.comment
            }
          />
          <BooleanField source="pin" />
          <DateField
            locales="en-TT"
            label="Created"
            source="created_at"
            showTime
          />
          <DateField
            locales="en-TT"
            label="Updated"
            source="updated_at"
            showTime
          />
          <EditButton />
        </Datagrid>
      </List>
    </React.Fragment>
  );
};

const PostFilter = (props) => {
  // console.log(props);
  return (
    <Filter {...props}>
      <ReferenceInput
        label="Select Course"
        source="course_id"
        alwaysOn
        reference="courses"
        sort={{ field: "title", order: "ASC" }}
        perPage={100000}
      >
        <AutocompleteInput optionText="title" style={{ background: "white" }} />
      </ReferenceInput>
    </Filter>
  );
};

const PostActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <Toolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    {/* <CreateButton basePath={basePath} /> */}
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

export const ReviewEdit = (props) => {
  // console.log(props);

  const ReviewEditToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton {...props} redirect="/reviews" disabled={props.pristine} />
    </Toolbar>
  );

  return (
    <ShowController {...props}>
      {(controllerProps) => (
        <Edit
          {...props}
          {...controllerProps}
          undoable={false}
          title="Edit Front Pages"
        >
          <SimpleForm toolbar={<ReviewEditToolbar />}>
            <TextField label="Title" source="courses.title" />
            <TextField label="User" source="users.name" />
            <FunctionField
              label="Rating"
              render={(record) => record.rating + " ⭐"}
            />
            <FunctionField
              label="Comment"
              render={(record) =>
                record.comment === null ? "-" : record.comment
              }
            />
            <BooleanInput
              label="pin reviews"
              source="pin"
              validate={[required()]}
            />
          </SimpleForm>
        </Edit>
      )}
    </ShowController>
  );
};
