import React from "react";
import { Admin, Resource } from "react-admin";
import authProvider from "../Provider/authProvider";
// import { Book, PostCreate, PostEdit } from "./Book";
// import { Rent, PostCreateRent, PostEditRent } from "./Rent";
import Dashboard from "../component/Dashboard";
import LoginPage from "./LoginPage";
import dataProvider from "../Provider/dataProvider";
import { User, PostCreate, PostEdit, UserShow } from "./User";
import { Voucher, VoucherCreate, VoucherEdit, VoucherShow } from "./Voucher";
import { Topups } from "./Topups";
import {
  SchedulerList,
  SchedulerCreate,
  SchedulerEdit,
  SchedulerShow,
} from "./MasterScheduler";
import { Category, CategoryCreate, CategoryEdit, ExpandShow } from "./Category";
import { VideoList, VideoCreate } from "./Video";
import { CoursesList, CourseEdit, CourseCreate, CourseShow } from "./Courses";
import { ContentList, ContentEdit, ContentCreate } from "./Content";
import { ContentItemEdit, ContentItemsCreate } from "./ContentItem";
import { CertificateList } from "./Certificate";
import { redirectOnLanding } from "../utils/variabels";
import { LogsList } from "./ActivityLogs";
import { FrontList, FrontCreate, FrontEdit } from "./FrontPages";
import { ReviewList, ReviewEdit } from "./Reviews";
import { TransferCreate, TransferList } from "./TransferPoint";
import { RevenueList } from "./Revenue";
import {
  CategoryPromotedList,
  CategoryPromotedCreate,
  CategoryPromotedEdit,
} from "./CategoryFront";
import {
  PromotedList,
  PromotedCreate,
  PromotedEdit,
  PromotedShow,
} from "./PromotedCourse";
import PeopleIcon from "@material-ui/icons/People";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import CategoryIcon from "@material-ui/icons/Category";
import MovieIcon from "@material-ui/icons/Movie";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DescriptionIcon from "@material-ui/icons/Description";
import TimelineIcon from "@material-ui/icons/Timeline";
import PublishIcon from "@material-ui/icons/Publish";
import ScheduleIcon from "@material-ui/icons/Schedule";
import BallotIcon from "@material-ui/icons/Ballot";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import StarsIcon from "@material-ui/icons/Stars";
import Layout from "../layouts";
import customRoutes from "../utils/customRoutes";
import client from "../utils/mococlient";
import forceLogout from "../utils/forceLogout";
import InputIcon from "@material-ui/icons/Input";
import SchoolIcon from "@material-ui/icons/School";
// import customRoutes from "./customRoutes";

import { createBrowserHistory as createHistory } from "history";

const Mooc = () => {
  const history = createHistory();
  // console.log("landing page");

  // eslint-disable-next-line no-unused-vars
  const checkRole = async () => {
    try {
      if (!window.location.pathname.includes("/login")) {
        // console.log("not /login");
        const permission = localStorage.getItem("MBaaS.auth.accessToken");
        if (permission) {
          const role = await client.user.get();
          // console.log(role.data.local_profile.roles[0]);
          // eslint-disable-next-line no-unused-vars
          const redirected = redirectOnLanding(
            role.data.local_profile.roles[0]
          );
          // console.log(redirected);
        } else {
          console.log("not staff/admin");
          // redirect if permission empty
          window.location.replace("/login");
        }
      } else {
        console.log("login page");
      }
    } catch (e) {
      await forceLogout(e);
    }
  };

  React.useEffect(() => {
    // checkRole();
  }, []);

  return (
    <Admin
      dataProvider={dataProvider}
      dashboard={Dashboard}
      loginPage={LoginPage}
      authProvider={authProvider}
      history={history}
      layout={Layout}
      customRoutes={customRoutes}
    >
      <Resource
        name="users"
        options={{ label: "Users" }}
        list={User}
        create={PostCreate}
        edit={PostEdit}
        show={UserShow}
        icon={PeopleIcon}
      />
      <Resource
        name="vouchers"
        options={{ label: "Vouchers" }}
        icon={ConfirmationNumberIcon}
        list={Voucher}
        create={VoucherCreate}
        edit={VoucherEdit}
        show={VoucherShow}
      />
      <Resource
        name="topups"
        options={{ label: "Payments" }}
        icon={LocalAtmIcon}
        list={Topups}
      />
      <Resource
        name="transfers"
        options={{ label: "Transfer point" }}
        icon={InputIcon}
        list={TransferList}
        create={TransferCreate}
        // edit={FrontEdit}
      />

      <Resource
        name="tutor_revenues"
        options={{ label: "Tutor Revenues" }}
        icon={SchoolIcon}
        list={RevenueList}
        // create={TransferCreate}
        // edit={FrontEdit}
      />
      <Resource
        name="category"
        create={CategoryCreate}
        edit={CategoryEdit}
        options={{ label: "Categories" }}
        icon={CategoryIcon}
        list={Category}
        show={ExpandShow}
      />
      <Resource
        name="content_videos"
        options={{ label: "Videos" }}
        icon={MovieIcon}
        list={VideoList}
        create={VideoCreate}
      />
      <Resource
        name="master_schedules"
        options={{ label: "Schedules" }}
        icon={ScheduleIcon}
        list={SchedulerList}
        edit={SchedulerEdit}
        create={SchedulerCreate}
        show={SchedulerShow}
      />
      <Resource
        name="courses"
        options={{ label: "Courses" }}
        icon={MenuBookIcon}
        list={CoursesList}
        edit={CourseEdit}
        create={CourseCreate}
        show={CourseShow}
      />
      <Resource
        name="contents"
        options={{ label: "Contents" }}
        icon={AttachFileIcon}
        create={ContentCreate}
        list={ContentList}
        edit={ContentEdit}
      />
      <Resource
        name="content_items"
        edit={ContentItemEdit}
        create={ContentItemsCreate}
      />

      <Resource name="levels" />
      <Resource
        name="certificate"
        options={{ label: "Certificate" }}
        icon={DescriptionIcon}
        list={CertificateList}
      />
      <Resource
        name="reviews"
        options={{ label: "Reviews" }}
        icon={StarsIcon}
        list={ReviewList}
        edit={ReviewEdit}
      />
      <Resource
        name="front_pages"
        options={{ label: "Front Pages" }}
        icon={BallotIcon}
        list={FrontList}
        create={FrontCreate}
        edit={FrontEdit}
      />

      <Resource
        name="category_front_pages"
        options={{ label: "Promoted category" }}
        icon={BubbleChartIcon}
        list={CategoryPromotedList}
        create={CategoryPromotedCreate}
        edit={CategoryPromotedEdit}
      />
      <Resource
        name="promoted_courses"
        options={{ label: "Promoted courses" }}
        icon={PublishIcon}
        list={PromotedList}
        create={PromotedCreate}
        edit={PromotedEdit}
        show={PromotedShow}
      />
      <Resource
        name="activity_logs"
        options={{ label: "Activity logs" }}
        icon={TimelineIcon}
        list={LogsList}
      />

      <Resource name="categoryNoLimit" />
    </Admin>
  );
};

export default Mooc;
