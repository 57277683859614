import React from "react";
import { List, Datagrid, TextField } from "react-admin";
import { ExportButton } from "react-admin";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import {
  Create,
  Edit,
  SimpleForm,
  DateField,
  CreateButton,
  EditButton,
  required,
  SelectInput,
  FormDataConsumer,
  ShowController,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  TextInput,
  ReferenceArrayField,
  ChipField,
  SingleFieldList,
  Show,
  useInput,
  DateTimeInput,
  SimpleShowLayout,
} from "react-admin";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

export const SchedulerList = (props) => {
  return (
    <React.Fragment>
      <List
        {...props}
        actions={<PostActions />}
        bulkActionButtons={false}
        title="Master Scheduler"
        sort={{ field: "created_at", order: "DESC" }}
        //   filters={<PostFilter />}
      >
        <Datagrid rowClick="show">
          <TextField label="Title" source="title" />
          <CourseType />
          <DateField
            locales="en-TT"
            label="Start Schedule"
            source="start_at"
            showTime
          />
          <DateField
            locales="en-TT"
            label="Created"
            source="created_at"
            showTime
          />
          <DateField
            locales="en-TT"
            label="Updated"
            source="updated_at"
            showTime
          />
          <EditButton />
        </Datagrid>
      </List>
    </React.Fragment>
  );
};

const PostActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <Toolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);
const DateTimeInputCustom = (props) => {
  const fs = useInput(props);

  const [selectedDate, setSelectedDate] = React.useState(Date.now());
  React.useState(() => {
    if (fs.input.value) {
      setSelectedDate(fs.input.value);
    } else {
      fs.input.onChange({
        target: { value: moment(Date.now()).format() },
      });
    }
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log(moment(date).format());
    fs.input.onChange({ target: { value: moment(date).format() } });
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container>
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label="Select Date"
          format="DD/MM/yyyy"
          value={selectedDate}
          style={{ marginRight: "20px" }}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <KeyboardTimePicker
          margin="normal"
          id="time-picker"
          ampm={false}
          label="Select Time"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
export const SchedulerCreate = (props) => {
  return (
    <Create {...props} title="Add Front Pages">
      <SimpleForm redirect="list">
        <TextInput label="Title" source="title" validate={[required()]} />
        {/* <DateTimeInput source="start_at" validate={[required()]} /> */}

        <SelectInput
          label="Course"
          source="course_type"
          validate={[required()]}
          choices={[
            { id: "all", name: "All Course" },
            { id: "select", name: "Select Course" },
          ]}
        />
        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => {
            if (formData.course_type === "select") {
              return (
                <ReferenceArrayInput
                  label="Course"
                  source="course_ids"
                  reference="courses"
                  validate={[required()]}
                  filter={{ draft: "false" }}
                  perPage={100000}
                  fullWidth
                >
                  <AutocompleteArrayInput optionText="title" />
                </ReferenceArrayInput>
              );
            }
          }}
        </FormDataConsumer>
        <DateTimeInputCustom
          onChange={(value) => console.log(value)}
          source="start_at"
          label="start_at"
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  );
};

export const SchedulerEdit = (props) => {
  // console.log(props);
  return (
    <ShowController {...props}>
      {(controllerProps) => (
        <Edit
          {...props}
          {...controllerProps}
          undoable={false}
          title="Edit Front Pages"
        >
          <SimpleForm>
            <TextInput label="Title" source="title" validate={[required()]} />
            {/* <DateTimeInput source="start_at" validate={[required()]} /> */}

            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => {
                return (
                  <SelectInput
                    label="Course"
                    source="course_type"
                    initialValue={
                      formData.course_ids === null ? "all" : "select"
                    }
                    choices={[
                      { id: "all", name: "All Course" },
                      { id: "select", name: "Select Course" },
                    ]}
                  />
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => {
                if (formData.course_type === "select") {
                  return (
                    <ReferenceArrayInput
                      label="Course"
                      source="course_ids"
                      reference="courses"
                      validate={[required()]}
                      perPage={100000}
                      filter={{ draft: "false" }}
                      fullWidth
                    >
                      <AutocompleteArrayInput optionText="title" />
                    </ReferenceArrayInput>
                  );
                }
              }}
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => {
                if (formData.course_type === "all") {
                  //   formData.course_type = "all";
                  console.log("here");
                  formData.course_ids = undefined;
                }
              }}
            </FormDataConsumer>
            <DateTimeInputCustom
              onChange={(value) => console.log(value)}
              source="start_at"
              label="start_at"
              validate={[required()]}
            />
          </SimpleForm>
        </Edit>
      )}
    </ShowController>
  );
};

const UserField = ({ record = {} }) => (
  <span>{record.user_id === null ? "Anonymous" : record.users.name}</span>
);
UserField.defaultProps = { label: "User", addLabel: true };

const CourseType = ({ record = {} }) => (
  <span>{record.course_ids === null ? "All Course" : "Selected Course"}</span>
);
CourseType.defaultProps = { label: "Type", addLabel: true };

export const SchedulerShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <Show {...props} {...controllerProps} title="Detail Schedule">
        <SimpleShowLayout>
          <TextField label="Title" source="title" />
          <CourseType />
          {controllerProps.record &&
            controllerProps.record.course_ids &&
            controllerProps.record.course_ids.length !== 0 && (
              <ReferenceArrayField
                label="Selected course"
                source="course_ids"
                reference="courses"
              >
                <SingleFieldList>
                  <ChipField source="title" />
                </SingleFieldList>
              </ReferenceArrayField>
            )}
          <DateField
            locales="en-TT"
            label="Start Schedule"
            source="start_at"
            showTime
          />
          <DateField
            locales="en-TT"
            label="Created"
            source="created_at"
            showTime
          />
          <DateField
            locales="en-TT"
            label="Updated"
            source="updated_at"
            showTime
          />
        </SimpleShowLayout>
      </Show>
    )}
  </ShowController>
);
