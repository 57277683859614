import gql from "graphql-tag";

export const allTopupsList = gql`
  query allTopupsList(
    $limit: Int
    $offset: Int
    $sort: TopupsSortEnum
    $order: PaginationOrderEnum
    $filter: String
    $where: TopupsWhereInput
  ) {
    allTopupsList(
      limit: $limit
      offset: $offset
      sort: $sort
      order: $order
      filter: $filter
      where: $where
    ) {
      data {
        id
        created_at
        updated_at
        created_by
        amount
        rates
        metadata
        topup_histories_topup_id_list(order: DESC, sort: created_at) {
          data {
            id
            status
            metadata
          }
        }
        created_by_data {
          id
          roles
          email
          name
        }
      }
      count
    }
  }
`;
