import client from "../../utils/mococlient";
import isProd from "../../utils/variabels";
import { getContentItemsById, getContentsById } from "../../graphql/content";

import {
  uploadIntroWithProgress,
  submitStatusJobCheck,
} from "../VideoProvider";
import forceLogout from "../../utils/forceLogout";
const { randomBytes, pbkdf2Sync } = require("crypto");

const create = async (resource, params) => {
  try {
    const table = client.getTable(resource);
    let data = params.data;
    console.log(params.data);

    if (resource === "users") {
      const salt = randomBytes(16).toString("hex");
      const hash = pbkdf2Sync(
        params.data.password,
        salt,
        2048,
        32,
        "sha512"
      ).toString("hex");
      const pwHash = [salt, hash].join("$");
      data = {
        ...data,
        roles: data.roles,
        password: pwHash,
        verified: true,
      };
    } else if (resource === "transfers") {
      const transfer = await client.invokeApi("transfer-saldo", {
        recipient_id: params.data.recipient_id,
        transfer_amount: parseInt(params.data.amount),
        token: params.data.token,
      });
      console.log(transfer);
      return transfer;
    } else if (resource === "promoted_courses") {
      console.log(params);
      if (params.data.priorityValue) {
        const { position } = params.data;
        // const query = {
        //   query: getContentsById,
        //   variables: {
        //     id: params.data.priorityValue,
        //   },
        // };
        // const gql = await client.gql.query(query);
        // const prioversus = gql.getContentsById.priority;

        const get = await table.lookup(params.data.priorityValue);
        const prioversus = get.data.priority;

        // console.log(gql.getContentsById);
        // console.log("PRIO VERSUS : " + prioversus);

        const modifier = position ? (position === "before" ? -1 : 1) : 0;
        const modScale = Math.ceil(Math.random() * 5);
        data = {
          ...params.data,
          priority: prioversus + Number(modifier * modScale),
        };
      } else {
        data = {
          ...params.data,
          priority: 1,
        };
      }
    } else if (resource === "category_front_pages") {
      console.log(params);
      if (params.data.priorityValue) {
        const { position } = params.data;

        const get = await table.lookup(params.data.priorityValue);
        const prioversus = get.data.priority;

        const modifier = position ? (position === "before" ? -1 : 1) : 0;
        const modScale = Math.ceil(Math.random() * 5);
        data = {
          ...params.data,
          priority: prioversus + Number(modifier * modScale),
        };
      } else {
        data = {
          ...params.data,
          priority: 1000,
        };
      }
    } else if (resource === "content_videos") {
      console.log(resource);
      // console.log(params);
      const upload = await client.storage.upload({
        params: {
          filename: params.data.files.title,
          bucket: isProd.privateBucket,
          expire: "600",
        },
        file: params.data.files.rawFile,
        progressCallback: (progressVal) => console.log(progressVal),
      });
      console.log(upload);
      data = { raw_file: upload.data.id };

      // const download = await client.storage.downloadUrl({
      //   params: {
      //     bucket: isProd.privateBucket,
      //     expire: "600",
      //   },
      //   fileId: upload.data.id,
      // });

      // console.log(download);
    } else if (resource === "contents") {
      console.log(params);
      if (params.data.priorityValue) {
        const { position } = params.data;
        const query = {
          query: getContentsById,
          variables: {
            id: params.data.priorityValue,
          },
        };
        const gql = await client.gql.query(query);
        const prioversus = gql.getContentsById.priority;
        // console.log(gql.getContentsById);
        // console.log("PRIO VERSUS : " + prioversus);

        const modifier = position ? (position === "before" ? -1 : 1) : 0;
        const modScale = Math.ceil(Math.random() * 5);
        data = {
          ...params.data,
          priority: prioversus + Number(modifier * modScale),
          course_id: localStorage.getItem("selectCourse"),
        };
      } else {
        data = {
          ...params.data,
          priority: 1000,
          course_id: localStorage.getItem("selectCourse"),
        };
      }
    } else if (resource === "content_items") {
      console.log(params);

      //attach video if selected
      if (params.data.selectVideo) {
        const table = client.getTable("content_videos");
        const dataFixUpdate = {
          id: params.data.selectVideo,
          data: { item_id: params.data.id },
          progressCallback: (progressVal) => console.log(progressVal),
        };
        const updateVid = await table.update(dataFixUpdate);
        console.log(updateVid);
      }

      if (params.data.priorityValue) {
        const { position } = params.data;
        const query = {
          query: getContentItemsById,
          variables: {
            id: params.data.priorityValue,
          },
        };
        const gql = await client.gql.query(query);
        const prioversus = gql.getContentItemsById.priority;
        // console.log(gql.getContentsById);
        // console.log("PRIO VERSUS : " + prioversus);

        const modifier = position ? (position === "before" ? -1 : 1) : 0;
        const modScale = Math.ceil(Math.random() * 5);
        data = {
          ...params.data,
          priority: prioversus + Number(modifier * modScale),
        };
      } else {
        data = {
          ...params.data,
          priority: 1000,
        };
      }
    } else if (resource === "courses") {
      console.log(params.data);
      if (params.data.pictures) {
        const upload = await client.storage.upload({
          params: {
            filename: `cover_img/${params.data.pictures.title}`,
            bucket: isProd.publicBucket,
            expire: "600",
          },
          file: params.data.pictures.rawFile,
        });
        console.log(upload);

        const download = await client.storage.downloadUrl({
          params: {
            bucket: isProd.publicBucket,
          },
          fileId: upload.data.id,
        });

        console.log(download);
        const urlImg = download.data.url.split("?");
        data = {
          ...data,
          cover_img: urlImg[0],
        };
      }
    } else if (resource === "front_pages") {
      console.log(params.data);

      if (params.data.priorityValue) {
        const { position } = params.data;

        const get = await table.lookup(params.data.priorityValue);
        const prioversus = get.data.priority;

        const modifier = position ? (position === "before" ? -1 : 1) : 0;
        const modScale = Math.ceil(Math.random() * 5);
        data = {
          ...params.data,
          priority: prioversus + Number(modifier * modScale),
        };
      } else {
        data = {
          ...params.data,
          priority: 1000,
        };
      }

      if (params.data.pictures) {
        const upload = await client.storage.upload({
          params: {
            filename: `frontpages/${params.data.pictures.title}`,
            bucket: isProd.publicBucket,
            expire: "600",
          },
          file: params.data.pictures.rawFile,
        });
        console.log(upload);

        const download = await client.storage.downloadUrl({
          params: {
            bucket: isProd.publicBucket,
          },
          fileId: upload.data.id,
        });

        console.log(download);
        const urlImg = download.data.url.split("?");
        data = {
          ...data,
          img_cover: urlImg[0],
        };
      }
    } else if (resource === "vouchers") {
      console.log(params.data);
      if (params.data.all_user === true) {
        data = {
          ...data,
          user_ids: null,
        };
      }
      if (params.data.all_course === true) {
        data = {
          ...data,
          course_ids: null,
        };
      }
    } else if (resource === "master_schedules") {
      if (params.data.course_type === "all") {
        data = {
          ...data,
          course_ids: null,
        };
      }
    }

    let add;

    add = await table.insert(data);

    if (params.data.files) {
      console.log("UPLOAD");
      const upload = await client.storage.upload({
        params: {
          filename: params.data.files.title,
          bucket: isProd.privateBucket,
          expire: "600",
        },
        file: params.data.files.rawFile,
      });
      console.log(upload);

      const table = client.getTable("content_videos");
      // const dataFixUpdate = {
      //   id: params.data.selectVideo,
      //   data: { item_id: add.data.id },
      // };
      const dataFixCreate = {
        item_id: add.data.id,
        raw_file: upload.data.id,
      };
      // const updateVid = await table.update(dataFixUpdate);
      const createVid = await table.insert(dataFixCreate);
      console.log(createVid);
    }

    if (params.data.intro) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      console.log(params.data);
      const { task: uploadTask, uid } = await uploadIntroWithProgress({
        file: params.data.intro.rawFile,
      });
      console.log(uploadTask);
      console.log(uid);
      const proses = await new Promise((resolve, reject) => {
        uploadTask.on("done", (val) => {
          // console.log("done");
          // console.log(val);
          resolve(val);
          submitStatusJobCheck(uid, add.data.id);
          // setIsUploading(false);
          console.log(
            "Video has been uploaded. It may take 5 minutes to process."
          );
          // closeAndResetAll();
        });
        uploadTask.on("progress", (val) => {
          // console.log("listener progress: ", val);
          // setUploadProgress(val);
          console.log(val);
        });

        uploadTask.on("error", (val) => {
          // console.log("error");
          // console.log(val);
          reject(val);
          // setIsUploading(false);
          console.log("Upload video failed");
        });
      });
      console.log(proses);
    }

    return add;
  } catch (e) {
    await forceLogout(e);
    const status = 500;
    const statusText = "error ngab";
    const json = { error: "error duh" };
    return Promise.reject({
      status,
      statusText,
      json,
    });
  }
};

export default create;
