import React from "react";
import { List, Datagrid, TextField } from "react-admin";
import { ExportButton } from "react-admin";
import Toolbar from "@material-ui/core/Toolbar";
import {
  Create,
  Edit,
  SimpleForm,
  DateField,
  CreateButton,
  EditButton,
  required,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  ShowController,
  FunctionField,
  Show,
  SimpleShowLayout,
} from "react-admin";

export const PromotedList = (props) => {
  return (
    <React.Fragment>
      <List
        {...props}
        actions={<PostActions />}
        bulkActionButtons={false}
        title="Promoted Courses"
        sort={{ field: "priority", order: "ASC" }}
        //   filters={<PostFilter />}
      >
        <Datagrid rowClick="show">
          <TextField label="Title" source="courses.title" />
          <FunctionField
            label="Description"
            render={(record) =>
              record.courses.description.length > 25
                ? `${record.courses.description.substring(0, 55)}...`
                : record.courses.description
            }
          />
          <DateField
            locales="en-TT"
            label="Created"
            source="created_at"
            showTime
          />
          <DateField
            locales="en-TT"
            label="Updated"
            source="updated_at"
            showTime
          />
          <EditButton />
        </Datagrid>
      </List>
    </React.Fragment>
  );
};

const PostActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <Toolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

export const PromotedCreate = (props) => (
  <Create {...props} title="Add Promoted Course">
    <SimpleForm redirect="list">
      <ReferenceInput
        label="Select Course"
        source="course_id"
        reference="courses"
        sort={{ field: "title", order: "ASC" }}
        filter={{
          activity: "true",
        }}
        perPage={100000}
        validate={[required()]}
      >
        <AutocompleteInput optionText="title" style={{ background: "white" }} />
      </ReferenceInput>
      <SelectInput
        label="Priority Position"
        source="position"
        choices={[
          { id: "before", name: "Before" },
          { id: "after", name: "After" },
        ]}
      />

      <ReferenceInput
        label="Priority Anchor"
        source="priorityValue"
        reference="promoted_courses"
        perPage={100000}
        sort={{ field: "priority", order: "ASC" }}
      >
        <SelectInput optionText="courses.title" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const PromotedEdit = (props) => {
  // console.log(props);
  return (
    <ShowController {...props}>
      {(controllerProps) => (
        <Edit {...props} {...controllerProps} undoable={false}>
          <SimpleForm>
            <ReferenceInput
              label="Select Course"
              source="course_id"
              reference="courses"
              sort={{ field: "title", order: "ASC" }}
              filter={{
                draft: "false",
              }}
              perPage={100000}
              disabled
              validate={[required()]}
            >
              <AutocompleteInput
                optionText="title"
                style={{ background: "white" }}
              />
            </ReferenceInput>
            <SelectInput
              label="Priority Position"
              source="position"
              choices={[
                { id: "before", name: "Before" },
                { id: "after", name: "After" },
              ]}
            />

            <ReferenceInput
              label="Priority Anchor"
              source="priorityValue"
              reference="promoted_courses"
              perPage={100000}
              sort={{ field: "priority", order: "ASC" }}
            >
              <SelectInput optionText="courses.title" />
            </ReferenceInput>
          </SimpleForm>
        </Edit>
      )}
    </ShowController>
  );
};

const UserField = ({ record = {} }) => (
  <span>{record.user_id === null ? "Anonymous" : record.users.name}</span>
);
UserField.defaultProps = { label: "User", addLabel: true };

export const PromotedShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <Show {...props} {...controllerProps} title="Detail Promoted Courses">
        <SimpleShowLayout>
          <TextField label="Title" source="courses.title" />
          <TextField label="Description" source="courses.description" />
        </SimpleShowLayout>
      </Show>
    )}
  </ShowController>
);
