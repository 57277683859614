import React from "react";
import { List, Datagrid, TextField } from "react-admin";

// import Button from '@material-ui/core/Button';
// import { CreateButton, ExportButton } from "react-admin";
// import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import EditIcon from "@material-ui/icons/Edit";
import UpdateIcon from "@material-ui/icons/Update";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import TextFieldMui from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import client from "../utils/mococlient";
import Alerta from "@material-ui/lab/Alert";

import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateField,
  useRefresh,
  Filter,
} from "react-admin";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const Topups = (props) => {
  const refresh = useRefresh();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectData, setSelectData] = React.useState({});
  const [status, setStatus] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [wrongOtp, setWrongOtp] = React.useState(false);

  const handleClickSnack = () => {
    setOpen(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleOverwrite = async () => {
    setWrongOtp(false);
    const payload = {
      token: otp,
      status: status,
      topup_id: selectData.metadata.order_id,
    };
    const overwrite = await client.invokeApi("overwrite-topup", payload);
    console.log(overwrite);

    if (overwrite.error && overwrite.error.message === "Token not valid!") {
      console.log("error");
      setOtp("");
      setStatus("");
      setWrongOtp(true);
    } else {
      setOpenDialog(false);
      setOtp("");
      setStatus("");
      refresh();
      setSuccess(true);
    }
  };

  const handleCheck = async (id) => {
    const overwrite = await client.invokeApi("check-payment", {
      id,
    });
    console.log(overwrite);
    if (overwrite.data === "Please retry later") {
      handleClickSnack();
    }
    refresh();
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setWrongOtp(false);
    setOtp("");
    setStatus("");
  };

  const ChangeStatus = ({ record }) => {
    return (
      <Button
        variant="contained"
        size="small"
        color="primary"
        startIcon={<EditIcon />}
        onClick={() => {
          console.log(record);
          setSelectData(record);
          handleClickOpenDialog();
        }}
      >
        Status
      </Button>
    );
  };

  return (
    <React.Fragment>
      <List
        {...props}
        // actions={<PostActions />}
        filters={<TopupFilter />}
        bulkActionButtons={false}
        title="Payments"
        filt
      >
        <Datagrid>
          <TextField label="Name" source="created_by_data.name" />
          <TextField label="Email" source="created_by_data.email" />
          <TextField label="Amount" source="amount" />
          <SingleField />
          <DateField
            locales="en-TT"
            label="Created"
            source="created_at"
            showTime
          />
          <DateField
            locales="en-TT"
            label="Updated"
            source="updated_at"
            showTime
          />
          <CheckButton handleCheck={handleCheck} />
          <ChangeStatus />
        </Datagrid>
      </List>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity="warning">
          Please retry later
        </Alert>
      </Snackbar>
      <Snackbar
        open={success}
        autoHideDuration={3000}
        onClose={() => setSuccess(false)}
      >
        <Alert onClose={() => setSuccess(false)} severity="success">
          Success overwrite status payment
        </Alert>
      </Snackbar>
      <Dialog
        open={openDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">Change Status Payment</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>Edit status payment + otp</DialogContentText> */}
          {selectData.id && (
            <div>
              <TextFieldMui
                label="Username"
                value={selectData.created_by_data.name || ""}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                style={{ marginBottom: "10px" }}
              />
              <TextFieldMui
                label="Email"
                value={selectData.created_by_data.email || ""}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                style={{ marginBottom: "20px" }}
              />
              <TextFieldMui
                label="Payment"
                value={selectData.metadata.payment_type || ""}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                style={{ marginBottom: "20px" }}
              />
              <TextFieldMui
                label="Amount"
                value={selectData.amount || ""}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                style={{ marginBottom: "20px" }}
              />
              {wrongOtp && <Alerta severity="error">Token Invalid!</Alerta>}
              <TextFieldMui
                label="OTP"
                value={otp}
                fullWidth
                onChange={(e) => setOtp(e.target.value)}
                style={{ marginBottom: "20px" }}
              />
              <FormControl fullWidth style={{ marginBottom: "20px" }}>
                <InputLabel id="demo-simple-select-label">
                  Change Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem value="SUCCESS">Success</MenuItem>
                  <MenuItem value="PENDING">Pending</MenuItem>
                  <MenuItem value="PAID">Paid</MenuItem>
                  <MenuItem value="FAILED">Failed</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleOverwrite}
            color="primary"
            variant="contained"
            disabled={otp === "" || status === ""}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const CheckButton = ({ record, handleCheck }) => {
  return (
    <Button
      variant="contained"
      size="small"
      color="primary"
      startIcon={<UpdateIcon />}
      onClick={() => handleCheck(record.metadata.order_id)}
    >
      CHECK
    </Button>
  );
};

export const PostCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="nama" />
      <TextInput source="pengarang" />
    </SimpleForm>
  </Create>
);

export const PostEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <TextInput source="nama" />
      <TextInput source="pengarang" />
    </SimpleForm>
  </Edit>
);

const SingleField = ({ record = {} }) => (
  <span>{record.topup_histories_topup_id_list.data[0].status}</span>
);
SingleField.defaultProps = { label: "Status", addLabel: true };

const TopupFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Search by name or transaction id"
      source="search"
      helperText="Search by name or transaction id"
      alwaysOn
      style={{ width: "350px" }}
    />
  </Filter>
);
