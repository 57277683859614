import React from "react";
import { List, Datagrid, TextField } from "react-admin";
import {
  Filter,
  SelectArrayInput,
  required,
  Show,
  SimpleShowLayout,
  FunctionField,
  ShowController,
  ArrayField,
  BooleanField,
  TabbedShowLayout,
  Tab,
  BooleanInput,
} from "react-admin";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import TabMat from "@material-ui/core/Tab";
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,
  DateField,
  useNotify,
  useRefresh,
} from "react-admin";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import TeachingList from "../component/TeachingList";
import { Button } from "@material-ui/core";
import DetailUser from "../component/DetailUser";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const User = (props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // React.useEffect(() => {
  //   console.log(props);
  // }, [props]);

  return (
    <div>
      <List
        {...props}
        filters={<PostFilter />}
        bulkActionButtons={false}
        title="Users"
        empty={false}
        filter={{
          role: value === 0 ? "Staff" : value === 1 ? "Tutor" : "Student",
        }}
      >
        <div>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              variant="fullWidth"
            >
              <TabMat label="Staff" {...a11yProps(0)} />
              <TabMat label="Tutor" {...a11yProps(1)} />
              <TabMat label="Student" {...a11yProps(2)} />
            </Tabs>
          </AppBar>

          {value === 0 && (
            <Datagrid rowClick="show">
              <TextField label="Email" source="email" />
              <TextField label="Name" source="name" />

              {/* <FunctionField
                label="Nama"
                render={(record) => (record.name === null ? "-" : record.name)}
              /> */}
              <DateField
                locales="en-TT"
                label="Created"
                source="created_at"
                showTime
              />
              <DateField
                locales="en-TT"
                label="Updated"
                source="updated_at"
                showTime
              />
              <BooleanField source="verified" />
              <EditButton />
            </Datagrid>
          )}

          {value === 1 && (
            <Datagrid rowClick="show">
              <TextField label="Email" source="email" />
              <TextField label="Name" source="name" />

              {/* <FunctionField
                label="Nama"
                render={(record) => (record.name === null ? "-" : record.name)}
              />{" "} */}
              <DateField
                locales="en-TT"
                label="Created"
                source="created_at"
                showTime
              />
              <DateField
                locales="en-TT"
                label="Updated"
                source="updated_at"
                showTime
              />
              <BooleanField source="verified" />
              <EditButton />
            </Datagrid>
          )}

          {value === 2 && (
            <Datagrid rowClick="show">
              <TextField label="Email" source="email" />
              <TextField label="Name" source="name" />

              {/* <FunctionField
                label="Nama"
                render={(record) => (record.name === null ? "-" : record.name)}
              />

              <ReferenceField
                source="record"
                sortBy="name"
                reference="record"
                label="Name"
              >
                <TextField source="name" />
              </ReferenceField> */}

              <DateField
                locales="en-TT"
                label="Created"
                source="created_at"
                showTime
              />
              <DateField
                locales="en-TT"
                label="Updated"
                source="updated_at"
                showTime
              />
              <BooleanField source="verified" />
              <EditButton />
            </Datagrid>
          )}
        </div>
      </List>
    </div>
  );
};

export const PostCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = ({ data }) => {
    notify(`add post  saved`);
    refresh();
  };
  const onFailure = (error) => {
    notify(`Could not add post`);
    refresh();
  };

  return (
    <Create {...props} onSuccess={onSuccess} onFailure={onFailure}>
      <SimpleForm redirect="list">
        <TextInput source="email" validate={[required()]} type="email" />
        <TextInput source="name" validate={[required()]} />
        <TextInput source="password" validate={[required()]} type="password" />
        <SelectArrayInput
          source="roles"
          validate={[required()]}
          choices={[
            { id: "Staff", name: "Staff" },
            { id: "Tutor", name: "Tutor" },
            { id: "Student", name: "Student" },
          ]}
        />
      </SimpleForm>
    </Create>
  );
};

export const PostEdit = (props) => (
  <Edit {...props} title="Edit User">
    <SimpleForm>
      <TextInput label="Email" source="email" />
      <TextInput source="name" validate={[required()]} />
      <BooleanInput source="verified" label="verified user" />
      <SelectArrayInput
        source="roles"
        validate={[required()]}
        choices={[
          { id: "Staff", name: "Staff" },
          { id: "Tutor", name: "Tutor" },
          { id: "Student", name: "Student" },
        ]}
      />
    </SimpleForm>
  </Edit>
);

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by email" source="email" alwaysOn />
  </Filter>
);

const countScore = (quiz) => {
  let avg = 0;
  if (quiz.count === 0) {
    return 0;
  } else {
    for (var i = 0; i < quiz.count; ++i) {
      avg = quiz.data[i].point + avg;
    }
    return Math.floor(avg / quiz.count);
  }
};

export const UserShow = (props) => {
  const [open, setDialog] = React.useState(false);
  const [target, setTarget] = React.useState({});

  const LookDetail = ({ record }) => {
    return (
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={() => {
          console.log(record);
          setTarget(record);
          setDialog(true);
        }}
      >
        Detail
      </Button>
    );
  };

  return (
    <ShowController {...props}>
      {(controllerProps) => (
        <Show {...props} {...controllerProps} title="Detail User">
          <SimpleShowLayout>
            <TabbedShowLayout>
              <Tab label="info">
                <TextField source="email" />
                <FunctionField
                  label="Name"
                  render={(record) =>
                    record.name === null ? "-" : record.name
                  }
                />
                {controllerProps.record && controllerProps.record.roles && (
                  <RoleField />
                )}

                {controllerProps.record &&
                  controllerProps.record.ledgers_user_id_list &&
                  controllerProps.record.ledgers_user_id_list.data.length !==
                    0 && <FullNameField />}
              </Tab>
              {controllerProps.record &&
                controllerProps.record.roles &&
                controllerProps.record.roles.includes("Student") && (
                  <Tab label="enroll">
                    {controllerProps.record &&
                      controllerProps.record.student_enrolments_list &&
                      controllerProps.record.student_enrolments_list.data
                        .length !== 0 && (
                        <ArrayField
                          source="student_enrolments_list.data"
                          label="Student Enrollments List"
                        >
                          <Datagrid>
                            <TextField
                              source="course_id_data.title"
                              label="Title"
                            />

                            <TextField source="comment" label="Comment" />
                            <FunctionField
                              label="Score(Avg)"
                              render={(record) =>
                                countScore(record.quiz_result_list)
                              }
                            />
                            <FunctionField
                              label="Paid at"
                              render={(record) =>
                                record.order_id_data.paid_at === null
                                  ? "-"
                                  : moment(record.order_id_data.paid_at).format(
                                      "DD/MM/YYYY, h:mm:ss a"
                                    )
                              }
                            />
                            <DateField
                              locales="en-TT"
                              label="Start date"
                              source="schedules_enrollment_id_list.data[0].start_date"
                              showTime
                            />
                            <DateField
                              locales="en-TT"
                              label="Finish date"
                              source="schedules_enrollment_id_list.data[0].finish_date"
                              showTime
                            />
                            <LookDetail />
                          </Datagrid>
                        </ArrayField>
                      )}
                    <DetailUser
                      open={open}
                      setDialog={setDialog}
                      indexTarget={target}
                    />
                  </Tab>
                )}
              {controllerProps.record &&
                controllerProps.record.roles &&
                controllerProps.record.roles.includes("Tutor") && (
                  <Tab label="course">
                    <TeachingList />
                  </Tab>
                )}
            </TabbedShowLayout>
          </SimpleShowLayout>
        </Show>
      )}
    </ShowController>
  );
};

const FullNameField = ({ record = {} }) => (
  <span>{record.ledgers_user_id_list.data[0].ending_balance}</span>
);
FullNameField.defaultProps = { label: "Balance", addLabel: true };

const RoleField = ({ record = {} }) => {
  return (
    <div>
      {record.roles.map((name) => (
        <Chip label={name} style={{ marginRight: "5px" }} />
      ))}
    </div>
  );
};
RoleField.defaultProps = { label: "Role", addLabel: true };
