/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { allEnrollmentsList } from "../graphql/enroll";
import { allCoursesList } from "../graphql/content";
import client from "../utils/mococlient";
import Button from "@material-ui/core/Button";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import formatRupiah from "../utils/priceFormat";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Honeycomb", 408, 3.2, 87, 6.5),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Jelly Bean", 375, 0.0, 94, 0.0),
  createData("KitKat", 518, 26.0, 65, 7.0),
  createData("Lollipop", 392, 0.2, 98, 0.0),
  createData("Marshmallow", 318, 0, 81, 2.0),
  createData("Nougat", 360, 19.0, 9, 37.0),
  createData("Oreo", 437, 18.0, 63, 4.0),
];

const headCells = [
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Title",
  },
  { id: "category", numeric: false, disablePadding: false, label: "Category" },
  { id: "price", numeric: false, disablePadding: false, label: "Price" },
  { id: "rating", numeric: false, disablePadding: false, label: "Rating(Avg)" },
  { id: "duration", numeric: false, disablePadding: false, label: "Duration" },
  { id: "level", numeric: false, disablePadding: false, label: "Level" },
  { id: "draft", numeric: false, disablePadding: false, label: "Draft" },
  { id: "created_at", numeric: false, disablePadding: false, label: "Created" },
  { id: "updated_at", numeric: false, disablePadding: false, label: "Updated" },
  { id: "position", numeric: false, disablePadding: false, label: "Position" },
];

function EnhancedTableHead(props) {
  const {
    // classes,
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    // onRequestSort,
  } = props;
  // const createSortHandler = (property) => (event) => {
  //   onRequestSort(event, property);
  // };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            > */}
            {headCell.label}
            {/* {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    margin: "20px 0px",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("title");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  // eslint-disable-next-line no-unused-vars
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setloading] = React.useState(true);
  const [datafetch, setdata] = React.useState({ data: [] });
  const [open, setDialog] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [indexTarget, setIndexTarget] = React.useState({});

  React.useEffect(() => {
    console.log("awal");
    // setCourse(window.location.pathname.split("/")[2]);
    fetchData();
  }, []);

  React.useEffect(() => {
    console.log("change");
    fetchData();
  }, [rowsPerPage, page]);

  const fetchData = async () => {
    const user_id = window.location.pathname.split("/")[2];
    setloading(true);
    try {
      let query;
      query = {
        query: allCoursesList,
        variables: {
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          sort: "title",
          order: "ASC",
          where: {
            _or: [
              { tutor_assistant_ids: { _has_key: user_id } },
              { tutor_id: { _eq: user_id } },
            ],
          },
        },
      };
      const gql = await client.gql.query(query);
      console.log(gql);
      setdata(gql.allCoursesList);
      setloading(false);
    } catch (err) {
      console.log(err);
      setloading(false);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const countRating = (review) => {
    let avg = 0;
    if (review.count === 0) {
      return 0;
    } else {
      for (var i = 0; i < review.count; ++i) {
        avg = review.data[i].rating + avg;
      }
      return (avg / review.count).toFixed(2) + " ⭐";
    }
  };

  const checkPosition = (data) => {
    const user_id = window.location.pathname.split("/")[2];

    return user_id === data ? "Leader" : "Assistant";
  };

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      {loading ? (
        <div
          style={{ display: "flex", justifyContent: "center", margin: "30px" }}
        >
          <CircularProgress style={{ width: "75px", height: "75px" }} />
        </div>
      ) : (
        <Paper className={classes.paper}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                {datafetch.data.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell id={labelId}>{row.title}</TableCell>
                      <TableCell id={labelId}>
                        {row.category_id_data.name}
                      </TableCell>
                      <TableCell id={labelId}>
                        {formatRupiah(row.price)}
                      </TableCell>
                      <TableCell id={labelId}>
                        {countRating(row.reviews)}
                      </TableCell>
                      <TableCell id={labelId}>{row.title}</TableCell>
                      <TableCell id={labelId}>
                        {row.level_id_data.name}
                      </TableCell>
                      <TableCell id={labelId}>
                        {row.draft ? <CheckIcon /> : <ClearIcon />}
                      </TableCell>
                      <TableCell id={labelId}>
                        {moment(row.created_at).format("DD/MM/YYYY, h:mm:ss a")}
                      </TableCell>
                      <TableCell id={labelId}>
                        {moment(row.updated_at).format("DD/MM/YYYY, h:mm:ss a")}
                      </TableCell>
                      <TableCell id={labelId}>
                        {checkPosition(row.tutor_id)}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 30]}
            component="div"
            count={datafetch.count || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        scroll="paper"
        onClose={() => setDialog(false)}
        // fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          Progress Course from{" "}
          {indexTarget.student_id_data && indexTarget.student_id_data.name}
        </DialogTitle>
        <DialogContent>
          <h3>Content item</h3>
          <Table
            className={classes.table2}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Start at</TableCell>
                <TableCell>Finish at</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {indexTarget.enrollment_progress &&
                indexTarget.enrollment_progress.data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.content_item_id_data.title}
                    </TableCell>
                    <TableCell>
                      {row.start_at !== null
                        ? moment(row.start_at).format("DD-MM-YYYY, h:mm:ss a")
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {row.finish_at !== null
                        ? moment(row.finish_at).format("DD-MM-YYYY, h:mm:ss a")
                        : "-"}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>

          <h3>Quiz</h3>

          <Table
            className={classes.table2}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Point</TableCell>
                <TableCell>Start at</TableCell>
                <TableCell>Finish at</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {indexTarget.quiz_result_list &&
                indexTarget.quiz_result_list.data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.quiz_id_data.title}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.point}
                    </TableCell>
                    <TableCell>
                      {row.start_at !== null
                        ? moment(row.start_at).format("DD-MM-YYYY, h:mm:ss a")
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {row.finish_at !== null
                        ? moment(row.finish_at).format("DD-MM-YYYY, h:mm:ss a")
                        : "-"}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
