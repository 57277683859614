import React from "react";
import { List, Datagrid, TextField } from "react-admin";
import { ExportButton } from "react-admin";
import Toolbar from "@material-ui/core/Toolbar";
import {
  Create,
  Edit,
  SimpleForm,
  DateField,
  CreateButton,
  EditButton,
  required,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  ShowController,
  Show,
  SimpleShowLayout,
} from "react-admin";

export const CategoryPromotedList = (props) => {
  return (
    <React.Fragment>
      <List
        {...props}
        actions={<PostActions />}
        bulkActionButtons={false}
        title="Promoted Courses"
        sort={{ field: "priority", order: "ASC" }}
        //   filters={<PostFilter />}
      >
        <Datagrid rowClick="show">
          <TextField label="Category" source="category.name" />
          <DateField
            locales="en-TT"
            label="Created"
            source="created_at"
            showTime
          />
          <DateField
            locales="en-TT"
            label="Updated"
            source="updated_at"
            showTime
          />
          <EditButton />
        </Datagrid>
      </List>
    </React.Fragment>
  );
};

const PostActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <Toolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

export const CategoryPromotedCreate = (props) => (
  <Create {...props} title="Add Promoted Course">
    <SimpleForm redirect="list">
      <ReferenceInput
        label="Select Category"
        source="category_id"
        perPage={100000}
        reference="categoryNoLimit"
        filter={{
          activity: "true",
        }}
        validate={[required()]}
      >
        <AutocompleteInput optionText="name" style={{ background: "white" }} />
      </ReferenceInput>
      <SelectInput
        label="Priority Position"
        source="position"
        choices={[
          { id: "before", name: "Before" },
          { id: "after", name: "After" },
        ]}
      />

      <ReferenceInput
        label="Priority Anchor"
        source="priorityValue"
        perPage={100000}
        reference="category_front_pages"
        sort={{ field: "priority", order: "ASC" }}
      >
        <SelectInput optionText="category.name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const CategoryPromotedEdit = (props) => {
  // console.log(props);
  return (
    <ShowController {...props}>
      {(controllerProps) => (
        <Edit {...props} {...controllerProps} undoable={false}>
          <SimpleForm>
            <ReferenceInput
              label="Select Category"
              source="category_id"
              perPage={100000}
              reference="categoryNoLimit"
              disabled
              validate={[required()]}
            >
              <AutocompleteInput
                optionText="name"
                style={{ background: "white" }}
              />
            </ReferenceInput>
            <SelectInput
              label="Priority Position"
              source="position"
              choices={[
                { id: "before", name: "Before" },
                { id: "after", name: "After" },
              ]}
            />

            <ReferenceInput
              label="Priority Anchor"
              source="priorityValue"
              perPage={100000}
              reference="category_front_pages"
              sort={{ field: "priority", order: "ASC" }}
            >
              <SelectInput optionText="category.name" />
            </ReferenceInput>
          </SimpleForm>
        </Edit>
      )}
    </ShowController>
  );
};

const UserField = ({ record = {} }) => (
  <span>{record.user_id === null ? "Anonymous" : record.users.name}</span>
);
UserField.defaultProps = { label: "User", addLabel: true };

export const PromotedShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <Show {...props} {...controllerProps} title="Detail Promoted Courses">
        <SimpleShowLayout>
          <TextField label="Title" source="courses.title" />
          <TextField label="Description" source="courses.description" />
        </SimpleShowLayout>
      </Show>
    )}
  </ShowController>
);
