import React from "react";
import { List, Datagrid, TextField } from "react-admin";
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  Show,
  SimpleShowLayout,
  DateField,
  BooleanField,
  useRefresh,
} from "react-admin";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import TextFieldMui from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import client from "../utils/mococlient";
import Alerta from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const CertificateList = (props) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectData, setSelectData] = React.useState({});
  const [otp, setOtp] = React.useState("");
  const [wrongOtp, setWrongOtp] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const refresh = useRefresh();

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setWrongOtp(false);
    setOtp("");
  };

  const ChangeStatus = ({ record }) => {
    return (
      <Button
        variant="contained"
        size="small"
        color="primary"
        startIcon={<EditIcon />}
        onClick={() => {
          console.log(record);
          setSelectData(record);
          handleClickOpenDialog();
        }}
      >
        Status
      </Button>
    );
  };

  const revokedProcess = async () => {
    console.log(selectData);
    setWrongOtp(false);
    const payload = {
      token: otp,
      revoked: selectData.revoked,
      enrollment_id: selectData.enrollment_id,
    };
    const overwrite = await client.invokeApi("revoke-cert", payload);
    console.log(overwrite);

    if (overwrite.error && overwrite.error.message === "Token not valid!") {
      console.log("error");
      setOtp("");
      setWrongOtp(true);
    } else {
      setOpenDialog(false);
      setOtp("");
      refresh();
      setSuccess(true);
    }
  };

  return (
    <React.Fragment>
      <List
        {...props}
        // actions={<PostActions />}
        bulkActionButtons={false}
        title="Certificate"
        // pagination={false}
        filters={<PostFilter />}
        empty={false}
      >
        <Datagrid expand={<ExpandShow />}>
          <TextField label="Enrollments ID" source="enrollment_id" />
          <TextField label="Course Title" source="course_title" />
          <TextField label="Course Description" source="course_description" />
          <BooleanField source="revoked" />
          <ChangeStatus />
        </Datagrid>
      </List>
      <Snackbar
        open={success}
        autoHideDuration={3000}
        onClose={() => setSuccess(false)}
      >
        <Alert onClose={() => setSuccess(false)} severity="success">
          Success update certificate
        </Alert>
      </Snackbar>
      <Dialog
        open={openDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          Change Revoke Certificate
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          {selectData.id && (
            <div>
              <TextFieldMui
                label="Course Title"
                value={selectData.course_title || ""}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                style={{ marginBottom: "10px" }}
              />
              <TextFieldMui
                label="Course Description"
                value={selectData.course_description || ""}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                style={{ marginBottom: "10px" }}
              />

              {wrongOtp && <Alerta severity="error">Token Invalid!</Alerta>}
              <TextFieldMui
                label="OTP"
                value={otp}
                fullWidth
                onChange={(e) => setOtp(e.target.value)}
                style={{ marginBottom: "20px" }}
              />
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={selectData.revoked}
                    onChange={(e) =>
                      setSelectData({
                        ...selectData,
                        revoked: !selectData.revoked,
                      })
                    }
                  />
                }
                label="Revoked"
                labelPlacement="start"
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={revokedProcess}
            color="primary"
            variant="contained"
            disabled={otp === ""}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export const PostCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="nama" />
      <TextInput source="pengarang" />
    </SimpleForm>
  </Create>
);

export const PostEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <TextInput source="nama" />
      <TextInput source="pengarang" />
    </SimpleForm>
  </Edit>
);

const PostFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Select Student"
      source="student_id"
      reference="users"
      perPage={100000}
      sort={{ field: "name", order: "ASC" }}
      filter={{
        role: "Student",
      }}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const ExpandShow = (props) => {
  console.log(props);
  return (
    <Show {...props} title=" ">
      <SimpleShowLayout>
        <TextField label="Certificate ID" source="cert_id" />
        <TextField label="Tutor Name" source="tutor_name" />
        <TextField label="Course Title" source="course_title" />
        <TextField label="Student Name" source="student_name" />
        <TextField label="Tutor Name" source="tutor_name" />
        <TextField label="Tutor Position" source="tutor_position" />
        <DateField
          label="Certificate Date"
          source="certificate_date"
          showTime
        />
      </SimpleShowLayout>
    </Show>
  );
};
