import React from "react";
import { List, Datagrid, TextField } from "react-admin";
import { ExportButton } from "react-admin";
import Toolbar from "@material-ui/core/Toolbar";
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateField,
  FileInput,
  FileField,
  Filter,
  AutocompleteInput,
  ReferenceInput,
} from "react-admin";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import ReactJson from "react-json-view";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";

export const LogsList = (props) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectData, setSelectData] = React.useState({});

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const ChangeStatus = ({ record }) => {
    return (
      <IconButton
        variant="contained"
        size="small"
        color="primary"
        onClick={() => {
          setSelectData(record);
          handleClickOpenDialog();
        }}
      >
        <InsertDriveFileIcon />
      </IconButton>
    );
  };
  ChangeStatus.defaultProps = { label: "Data", addLabel: true };

  return (
    <React.Fragment>
      <List
        {...props}
        actions={<PostActions />}
        bulkActionButtons={false}
        title="Activity Logs"
        sort={{ field: "created_at", order: "DESC" }}
        filters={<PostFilter />}
      >
        <Datagrid>
          <UserField />
          <TextField label="Action" source="action" />
          <TextField label="Type" source="type" />
          <DateField
            locales="en-TT"
            label="Created"
            source="created_at"
            showTime
          />
          <DateField
            locales="en-TT"
            label="Updated"
            source="updated_at"
            showTime
          />
          <ChangeStatus />
        </Datagrid>
      </List>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">Data Activity Log</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>Edit status payment + otp</DialogContentText> */}
          <ReactJson
            src={selectData.data}
            collapsed={1}
            enableClipboard={false}
            displayDataTypes={false}
            displayObjectSize={false}
            collapseStringsAfterLength={50}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const PostFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Select user"
      source="user_id"
      reference="users"
      perPage={100000}
      sort={{ field: "name", order: "ASC" }}
      filter={{
        role: "ALL",
      }}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput
      label="Select activity"
      source="activity"
      perPage={100000}
      reference="activity_logs"
      filter={{
        activityFilter: true,
      }}
      alwaysOn
    >
      <AutocompleteInput optionText="action" />
    </ReferenceInput>
  </Filter>
);

const PostActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <Toolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    {/* <CreateButton basePath={basePath} /> */}
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

export const VideoCreate = (props) => (
  <Create {...props} title="Add Video">
    <SimpleForm redirect="list">
      <FileInput source="files" label="Video" multiple={false} accept="video/*">
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);

export const PostEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <TextInput source="nama" />
      <TextInput source="pengarang" />
    </SimpleForm>
  </Edit>
);

const UserField = ({ record = {} }) => (
  <span>{record.user_id === null ? "Anonymous" : record.users.name}</span>
);
UserField.defaultProps = { label: "User", addLabel: true };
