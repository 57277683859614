import React from "react";
import { List, Datagrid, TextField } from "react-admin";

// import Button from '@material-ui/core/Button';
import { CreateButton, ExportButton } from "react-admin";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateField,
  ArrayField,
  Show,
  SimpleShowLayout,
  Filter,
  AutocompleteInput,
  ReferenceInput,
  required,
  SelectInput,
  ShowController,
  NumberInput,
} from "react-admin";

export const ContentList = (props) => {
  const PostFilter = (props) => {
    // console.log(props);
    return (
      <Filter {...props}>
        <ReferenceInput
          label="Select Category"
          source="category_id"
          alwaysOn
          reference="category"
          perPage={100000}
          sort={{ field: "name", order: "ASC" }}
          filter={{
            subcategory: true,
          }}
          onChange={(e) => {
            if (e === null) {
              // localStorage.setItem("selectCourse", "");
              props.setFilters({
                id: "",
              });
            } else {
              props.setFilters({
                id: "",
                category_id: e,
              });
            }
          }}
        >
          <AutocompleteInput
            optionText="name"
            style={{ background: "white" }}
          />
        </ReferenceInput>
        {/* <div>{props.filterValues.id ? "ada" : "tidak"}</div> */}
        {props.filterValues.category_id && (
          <ReferenceInput
            label="Select Course Title"
            source="id"
            reference="contents"
            perPage={1000000}
            sort={{ field: "title", order: "ASC" }}
            filter={{ category_id: props.filterValues.category_id }}
            alwaysOn
            onChange={(e) => localStorage.setItem("selectCourse", e)}
          >
            <AutocompleteInput
              optionText="title"
              style={{ background: "white" }}
            />
          </ReferenceInput>
        )}
      </Filter>
    );
  };
  return (
    <List
      {...props}
      actions={<PostActions />}
      bulkActionButtons={false}
      title="Contents"
      filters={<PostFilter />}
      sort={{ field: "priority", order: "ASC" }}
      empty={false}
    >
      <Datagrid expand={<ExpandShow />}>
        <TextField label="Title" source="title" />
        <ContentField />
        {/* <TextField label="Priority" source="priority" /> */}
        <DateField
          locales="en-TT"
          label="Created"
          source="created_at"
          showTime
        />
        <DateField
          locales="en-TT"
          label="Updated"
          source="updated_at"
          showTime
        />
        <EditContentButton />
      </Datagrid>
    </List>
  );
};

const PostActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => {
  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {filterValues.id && <CreateButton basePath={basePath} />}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
    </Toolbar>
  );
};

export const ContentCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="title" validate={[required()]} />
      <TextInput
        source="description"
        multiline
        fullWidth
        validate={[required()]}
      />
      <NumberInput
        label="Duration in day"
        source="duration"
        step={1}
        min={1}
        validate={[required()]}
      />
      <SelectInput
        label="Priority Position"
        source="position"
        choices={[
          { id: "before", name: "Before" },
          { id: "after", name: "After" },
        ]}
      />
      <ReferenceInput
        label="Priority Anchor"
        source="priorityValue"
        reference="contents"
        perPage={100000}
        sort={{ field: "priority", order: "ASC" }}
        // filter={{ id: props.history.location.state.record.course }}
        filter={{ id: localStorage.getItem("selectCourse") }}
      >
        <SelectInput optionText="title" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const ContentEdit = (props) => {
  // console.log(props);
  return (
    <ShowController {...props}>
      {(controllerProps) => (
        <Edit {...props} {...controllerProps} undoable={false}>
          <SimpleForm>
            <TextInput label="Title" source="title" validate={[required()]} />
            <TextInput
              label="Description"
              source="description"
              multiline
              fullWidth
              validate={[required()]}
            />
            <NumberInput
              label="Duration in day"
              source="duration"
              step={1}
              min={1}
              validate={[required()]}
            />
            {/* <TextInput label="Priority" source="priority" type="number" /> */}
            <SelectInput
              label="Priority Position"
              source="position"
              choices={[
                { id: "before", name: "Before" },
                { id: "after", name: "After" },
              ]}
            />
            <ReferenceInput
              label="Priority Anchor"
              source="priorityValue"
              reference="contents"
              perPage={100000}
              sort={{ field: "priority", order: "ASC" }}
              filter={{ id: localStorage.getItem("selectCourse") }}
            >
              <SelectInput optionText="title" />
            </ReferenceInput>
          </SimpleForm>
        </Edit>
      )}
    </ShowController>
  );
};

export const ExpandShow = (props) => (
  <Show {...props} title=" ">
    <SimpleShowLayout>
      <TextField label="Title" source="title" />
      <TextField label="Description" source="description" />
      <CreateContentItemsButton />
      <ArrayField source="content_items_list.data" label=" ">
        <Datagrid>
          <TextField source="title" label="Title" />
          {/* <TextField source="priority" label="Priority" /> */}
          <SingleField />
          <EditItemButton />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

const SingleField = ({ record = {} }) => {
  return (
    <span>
      {record.video.count === 0
        ? "-"
        : record.video.data[0].raw_file === null
        ? record.video.data[0].item_id
        : record.video.data[0].raw_file}
    </span>
  );
};
SingleField.defaultProps = { label: "Video", addLabel: true };

const EditItemButton = ({ record }) => (
  <Button
    variant="text"
    color="primary"
    component={Link}
    startIcon={<EditIcon />}
    to={{
      pathname: `/content_items/${record.id}`,
      state: { record: { content_id: record.content_id } },
    }}
  >
    Edit
  </Button>
);

const EditContentButton = ({ record }) => (
  <Button
    variant="text"
    color="primary"
    component={Link}
    startIcon={<EditIcon />}
    to={{
      pathname: `/contents/${record.id}`,
      state: { record: { course: record.course_id } },
    }}
  >
    Edit
  </Button>
);

// const CreateContentButton = ({ record }) => (
//   <Button
//     variant="text"
//     color="primary"
//     component={Link}
//     startIcon={<EditIcon />}
//     to={{
//       pathname: `/contents/create`,
//       state: { record: { course: record.course_id } },
//     }}
//   >
//     Create
//   </Button>
// );

const CreateContentItemsButton = ({ record }) => (
  <Button
    variant="contained"
    color="primary"
    component={Link}
    style={{ marginTop: "20px" }}
    startIcon={<AddIcon />}
    to={{
      pathname: `/content_items/create`,
      state: { record: { content_id: record.id } },
    }}
  >
    Create Content Item
  </Button>
);

const ContentField = ({ record = {} }) => (
  <span>{record.duration === null ? "-" : record.duration}</span>
);
ContentField.defaultProps = { label: "Duration", addLabel: true };
