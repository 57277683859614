import React from "react";
import { List, Datagrid, TextField } from "react-admin";

// import Button from '@material-ui/core/Button';
import { CreateButton, ExportButton } from "react-admin";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import CourseDetailTable from "../component/CourseDetails";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateField,
  EditButton,
  ReferenceInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  ImageField,
  ImageInput,
  required,
  FormDataConsumer,
  Labeled,
  BooleanField,
  BooleanInput,
  FileField,
  FileInput,
  FunctionField,
  SaveButton,
  useRedirect,
  NumberInput,
  useNotify,
  AutocompleteInput,
  DeleteButton,
  ShowController,
  Show,
  SimpleShowLayout,
  TabbedShowLayout,
  Tab,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import forceLogout from "../utils/forceLogout";
import DialogTitle from "@material-ui/core/DialogTitle";
import ReactPlayer from "react-player";
import LinearProgress from "@material-ui/core/LinearProgress";
import client from "../utils/mococlient";
import isProd from "../utils/variabels";
import formatRupiah from "../utils/priceFormat";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  submitStatusJobCheck,
  uploadIntroWithProgress,
} from "../Provider/VideoProvider";
const redirect = (basePath, id, data) => `/courses`;

export const CoursesList = (props) => (
  <List
    {...props}
    actions={<PostActions />}
    bulkActionButtons={false}
    title="Courses"
    sort={{ field: "created_at", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <TextField label="Title" source="title" />
      <FunctionField
        label="Description"
        render={(record) =>
          record.description.length > 25
            ? `${record.description.substring(0, 45)}...`
            : record.description
        }
      />
      <ContentField />
      <TextField label="Price" source="price" />
      <TextField label="Sharing" source="sharing_profit_percent" />
      <TextField label="Category" source="category.name" />
      <BooleanField label="Draft" source="draft" />
      <BooleanField label="Schedules" source="use_master_schedules" />
      <DateField locales="en-TT" label="Created" source="created_at" showTime />
      <DateField locales="en-TT" label="Updated" source="updated_at" showTime />
      <EditButton />
    </Datagrid>
  </List>
);

const PostActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <Toolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

const SaveWithNoteButton = (props) => {
  // console.log(props);
  const redirectTo = useRedirect();
  const notify = useNotify();

  const handleSave = async (values, redirect) => {
    try {
      // console.log(values);
      props.setProgress(true);
      const params = { data: values };
      let data = params.data;
      if (params.data.pictures) {
        const upload = await client.storage.upload({
          params: {
            filename: `cover_img/${params.data.pictures.title}`,
            bucket: isProd.publicBucket,
            expire: "600",
          },
          file: params.data.pictures.rawFile,
        });
        console.log(upload);

        const download = await client.storage.downloadUrl({
          params: {
            bucket: isProd.publicBucket,
          },
          fileId: upload.data.id,
        });

        console.log(download);
        const urlImg = download.data.url.split("?");
        data = {
          ...data,
          cover_img: urlImg[0],
        };
      }
      const table = client.getTable("courses");
      const add = await table.insert(data);

      if (params.data.intro) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        // console.log(params.data);
        const { task: uploadTask, uid } = await uploadIntroWithProgress({
          file: params.data.intro.rawFile,
        });
        // console.log(uploadTask);
        // console.log(uid);
        const proses = await new Promise((resolve, reject) => {
          uploadTask.on("done", (val) => {
            // console.log("done");
            // console.log(val);
            resolve(val);
            submitStatusJobCheck(uid, add.data.id);
            // setIsUploading(false);
            notify(
              "Element Created, video has been uploaded. It may take 5 minutes to process.",
              "info",
              {
                smart_count: 1,
              }
            );
            console.log(
              "Video has been uploaded. It may take 5 minutes to process."
            );
            // closeAndResetAll();
          });
          uploadTask.on("progress", (val) => {
            // console.log("listener progress: ", val);
            // setUploadProgress(val);
            // console.log(val);
            props.setLoading(val);
          });

          uploadTask.on("error", (val) => {
            // console.log("error");
            // console.log(val);
            reject(val);
            // setIsUploading(false);
            console.log("Upload video failed");
          });
        });
        console.log(proses);
      }
      // return update;
      props.setProgress(false);

      redirectTo(redirect);
    } catch (e) {
      props.setProgress(false);
      console.log(e);
      await forceLogout(e);
    }
  };
  return <SaveButton {...props} onSave={handleSave} />;
};

export const CourseCreate = (props) => {
  const [loading, setLoading] = React.useState(0);
  const [progress, setProgress] = React.useState(false);
  const [change, setChange] = React.useState(false);

  const CustomToolbar = (props) => (
    <Toolbar
      {...props}
      style={{
        display: "flex",
        justifyContent: "space-between",
        background: "#F5F5F5",
      }}
    >
      {progress ? (
        <Button
          color="default"
          disabled
          style={{ bakcgroundColor: "#D7D7D7" }}
          variant="contained"
        >
          <CircularProgress style={{ width: "25px", height: "25px" }} />
        </Button>
      ) : (
        <SaveWithNoteButton
          setLoading={setLoading}
          setProgress={setProgress}
          {...props}
        />
      )}
      <DeleteButton undoable={false} redirect={redirect} />
    </Toolbar>
  );

  return (
    <Create {...props}>
      <SimpleForm redirect={redirect} toolbar={<CustomToolbar />}>
        <TextInput label="Title" source="title" validate={[required()]} />
        <BooleanInput
          label="Use Master Schedules"
          source="use_master_schedules"
        />
        <TextInput
          label="Description"
          source="description"
          validate={[required()]}
          multiline
          fullWidth
        />
        <NumberInput
          label="Price"
          source="price"
          step={1}
          min={0}
          validate={[required()]}
        />
        <NumberInput
          label="Sharing profit percent"
          source="sharing_profit_percent"
          step={1}
          min={0}
          max={100}
          validate={[required()]}
        />
        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => (
            <ReferenceInput
              label="Tutor Leader"
              source="tutor_id"
              reference="users"
              sort={{ field: "name", order: "ASC" }}
              filter={{
                role: "Tutor",
              }}
              perPage={100000}
              validate={[required()]}
              onChange={(e) => {
                setChange(true);
              }}
              style={{ width: "255px" }}
            >
              <SelectInput optionText="email" />
            </ReferenceInput>
          )}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) =>
            formData.tutor_id !== undefined && (
              <ReferenceArrayInput
                label="Tutor Assistant"
                source="tutor_assistant_ids"
                sort={{ field: "name", order: "ASC" }}
                reference="users"
                filter={{
                  role: "Tutor",
                  except: formData.tutor_id,
                }}
                perPage={100000}
                fullWidth
              >
                <SelectArrayInput optionText="email" />
              </ReferenceArrayInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => {
            if (change === true) {
              console.log("CHANGE");
              formData.tutor_assistant_ids = undefined;
              setChange(false);
              console.log(formData);
            }
          }}
        </FormDataConsumer>
        <ReferenceInput
          label="Category"
          source="category_id"
          reference="category"
          filter={{
            subcategory: true,
          }}
          perPage={100000}
          validate={[required()]}
        >
          <AutocompleteInput
            optionText="name"
            style={{ background: "white" }}
          />
        </ReferenceInput>
        <ReferenceInput
          label="Level"
          source="level_id"
          reference="levels"
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ImageInput
          source="pictures"
          label="Cover Image"
          accept="image/*"
          validate={[required()]}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <FileInput
          source="intro"
          label="Video intro"
          multiple={false}
          accept="video/*"
          validate={[required()]}
        >
          <FileField source="src" title="title" />
        </FileInput>
        {loading !== 0 && (
          <div style={{ width: "100%", marginBottom: "10px" }}>
            <LinearProgress variant="determinate" value={loading} fullWidth />
            <div>{`Upload process : ${loading}%`}</div>
          </div>
        )}
      </SimpleForm>
    </Create>
  );
};

const EditWithNoteButton = (props) => {
  // console.log(props);
  const redirectTo = useRedirect();
  const notify = useNotify();

  const handleSave = async (values, redirect) => {
    try {
      // console.log(values);
      props.setProgress(true);
      const params = { data: values };
      let dataEdit = params.data;
      if (params.data.pictures) {
        console.log("new pic");
        const upload = await client.storage.upload({
          params: {
            filename: `cover_img/${params.data.pictures.title}`,
            bucket: isProd.publicBucket,
            expire: "600",
          },
          file: params.data.pictures.rawFile,
        });
        console.log(upload);

        const download = await client.storage.downloadUrl({
          params: {
            bucket: isProd.publicBucket,
          },
          fileId: upload.data.id,
        });

        console.log(download);
        const urlImg = download.data.url.split("?");
        dataEdit = {
          ...dataEdit,
          cover_img: urlImg[0],
        };
      }

      if (params.data.intro) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        // console.log(params.data);
        const { task: uploadTask, uid } = await uploadIntroWithProgress({
          file: params.data.intro.rawFile,
        });
        // console.log(uploadTask);
        // console.log(uid);
        const proses = await new Promise((resolve, reject) => {
          uploadTask.on("done", (val) => {
            // console.log("done");
            // console.log(val);
            resolve(val);
            submitStatusJobCheck(uid, params.data.id);
            // setIsUploading(false);
            notify(
              "Video has been uploaded. It may take 5 minutes to process.",
              "info",
              {
                smart_count: 1,
              }
            );
            console.log(
              "Video has been uploaded. It may take 5 minutes to process."
            );
            // closeAndResetAll();
          });
          uploadTask.on("progress", (val) => {
            // console.log("listener progress: ", val);
            // setUploadProgress(val);
            // console.log(val);
            props.setLoading(val);
          });

          uploadTask.on("error", (val) => {
            // console.log("error");
            // console.log(val);
            reject(val);
            // setIsUploading(false);
            console.log("Upload video failed");
          });
        });
        console.log(proses);
      }

      const table = client.getTable("courses");
      const dataUpdate = {
        id: params.data.id,
        data: dataEdit,
      };
      const update = await table.update(dataUpdate);
      console.log(update);
      notify("ra.notification.updated", "info", {
        smart_count: 1,
      });
      props.setProgress(false);
      redirectTo(redirect);
    } catch (e) {
      props.setProgress(false);
      console.log(e);
      await forceLogout(e);
    }
  };
  return (
    <SaveButton {...props} onSave={handleSave} disabled={props.disabled} />
  );
};

export const CourseEdit = (props) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [data, setData] = React.useState({});
  const [progress, setProgress] = React.useState(false);
  const [change, setChange] = React.useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [loading, setLoading] = React.useState(0);

  const CustomEditToolbar = (props) => (
    <Toolbar
      {...props}
      style={{
        display: "flex",
        justifyContent: "space-between",
        background: "#F5F5F5",
      }}
    >
      {progress ? (
        <Button
          color="default"
          disabled
          style={{ bakcgroundColor: "#D7D7D7" }}
          variant="contained"
        >
          <CircularProgress style={{ width: "25px", height: "25px" }} />
        </Button>
      ) : (
        <EditWithNoteButton
          setLoading={setLoading}
          setProgress={setProgress}
          disabled={props.pristine}
          {...props}
        />
      )}
      <DeleteButton undoable={false} redirect={redirect} {...props} />
    </Toolbar>
  );

  return (
    <React.Fragment>
      <Edit {...props} undoable={false}>
        <SimpleForm redirect={redirect} toolbar={<CustomEditToolbar />}>
          <TextInput label="Title" source="title" validate={[required()]} />
          <BooleanInput label="Draft" source="draft" />
          <BooleanInput
            label="Use Master Schedules"
            source="use_master_schedules"
          />

          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => (
              <ReferenceInput
                label="Tutor Leader"
                source="tutor_id"
                reference="users"
                sort={{ field: "name", order: "ASC" }}
                filter={{
                  role: "Tutor",
                }}
                perPage={100000}
                validate={[required()]}
                onChange={(e) => {
                  setChange(true);
                }}
                style={{ width: "255px" }}
              >
                <SelectInput optionText="email" />
              </ReferenceInput>
            )}
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) =>
              formData.tutor_id !== undefined && (
                <ReferenceArrayInput
                  label="Tutor Assistant"
                  source="tutor_assistant_ids"
                  sort={{ field: "name", order: "ASC" }}
                  reference="users"
                  perPage={100000}
                  filter={{
                    role: "Tutor",
                    except: formData.tutor_id,
                  }}
                  fullWidth
                >
                  <SelectArrayInput optionText="email" />
                </ReferenceArrayInput>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => {
              if (change === true) {
                console.log("CHANGE");
                formData.tutor_assistant_ids = undefined;
                setChange(false);
                console.log(formData);
              }
            }}
          </FormDataConsumer>
          <TextInput
            label="Description"
            source="description"
            validate={[required()]}
            multiline
            fullWidth
          />
          <NumberInput
            label="Price"
            source="price"
            step={1}
            min={0}
            validate={[required()]}
          />
          <NumberInput
            label="Sharing profit percent"
            source="sharing_profit_percent"
            step={1}
            min={0}
            max={100}
            validate={[required()]}
          />
          <ReferenceInput
            label="Category"
            source="category_id"
            reference="category"
            filter={{
              subcategory: true,
            }}
            perPage={100000}
            validate={[required()]}
          >
            <AutocompleteInput
              optionText="name"
              style={{ background: "white" }}
            />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData, record, dispatch, ...rest }) => {
              if (!formData.pictures) {
                return (
                  // <Labeled label="Current Cover Image">
                  //   <ImageField source="cover_img" {...rest} />
                  // </Labeled>
                  <ImagePreview record={record} />
                );
              }
            }}
          </FormDataConsumer>
          <ImageInput
            source="pictures"
            label="Change image"
            accept="image/*"
            mulitple={false}
          >
            <ImageField source="cover_img" title="title" />
          </ImageInput>
          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => {
              if (!formData.intro) {
                return (
                  <Labeled label="Current Video Intro">
                    <ButtonVideo
                      {...rest}
                      OpenDialog={handleClickOpenDialog}
                      setData={setData}
                    />
                  </Labeled>
                );
              }
            }}
          </FormDataConsumer>
          <FileInput
            source="intro"
            label="Video intro"
            multiple={false}
            accept="video/*"
          >
            <FileField source="src" title="title" />
          </FileInput>
          {loading !== 0 && (
            <div style={{ width: "100%", marginBottom: "10px" }}>
              <LinearProgress variant="determinate" value={loading} fullWidth />
              <div>{`Upload process : ${loading}%`}</div>
            </div>
          )}
        </SimpleForm>
      </Edit>
      <Dialog
        open={openDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          Video intro {data.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {Object.keys(data).length !== 0
              ? Object.keys(data.video_intro_metadata).length !== 0
                ? data.video_intro_metadata.meta.name
                : ""
              : ""}
          </DialogContentText>

          {data.video_intro_metadata && data.video_intro !== null ? (
            <div style={{ marginTop: "15px" }}>
              <ReactPlayer
                url={data.video_intro_metadata.preview}
                width="550px"
                controls={true}
              />
            </div>
          ) : (
            <div
              style={{
                background: "#dbdbdb",
                height: "60px",
                display: "flex",
                alignItems: "center",
                padding: "5px",
              }}
            >
              No video intro for this course
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const CoverImg = ({ record = {} }) => {
  return (
    <span>
      <img src={record.cover_img} alt="cover_img" style={{ width: "250px" }} />
    </span>
  );
};
CoverImg.defaultProps = { label: "Cover Img", addLabel: true };

const ButtonVideo = ({ record = {}, OpenDialog, setData }) => {
  return (
    <span>
      {/* <a
        href={record.video_intro_metadata.preview}
        target="_blank"
        rel="noopener noreferrer"
      > */}
      <Button
        color="primary"
        variant="contained"
        startIcon={<PlayCircleFilledIcon />}
        onClick={() => {
          setData(record);
          OpenDialog();
        }}
      >
        VIDEO
      </Button>
      {/* </a> */}
    </span>
  );
};
ButtonVideo.defaultProps = {
  label: "Open Current Video Intro",
  addLabel: true,
};

const ContentField = ({ record = {} }) => (
  <span>{record.duration === null ? "-" : record.duration}</span>
);
ContentField.defaultProps = { label: "Duration (day)", addLabel: true };

const ImagePreview = ({ record = {} }) => {
  return (
    <div>
      {record.cover_img ? (
        <Labeled label="Current Cover Image">
          <img
            src={record.cover_img}
            alt="imgpreview"
            style={{ width: "300px" }}
          />
        </Labeled>
      ) : (
        "No Image"
      )}
    </div>
  );
};

export const CourseShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <Show {...props} {...controllerProps} title="Detail Course">
        <SimpleShowLayout>
          <TabbedShowLayout>
            <Tab label="info">
              <TextField source="title" label="Title" />
              <TextField source="description" label="Description" />
              <FunctionField
                label="Price"
                render={(record) => formatRupiah(record.price)}
              />
              <TextField source="duration" label="Duration" />
              <BooleanField label="Draft" source="draft" />
              <BooleanField
                label="Use Master scheduler"
                source="use_master_schedules"
              />
              <TextField source="category_id_data.name" label="Category" />
              <TextField source="tutor_id_data.name" label="Tutor name" />
              <TextField source="tutor_id_data.email" label="Tutor email" />

              {controllerProps.record &&
                controllerProps.record.tutor_assistant_ids &&
                controllerProps.record.tutor_assistant_ids.length !== 0 && (
                  <ReferenceArrayField
                    label="Assistan list"
                    source="tutor_assistant_ids"
                    reference="users"
                  >
                    <SingleFieldList>
                      <ChipField source="email" />
                    </SingleFieldList>
                  </ReferenceArrayField>
                )}
            </Tab>
            <Tab label="user list">
              <CourseDetailTable />
            </Tab>
          </TabbedShowLayout>
        </SimpleShowLayout>
      </Show>
    )}
  </ShowController>
);
