import client from "../../utils/mococlient";
import { allUsersList } from "../../graphql/users";
import {
  allContentItemsList,
  allCoursesList,
  allContentsList,
} from "../../graphql/content";
import { allTopupsList } from "../../graphql/topups";
import { allCategoryList } from "../../graphql/category";
import { allContentVideosList } from "../../graphql/videos";
import forceLogout from "../../utils/forceLogout";
import moment from "moment";
const getList = async (resource, params) => {
  console.log("get");
  // console.log(resource);
  let gql;
  let fetchData;
  const { page, perPage } = params.pagination;
  const { field, order } = params.sort;
  const query = {
    sort: order === "DESC" ? `-${field}` : `${field}`,
  };
  try {
    const table = client.getTable(resource);
    console.log("list");
    // console.log(params);
    if (resource === "categoryNoLimit") {
      if (params.filter.activity === "true") {
        const promoted = await client
          .getTable("category_front_pages")
          .include(["category"])
          .limit(9999)
          .read();

        const id = promoted.data.map((x) => x.category_id);

        console.log(id);

        const categoryData = await client
          .getTable("category")
          .filter(table.composeFilter().eq("category_id", null).toOData())
          .read();

        const datafilter = categoryData.data.filter((x) => !id.includes(x.id));
        console.log(datafilter);
        fetchData = { data: datafilter, total: datafilter.length };
      } else {
        fetchData = await client
          .getTable("category")
          .filter(table.composeFilter().eq("category_id", null).toOData())
          .read();
      }
    } else if (resource === "certificate") {
      if (params.filter.student_id) {
        const get = await client.invokeApi("get-certificate-list", {
          student_id: params.filter.student_id,
        });
        console.log(get.data.map((x) => ({ ...x, id: x.enrollment_id })));
        fetchData = {
          data: get.data.map((x) => ({ ...x, id: x.enrollment_id })),
          total: get.data.length,
        };
      } else {
        fetchData = {
          data: [],
          total: 0,
        };
      }
    } else if (resource === "activity_logs") {
      if (params.filter.user_id && params.filter.activity) {
        const filterData = await client.invokeApi("get-activity-filters", {
          field: "action",
        });
        const actionFilter = filterData.data[params.filter.activity].action;
        fetchData = await table
          .include(["users"])
          .sort(query.sort)
          .limit(perPage)
          .offset((page - 1) * perPage)
          .filter(
            table
              .composeFilter("and")
              .eq("user_id", params.filter.user_id)
              .eq("action", actionFilter)
              .toOData()
          )
          .read();
      } else if (params.filter.activity) {
        const filterData = await client.invokeApi("get-activity-filters", {
          field: "action",
        });
        const actionFilter = filterData.data[params.filter.activity].action;
        fetchData = await table
          .include(["users"])
          .sort(query.sort)
          .limit(perPage)
          .offset((page - 1) * perPage)
          .filter(table.composeFilter().eq("action", actionFilter).toOData())
          .read();
      } else if (params.filter.user_id) {
        fetchData = await table
          .include(["users"])
          .sort(query.sort)
          .limit(perPage)
          .offset((page - 1) * perPage)
          .filter(
            table.composeFilter().eq("user_id", params.filter.user_id).toOData()
          )
          .read();
      } else if (params.filter.activityFilter) {
        fetchData = await client.invokeApi("get-activity-filters", {
          field: "action",
        });

        const manipulate = fetchData.data.map((x, index) => ({
          ...x,
          id: index,
        }));

        fetchData = { data: manipulate, total: manipulate.length };
      } else {
        fetchData = await table
          .include(["users"])
          .sort(query.sort)
          .limit(perPage)
          .offset((page - 1) * perPage)
          .read();
      }
    } else if (resource === "promoted_courses") {
      fetchData = await table
        .include(["courses"])
        .sort(query.sort)
        .limit(perPage)
        .offset((page - 1) * perPage)
        .read();
    } else if (resource === "tutor_revenues") {
      if (params.filter.tutor_id && params.filter.period) {
        const test = moment(params.filter.period)
          .set("date", 20)
          .format("YYYY-MM-DD");
        fetchData = await table
          .include(["users"])
          .sort(query.sort)
          .filter(
            table
              .composeFilter("and")
              .eq("tutor_id", params.filter.tutor_id)
              .eq("period", test)
              .toOData()
          )
          .limit(perPage)
          .offset((page - 1) * perPage)
          .read();
      } else if (params.filter.tutor_id) {
        fetchData = await table
          .include(["users"])
          .sort(query.sort)
          .filter(
            table
              .composeFilter()
              .eq("tutor_id", params.filter.tutor_id)
              .toOData()
          )
          .limit(perPage)
          .offset((page - 1) * perPage)
          .read();
      } else if (params.filter.period) {
        const test = moment(params.filter.period)
          .set("date", 20)
          .format("YYYY-MM-DD");
        console.log(test);
        fetchData = await table
          .include(["users"])
          .sort(query.sort)
          .filter(table.composeFilter().eq("period", test).toOData())
          .limit(perPage)
          .offset((page - 1) * perPage)
          .read();
      } else {
        fetchData = await table
          .include(["users"])
          .sort(query.sort)
          .limit(perPage)
          .offset((page - 1) * perPage)
          .read();
      }
    } else if (resource === "reviews") {
      if (params.filter.course_id) {
        fetchData = await table
          .include(["courses","users"])
          .sort(query.sort)
          .limit(perPage)
          .filter(
            table
              .composeFilter()
              .eq("course_id", params.filter.course_id)
              .toOData()
          )
          .offset((page - 1) * perPage)
          .read();
      } else {
        fetchData = await table
          .include(["courses","users"])
          .sort(query.sort)
          .limit(perPage)
          .offset((page - 1) * perPage)
          .read();
      }
    } else if (resource === "transfers") {
      if (params.filter.search) {
        fetchData = await table
          .include(["courses","users"])
          .sort(query.sort)
          .limit(perPage)
          .filter(
            table
              .composeFilter()
              .eq("course_id", params.filter.course_id)
              .toOData()
          )
          .offset((page - 1) * perPage)
          .read();
      } else {
        fetchData = await table
          .sort(query.sort)
          .include(["users"])
          .limit(perPage)
          .offset((page - 1) * perPage)
          .read();
      }
    } else if (resource === "front_pages") {
      fetchData = await table
        .sort(query.sort)
        .limit(perPage)
        .filter(table.composeFilter().ne("priority", 0).toOData())
        .offset((page - 1) * perPage)
        .read();
    } else if (resource === "category_front_pages") {
      console.log("test");
      fetchData = await table
        .include(["category"])
        .sort(query.sort)
        .limit(perPage)
        .offset((page - 1) * perPage)
        .read();
    } else if (resource === "contents") {
      if (
        (params.filter.id !== undefined &&
          params.filter.category_id !== undefined) ||
        params.pagination.perPage === 1000000 ||
        params.filter.id !== undefined
      ) {
        let query;
        if (params.pagination.perPage === 1000000) {
          console.log(params.filter);

          if (params.filter.category_id) {
            query = {
              query: allCoursesList,
              // query: allContentItemsList,
              variables: {
                limit: params.pagination.perPage,
                offset:
                  (params.pagination.page - 1) * params.pagination.perPage,
                sort: params.sort.field,
                order: params.sort.order,
                where: {
                  category_id_data: { id: { _eq: params.filter.category_id } },
                },
              },
            };
          }
        } else {
          console.log("here");
          query = {
            query: allContentsList,
            // query: allContentItemsList,
            variables: {
              limit: params.pagination.perPage,
              offset: (params.pagination.page - 1) * params.pagination.perPage,
              sort: params.sort.field,
              order: params.sort.order,
              filter: `course_id eq ${params.filter.id}`,
            },
          };
        }
        gql = await client.gql.query(query);
        // fetchData = {
        //   data: gql.allContentItemsList.data,
        //   total: gql.allContentItemsList.count,
        // };
        console.log(gql);

        if (params.pagination.perPage === 1000000) {
          fetchData = {
            data: gql.allCoursesList.data,
            total: gql.allCoursesList.count,
          };
        } else {
          fetchData = {
            data: gql.allContentsList.data,
            total: gql.allContentsList.count,
          };
        }
      } else {
        fetchData = {
          data: [],
          total: 0,
        };
      }
    } else if (resource === "users") {
      let query;
      if (params.filter.email) {
        query = {
          query: allUsersList,
          variables: {
            limit: params.pagination.perPage,
            offset: (params.pagination.page - 1) * params.pagination.perPage,
            sort: params.sort.field,
            order: params.sort.order,
            where: {
              roles: { _has_key: params.filter.role },
              email: { _eq: params.filter.email },
            },
          },
        };
      } else if (params.filter.role === "ALL") {
        query = {
          query: allUsersList,
          variables: {
            limit: params.pagination.perPage,
            offset: (params.pagination.page - 1) * params.pagination.perPage,
            sort: params.sort.field,
            order: params.sort.order,
          },
        };
      } else {
        query = {
          query: allUsersList,
          variables: {
            limit: params.pagination.perPage,
            offset: (params.pagination.page - 1) * params.pagination.perPage,
            sort: params.sort.field,
            order: params.sort.order,
            where: { roles: { _has_key: params.filter.role } },
          },
        };
      }
      gql = await client.gql.query(query);
      console.log(gql);

      if (params.filter.except) {
        fetchData = {
          data: gql.allUsersList.data.filter(
            (x) => x.id !== params.filter.except
          ),
          total: gql.allUsersList.count,
        };
      } else {
        fetchData = {
          data: gql.allUsersList.data,
          total: gql.allUsersList.count,
        };
      }
    } else if (resource === "category") {
      console.log(params.filter.name);
      if (params.filter.name) {
        // fetchData = await table
        //   .sort(query.sort)
        //   .limit(perPage)
        //   .offset((page - 1) * perPage)
        //   .filter(
        //     table
        //       .composeFilter("and")
        //       .eq("category_id", null)
        //       .eq("name", params.filter.name)
        //       .toOData()
        //   )
        //   .read();
        let query;
        query = {
          query: allCategoryList,
          variables: {
            limit: params.pagination.perPage,
            offset: (params.pagination.page - 1) * params.pagination.perPage,
            sort: params.sort.field,
            order: params.sort.order,
            where: {
              _and: {
                name: { _ilike: `%${params.filter.name}%` },
                category_id: { _is_null: true },
              },
            },
          },
        };
        gql = await client.gql.query(query);
        console.log(gql);
        fetchData = {
          data: gql.allCategoryList.data,
          total: gql.allCategoryList.count,
        };
      } else if (params.filter.subcategory) {
        let query;
        query = {
          query: allCategoryList,
          variables: {
            limit: params.pagination.perPage,
            offset: (params.pagination.page - 1) * params.pagination.perPage,
            sort: params.sort.field,
            order: params.sort.order,
            where: {
              category_id: { _is_null: false },
            },
          },
        };
        gql = await client.gql.query(query);
        console.log(gql);
        fetchData = {
          data: gql.allCategoryList.data,
          total: gql.allCategoryList.count,
        };
      } else {
        let query;
        query = {
          query: allCategoryList,
          variables: {
            limit: params.pagination.perPage,
            offset: (params.pagination.page - 1) * params.pagination.perPage,
            sort: params.sort.field,
            order: params.sort.order,
            where: {
              category_id: { _is_null: true },
            },
          },
        };
        gql = await client.gql.query(query);
        console.log(gql);
        fetchData = {
          data: gql.allCategoryList.data,
          total: gql.allCategoryList.count,
        };
      }
    } else if (resource === "topups") {
      console.log(params.filter);
      let query;
      if (params.filter.search) {
        query = {
          query: allTopupsList,
          variables: {
            limit: params.pagination.perPage,
            offset: (params.pagination.page - 1) * params.pagination.perPage,
            sort: params.sort.field,
            order: params.sort.order,
            where: {
              metadata: { _has_key: "order_id" },
              _and: {
                _or: [
                  {
                    created_by_data: {
                      name: { _ilike: `%${params.filter.search}%` },
                    },
                  },
                  {
                    metadata: {
                      _contains: { transaction_id: params.filter.search },
                    },
                  },
                ],
              },
            },
          },
        };
      } else {
        query = {
          query: allTopupsList,
          variables: {
            limit: params.pagination.perPage,
            offset: (params.pagination.page - 1) * params.pagination.perPage,
            sort: params.sort.field,
            order: params.sort.order,
            where: {
              metadata: { _has_key: "order_id" },
            },
          },
        };
      }
      gql = await client.gql.query(query);
      console.log(gql);
      fetchData = {
        data: gql.allTopupsList.data,
        total: gql.allTopupsList.count,
      };
    } else if (resource === "courses") {
      if (params.filter.draft) {
        fetchData = await table
          .include(["category"])
          .sort(query.sort)
          .limit(perPage)
          .offset((page - 1) * perPage)
          .filter(
            table
              .composeFilter()
              .eq("draft", params.filter.draft === "true" ? true : false)
              .toOData()
          )
          .read();
      } else if (params.filter.activity) {
        const promoted = await client
          .getTable("promoted_courses")
          .include(["courses"])
          .limit(9999)
          .read();

        const id = promoted.data.map((x) => x.course_id);

        console.log(id);

        const courseData = await table
          .include(["category"])
          .sort(query.sort)
          .limit(perPage)
          .offset((page - 1) * perPage)
          .filter(table.composeFilter().eq("draft", false).toOData())
          .read();

        const datafilter = courseData.data.filter((x) => !id.includes(x.id));
        console.log(datafilter);
        fetchData = { data: datafilter, total: datafilter.length };
      } else {
        fetchData = await table
          .include(["category"])
          .sort(query.sort)
          .limit(perPage)
          .offset((page - 1) * perPage)
          .read();
      }
    } else if (resource === "vouchers") {
      if (params.filter.search) {
        if (params.filter.dateSet === "limit") {
          fetchData = await table
            .include(["courses"])
            .sort(query.sort)
            .limit(perPage)
            .filter(
              table
                .composeFilter("and")
                .ge("limit_date", moment(Date.now()).format())
                .startsWith("code", params.filter.search)
                .toOData()
            )
            .offset((page - 1) * perPage)
            .read();
        } else {
          fetchData = await table
            .include(["courses"])
            .sort(query.sort)
            .limit(perPage)
            .filter(
              table
                .composeFilter()
                .startsWith("code", params.filter.search)
                .toOData()
            )
            .offset((page - 1) * perPage)
            .read();
        }
      } else {
        if (params.filter.dateSet === "limit") {
          fetchData = await table
            .include(["courses"])
            .sort(query.sort)
            .limit(perPage)
            .filter(
              table
                .composeFilter()
                .ge("limit_date", moment(Date.now()).format())
                .toOData()
            )
            .offset((page - 1) * perPage)
            .read();
        } else {
          fetchData = await table
            .include(["courses"])
            .sort(query.sort)
            .limit(perPage)
            .offset((page - 1) * perPage)
            .read();
        }
      }
    } else if (resource === "content_items" && params.filter.content_id) {
      const query = {
        query: allContentItemsList,
        variables: {
          limit: params.pagination.perPage,
          offset: (params.pagination.page - 1) * params.pagination.perPage,
          sort: params.sort.field,
          order: params.sort.order,
          filter: `content_id eq ${params.filter.content_id}`,
        },
      };
      gql = await client.gql.query(query);
      console.log(gql);
      fetchData = {
        data: gql.allContentItemsList.data,
        total: gql.allContentItemsList.count,
      };
    } else if (resource === "content_videos") {
      console.log(params);
      console.log("video");
      console.log(params.filter.id);
      if (params.filter.content_id !== undefined) {
        console.log("ada");
        // fetchData = await table
        //   .include("content_items")
        //   .sort(query.sort)
        //   .limit(perPage)
        //   .offset((page - 1) * perPage)
        //   .filter(
        //     table
        //       .composeFilter()
        //       .eq("item_id", params.filter.content_id)
        //       .toOData()
        //   )
        //   .read();
        const query = {
          query: allContentVideosList,
          variables: {
            limit: params.pagination.perPage,
            offset: (params.pagination.page - 1) * params.pagination.perPage,
            sort: params.sort.field,
            order: params.sort.order,
            where: {
              item_id_data: { content_id: { _eq: params.filter.content_id } },
            },
          },
        };
        gql = await client.gql.query(query);
        console.log(gql);
        fetchData = {
          data: gql.allContentVideosList.data,
          total: gql.allContentVideosList.count,
        };
      } else {
        // fetchData = await table
        //   .include("content_items")
        //   .sort(query.sort)
        //   .limit(perPage)
        //   .offset((page - 1) * perPage)
        //   .filter(table.composeFilter().ne("item_id", null).toOData())
        //   .read();
        const query = {
          query: allContentVideosList,
          variables: {
            limit: params.pagination.perPage,
            offset: (params.pagination.page - 1) * params.pagination.perPage,
            sort: params.sort.field,
            order: params.sort.order,
            filter: "item_id ne null",
          },
        };
        gql = await client.gql.query(query);
        console.log(gql);
        fetchData = {
          data: gql.allContentVideosList.data,
          total: gql.allContentVideosList.count,
        };
      }
    } else {
      fetchData = await table
        .sort(query.sort)
        .limit(perPage)
        .offset((page - 1) * perPage)
        .read();
    }

    // console.log(params);
    // console.log(resource);
    console.log(fetchData);

    return fetchData;
  } catch (e) {
    console.log(e);
    await forceLogout(e);
  }
};

export default getList;
