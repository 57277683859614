// in src/customRoutes.js
import React from "react";
import { Route } from "react-router-dom";
import BackgroundEdit from "../Page/Custom/BackgroundEdit";
import EditProfile from "../component/EditProfile";

// function OpenProfile() {
//   const redirect = useRedirect();
//   useEffect(() => {
//     client.auth.openAccountPage().then(() => {
//       redirect("/");
//     });
//   }, [redirect]);
//   return <div></div>;
// }

const OpenProfile = () => {
  return <EditProfile />;
};

export default [
  <Route exact path="/profile" component={OpenProfile} />,
  <Route exact path="/bg" component={BackgroundEdit} />,
];
