import React from "react";
import { List, Datagrid, TextField } from "react-admin";
import { ExportButton } from "react-admin";
import Toolbar from "@material-ui/core/Toolbar";
import {
  Create,
  SimpleForm,
  DateField,
  AutocompleteInput,
  required,
  ReferenceInput,
  FunctionField,
  TextInput,
  useRefresh,
  Filter,
  DateInput,
  downloadCSV,
} from "react-admin";
import formatRupiah from "../utils/priceFormat";
import Button from "@material-ui/core/Button";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import client from "../utils/mococlient";
import Alerta from "@material-ui/lab/Alert";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import jsonExport from "jsonexport/dist";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const RevenueList = (props) => {
  const refresh = useRefresh();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  // const [wrongOtp, setWrongOtp] = React.useState(false);
  const [errorGenerate, setErrorGenerate] = React.useState(false);
  const [overwrite, setOverwrite] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = React.useState("");
  const [message, setMessage] = React.useState("");
  // eslint-disable-next-line no-unused-vars
  const [amount, setAmount] = React.useState("");
  const [date, setDate] = React.useState(moment(Date.now()).format());
  // const handleClickSnack = () => {
  //   setOpen(true);
  // };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  // const changeDate = (data) => {
  //   setDate(data);
  // };

  const handleOverwrite = async () => {
    // setWrongOtp(false);
    setErrorGenerate(false);

    setLoading(true);
    const payload = {
      period: date,
      overwrite,
    };
    console.log(payload);
    const generate = await client.invokeApi("generate-revenues", payload);
    console.log(generate);

    if (generate.error) {
      setToken("");
      // setWrongOtp(true);
      setMessage(generate.error.message);
      setErrorGenerate(true);
      setLoading(false);
    } else {
      setLoading(false);
      setOpenDialog(false);
      setAmount("");
      setToken("");
      refresh();
      setSuccess(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setErrorGenerate(false);
    // setWrongOtp(false);
    setAmount("");
    setToken("");
  };

  const PostActions = ({
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
  }) => (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {/* <CreateButton basePath={basePath} /> */}
      <Button
        variant="text"
        color="primary"
        startIcon={<AutorenewIcon />}
        size="small"
        onClick={() => setOpenDialog(true)}
      >
        Generate
      </Button>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
    </Toolbar>
  );

  const exporter = (posts) => {
    const postsForExport = posts.map((post) => {
      const { backlinks, author, ...postForExport } = post; // omit backlinks and author
      const data = postForExport;
      const postExport = {
        id: data.id,
        tutor_name: data.users__tutor_id.name,
        tutor_email: data.users__tutor_id.email,
        revenue: data.revenue,
        sales: data.sales,
        period: moment(data.period).format("DD-MM-YYYY, h:mm:ss a"),
        generated: data.users__generated_by.name,
        created: moment(data.created_at).format("DD-MM-YYYY, h:mm:ss a"),
        updated: moment(data.updated_at).format("DD-MM-YYYY, h:mm:ss a"),
      };
      return postExport;
    });
    jsonExport(
      postsForExport,
      {
        rename: [
          "ID",
          "Name",
          "Email",
          "Revenue",
          "Sales",
          "Period",
          "Generate by",
          "Created at",
          "Updated at",
        ], // order fields in the export
      },
      (err, csv) => {
        downloadCSV(csv, "posts"); // download as 'posts.csv` file
      }
    );
  };

  return (
    <React.Fragment>
      <List
        {...props}
        actions={<PostActions />}
        bulkActionButtons={false}
        title="Front Pages"
        sort={{ field: "created_at", order: "DESC" }}
        filters={<PostFilter />}
        exporter={exporter}
        empty={false}
      >
        <Datagrid>
          <TextField
            label="Tutor name"
            source="users__tutor_id.name"
            sortable={false}
          />
          <TextField
            label="Tutor email"
            source="users__tutor_id.email"
            sortable={false}
          />
          <FunctionField
            label="Revenue"
            render={(record) => formatRupiah(record.revenue)}
          />
          <FunctionField
            label="Sales"
            render={(record) => formatRupiah(record.sales)}
          />
          <DateField locales="en-TT" label="Period" source="period" />
          <TextField
            label="Generated by"
            source="users__generated_by.name"
            sortable={false}
          />
          <DateField
            locales="en-TT"
            label="Created"
            source="created_at"
            showTime
          />
          <DateField
            locales="en-TT"
            label="Updated"
            source="updated_at"
            showTime
          />
        </Datagrid>
      </List>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity="warning">
          Please retry later
        </Alert>
      </Snackbar>
      <Snackbar
        open={success}
        autoHideDuration={3000}
        onClose={() => setSuccess(false)}
      >
        <Alert onClose={() => setSuccess(false)} severity="success">
          Success generate data
        </Alert>
      </Snackbar>
      <Dialog
        open={openDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">Generate Revenue</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Generate revenue will count in 20th every month
          </DialogContentText>
          <div>
            {errorGenerate && (
              <Alerta severity="error" style={{ marginBottom: "5px" }}>
                {message}
              </Alerta>
            )}
            <DatePicker
              fullWidth
              views={["year", "month"]}
              label="Year and Month"
              value={date}
              style={{ marginBottom: "15px" }}
              onChange={(event, x) => {
                setDate(moment(event._d).set("date", 20).format());
              }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={overwrite}
                  onChange={() => setOverwrite(!overwrite)}
                  name="overwrite"
                  color="primary"
                />
              }
              label="Overwrite Data"
            />
            {/* {wrongOtp && (
              <Alerta severity="error" style={{ marginBottom: "15px" }}>
                Token Invalid!
              </Alerta>
            )}
            <TextFieldMui
              label="Token"
              value={token}
              required
              onChange={(e) => setToken(e.target.value)}
              fullWidth
              style={{ marginBottom: "20px" }}
            /> */}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            disabled={loading}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleOverwrite}
            color="primary"
            variant="contained"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress style={{ width: "25px", height: "25px" }} />
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export const TransferCreate = (props) => (
  <Create {...props} title="Add Front Pages">
    <SimpleForm redirect="list">
      <ReferenceInput
        label="Recipient"
        source="recipient_id"
        reference="users"
        filter={{
          role: "Student",
        }}
        perPage={100000}
        sort={{ field: "name", order: "ASC" }}
        validate={[required()]}
      >
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
      <TextInput label="Amount" source="amount" validate={[required()]} />
      <TextInput label="Code OTP" source="token" validate={[required()]} />
    </SimpleForm>
  </Create>
);

const PostFilter = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput
        label="Select Tutor"
        source="tutor_id"
        reference="users"
        perPage={100000}
        sort={{ field: "name", order: "ASC" }}
        filter={{
          role: "Tutor",
        }}
        alwaysOn
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <DateInput locales="en-TT" source="period" alwaysOn />
      <Button
        onClick={() =>
          props.setFilters({
            tutor_id: "",
            period: "",
          })
        }
        alwaysOn
        startIcon={<HighlightOffIcon />}
        variant="text"
        color="primary"
        style={{
          marginBottom: "10px",
          backgroundColor: "#2196F3",
          color: "white",
        }}
      >
        Reset
      </Button>
    </Filter>
  );
};
