import React from "react";
import "./App.css";
import Mooc from "./Page/Mooc";
import * as Sentry from "@sentry/react";
import { isProduction } from "./utils/variabels";
import { Integrations } from "@sentry/tracing";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateUtils from "@date-io/moment";

if (isProduction) {
  Sentry.init({
    dsn:
      "https://ada15fd56a914b858cee3e51b326540f@o131490.ingest.sentry.io/5599510",
    autoSessionTracking: false,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

function App() {
  return (
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <MuiPickersUtilsProvider utils={DateUtils}>
        <Mooc />
      </MuiPickersUtilsProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
