// in src/Dashboard.js
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { useRedirect } from "react-admin";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import forceLogout from "../utils/forceLogout";
import client from "../utils/mococlient";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alerta from "@material-ui/lab/Alert";
import FacebookIcon from "@material-ui/icons/Facebook";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Grid from "@material-ui/core/Grid";
import { Divider } from "@material-ui/core";

const ggIcon = (
  <img
    src={"google.png"}
    alt="google"
    style={{ height: "15px" }}
  />
);
const fbIcon = <FacebookIcon style={{ color: "#4267B2" }} />;
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const EditProfile = () => {
  const [openProfile, setOpenProfile] = React.useState(false);
  const redirect = useRedirect();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [profile, setProfile] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [loadingProfile, setLoadingProfile] = React.useState(false);
  const [loadingForgot, setLoadingForgot] = React.useState(false);
  const [successProfile, setSuccessProfile] = React.useState(false);
  const [successReset, setSuccessReset] = React.useState(false);
  const [successForgot, setSuccessForgot] = React.useState(false);
  const [errorOld, setErrorOld] = React.useState(false);
  const [oldPass, setOld] = React.useState("");
  const [newPass, setNew] = React.useState("");
  const [showPass, setShowpass] = React.useState(false);
  const [errorLink, setErrorLink] = React.useState(false);
  const [successLink, setSuccessLink] = React.useState(false);
  const [loadingLink, setLoadingLink] = React.useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const fetchProfile = async () => {
    setLoading(true);
    try {
      const profile = await client.user.get();
      console.log(profile);
      if (profile.error) {
        await forceLogout(profile);
      } else {
        setProfile(profile.data);
      }
      setLoading(false);
    } catch (e) {
      await forceLogout(e);
      setLoading(false);
    }
  };
  const handleClickShowPassword = () => {
    setShowpass(!showPass);
  };
  const checkCode = () => {
    const thisUrl = new URL(window.location.href);
    if (thisUrl.searchParams.get("code")) {
      setValue(2);
    }
  };

  const requestLink = async () => {
    setErrorLink(false);
    setSuccessLink("");

    const thisUrl = new URL(window.location.href);
    const code = thisUrl.searchParams.get("code");
    const socmed = thisUrl.searchParams.get("prompt") ? "google" : "facebook";
    if (code) {
      setLoadingLink(socmed);

      // Verifikasi OAuth.
      const loginRes = await client.auth.login(
        socmed,
        {
          callback: `${window.location.origin}/profile`,
          code,
        },
        "link"
      );
      // console.log(loginRes);
      // Jika dapat error.
      const loginData = loginRes.data;

      if (loginRes.data) {
        console.log("loginRes:", loginRes);
        // Cantolkan akun sosial tersebut ke pengguna ini.
        const linkRes = await client.user.update("link", {
          provider: socmed,
          oauthKey: loginData.oauthKey,
        });
        console.log(linkRes);
        setSuccessLink("Link social media success");
        await fetchProfile();
        setLoadingLink("");
        return;
      } else {
        setErrorLink(true);
      }
      setLoadingLink("");
    }

    // const loginData = loginRes.data;

    // // Cantolkan akun sosial tersebut ke pengguna ini.
    // const linkRes = await client.user.update("link", {
    //   provider: "twitter",
    //   oauthKey: loginData.oauthKey,
    // });
  };

  const unlink = async (socmed) => {
    setSuccessLink("");
    setLoadingLink(socmed);
    try {
      const res = await client.user.update("unlink", {
        provider: socmed,
      });
      console.log(res);
      await fetchProfile();
      setSuccessLink("Unlink social media success");
    } catch (e) {
      await forceLogout(e);
    }
    setLoadingLink("");
  };

  React.useEffect(() => {
    fetchProfile();
    setOpenProfile(true);
    checkCode();
    requestLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCloseShowQR = () => {
    setOpenProfile(false);
    redirect("/");
  };

  const EditProfileRequest = async (e) => {
    e.preventDefault();
    setLoadingProfile(true);
    setSuccessProfile(false);
    try {
      const res = await client.user.update("profile", {
        name: profile.name,
      });
      console.log(res);
      localStorage.setItem("permission", JSON.stringify(res.data));
      setLoadingProfile(false);
      setSuccessProfile(true);
    } catch (e) {
      await forceLogout(e);
      setLoadingProfile(false);
    }
  };

  const PasswordChange = async (e) => {
    e.preventDefault();
    setLoadingProfile(true);
    setSuccessProfile(false);
    setErrorOld(false);
    try {
      const res = await client.user.update("password", {
        oldPassword: oldPass,
        newPassword: newPass,
      });
      console.log(res);
      if (res.data) {
        console.log("OK");
        setSuccessReset(true);
        setNew("");
        setOld("");
      } else {
        console.log("ERROR");
        setErrorOld(true);
      }
      setLoadingProfile(false);
    } catch (e) {
      await forceLogout(e);
      setLoadingProfile(false);
    }
  };

  const handleInitOauth = async (provider) => {
    // const initRes = await initOauth(config.serverOidcUrl, provider);
    setLoadingLink(provider);
    const loginsosmed = await client.auth.oauthRedirect(
      provider,
      `${window.location.origin}/profile`
    );
    console.log(loginsosmed);
    setLoadingLink("");
  };

  const forgotRequest = async () => {
    setLoadingForgot(true);
    setSuccessForgot(false);
    try {
      const req = await client.auth.forgotPassword(profile.email);
      console.log(req);
      setSuccessForgot(true);
    } catch (e) {
      console.log(e);
      setSuccessForgot(false);
    }
    setLoadingForgot(false);
  };
  return (
    <Dialog
      open={openProfile}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      {/* <DialogTitle id="form-dialog-title">Edit Profile</DialogTitle> */}
      <DialogContent>
        <div className={classes.root}>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "30px",
              }}
            >
              <CircularProgress style={{ width: "75px", height: "75px" }} />
            </div>
          ) : (
            <React.Fragment>
              <AppBar position="static" style={{ marginBottom: "-30px" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="fullWidth"
                  aria-label="simple tabs example"
                >
                  <Tab label="Edit Profile" {...a11yProps(0)} />
                  <Tab label="Change Password" {...a11yProps(1)} />
                  <Tab label="Social Media Link" {...a11yProps(2)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <form
                  onSubmit={EditProfileRequest}
                  style={{ margin: "10px -20px" }}
                >
                  {successProfile && (
                    <Alerta severity="success" style={{ marginTop: "20px" }}>
                      Profile change success !
                    </Alerta>
                  )}

                  <TextField
                    value={profile?.name || ""}
                    onChange={(e) =>
                      setProfile({ ...profile, name: e.target.value })
                    }
                    id="outlined-basic"
                    label="Name"
                    fullWidth
                    required
                    style={{ margin: "20px 0px" }}
                  />
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    disabled={loadingProfile}
                    size="small"
                  >
                    {loadingProfile ? (
                      <CircularProgress
                        style={{ width: "20px", height: "20px" }}
                      />
                    ) : (
                      " Save Edit Profile"
                    )}
                  </Button>
                </form>
              </TabPanel>

              {/* change password */}
              <TabPanel value={value} index={1}>
                <form
                  onSubmit={PasswordChange}
                  style={{ margin: "20px -20px" }}
                >
                  {successReset && (
                    <Alerta severity="success">Change password success</Alerta>
                  )}
                  {errorOld && (
                    <Alerta severity="error" style={{ marginBottom: "20px" }}>
                      Old password incorrect
                    </Alerta>
                  )}

                  <FormControl
                    variant="outlined"
                    fullWidth
                    required
                    style={{ margin: "10px 0px 20px 0px" }}
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Old Password
                    </InputLabel>
                    <OutlinedInput
                      labelWidth={120}
                      type={showPass ? "text" : "password"}
                      id="oldpassword"
                      required
                      error={errorOld}
                      value={oldPass}
                      onChange={(e) => setOld(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPass ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    required
                    style={{ margin: "5px 0px 20px 0px" }}
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      New Password
                    </InputLabel>
                    <OutlinedInput
                      labelWidth={120}
                      type={showPass ? "text" : "password"}
                      id="newpassword"
                      required
                      error={errorOld}
                      value={newPass}
                      onChange={(e) => setNew(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPass ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <Grid container justify="space-between">
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={loadingProfile}
                      size="small"
                    >
                      {loadingProfile ? (
                        <CircularProgress
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : (
                        " Change password"
                      )}
                    </Button>
                  </Grid>
                </form>

                <Divider style={{ marginTop: "20px" }} />
                <Grid container justify="center" style={{ marginTop: "10px" }}>
                  {successForgot && (
                    <Alerta severity="success">
                      Reset Password already send, please check your email.
                    </Alerta>
                  )}
                  <p>
                    Forgot password ? please click this button for reset
                    password
                  </p>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={loadingForgot}
                    size="small"
                    onClick={() => forgotRequest()}
                  >
                    {loadingForgot ? (
                      <CircularProgress
                        style={{ width: "20px", height: "20px" }}
                      />
                    ) : (
                      " forgot password"
                    )}
                  </Button>
                </Grid>
                <Divider style={{ marginTop: "20px" }} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Grid
                  container
                  direction="column"
                  spacing={5}
                  style={{
                    margin: "20px -20px",
                  }}
                >
                  {errorLink && (
                    <Alerta severity="error">Account already use</Alerta>
                  )}
                  {successLink !== "" && (
                    <Alerta severity="success">{successLink}</Alerta>
                  )}
                  {profile?.social_ids && profile.social_ids.google ? (
                    <Button
                      fullWidth
                      variant="contained"
                      startIcon={
                        loadingLink === "" && loadingLink !== "google" && ggIcon
                      }
                      className={classes.linkButton}
                      style={{ margin: "20px 0px" }}
                      onClick={() => unlink("google")}
                    >
                      {loadingLink === "google" ? (
                        <CircularProgress
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : (
                        " UNLINK GOOGLE ACCOUNT"
                      )}
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      startIcon={
                        loadingLink === "" && loadingLink !== "google" && ggIcon
                      }
                      className={classes.linkButton}
                      style={{ margin: "20px 0px" }}
                      onClick={() => handleInitOauth("google")}
                    >
                      {loadingLink === "google" ? (
                        <CircularProgress
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : (
                        " LINK WITH GOOGLE ACCOUNT"
                      )}
                    </Button>
                  )}

                  {profile?.social_ids && profile.social_ids.facebook ? (
                    <Button
                      fullWidth
                      variant="contained"
                      startIcon={
                        loadingLink === "" &&
                        loadingLink !== "facebook" &&
                        fbIcon
                      }
                      className={classes.linkButton}
                      onClick={() => unlink("facebook")}
                    >
                      {loadingLink === "facebook" ? (
                        <CircularProgress
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : (
                        "UNLINK FACEBOOK ACCOUNT"
                      )}
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      startIcon={
                        loadingLink === "" &&
                        loadingLink !== "facebook" &&
                        fbIcon
                      }
                      className={classes.linkButton}
                      onClick={() => handleInitOauth("facebook")}
                    >
                      {loadingLink === "facebook" ? (
                        <CircularProgress
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : (
                        "LINK WITH FACEBOOK ACCOUNT"
                      )}
                    </Button>
                  )}
                </Grid>
              </TabPanel>
            </React.Fragment>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseShowQR} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditProfile;
