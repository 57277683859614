export const API = "https://mooc-test-api.hash.id";
export const API_PROD = "";

export const STORAGE = "";
export const STORAGE_PROD = "";

export const LANDING = "";
export const LANDING_PROD = "";

const testEnv = {
  privateBucket: "mooc-test-private-data-stores",
  publicBucket: "mooc-test-public-data-stores",
  apiUrl: "https://mooc-test-api.hash.id",
  studentWeb: "https://mooc-test.hash.id",
  staffWeb: "https://develop--greatchain-office.netlify.app",
  // tutorWeb: "https://develop--greatchain-tutor.netlify.app",
  loginUI: "https://develop--greatchain-auth.netlify.app/login",
};

const productionEnv = {
  privateBucket: "mooc-prod-private-data-stores",
  publicBucket: "mooc-prod-public-data-stores",
  apiUrl: "https://core.greatchain.academy",
  studentWeb: "https://greatchain.academy",
  staffWeb: "https://office.greatchain.academy",
  // tutorWeb: "https://tutor.greatchain.academy",
  loginUI: "https://auth.greatchain.academy/login",
};

export const isProduction =
  window.location.host === "office.greatchain.academy";

const validEnv = isProduction ? productionEnv : testEnv;

export default validEnv;

export function redirectOnRoles(role) {
  if (role === "Student") {
    window.open(validEnv.studentWeb, "_self");
  }
  // else if (["Staff", "Administrator"].includes(role)) {
  //   window.location.replace("/");
  // }
  else if (["Tutor"].includes(role)) {
    window.open(validEnv.tutorWeb, "_self");
  } else {
    return false;
  }
  return true;
}

export function redirectOnLanding(role) {
  if (role === "Student" || role === "Tutor") {
    window.open(validEnv.studentWeb, "_self");
  }

  //   else if (["Staff", "Administrator"].includes(role)) {
  //     window.open(validEnv.staffWeb, "_self");
  //   }
  // else if (["Tutor"].includes(role)) {
  //   window.open(validEnv.tutorWeb, "_self");
  // }
  else {
    return false;
  }
  return true;
}
