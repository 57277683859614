/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function EnhancedTable({ open, setDialog, indexTarget }) {
  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      scroll="paper"
      onClose={() => setDialog(false)}
      // fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">
        Progress Course from{" "}
        {indexTarget.student_id_data && indexTarget.student_id_data.name}
      </DialogTitle>
      <DialogContent>
        <h3>Content item</h3>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Start at</TableCell>
              <TableCell>Finish at</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {indexTarget.enrollment_progress &&
              indexTarget.enrollment_progress.data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.content_item_id_data.title}
                  </TableCell>
                  <TableCell>
                    {row.start_at !== null
                      ? moment(row.start_at).format("DD-MM-YYYY, h:mm:ss a")
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {row.finish_at !== null
                      ? moment(row.finish_at).format("DD-MM-YYYY, h:mm:ss a")
                      : "-"}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        <h3>Quiz</h3>

        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Point</TableCell>
              <TableCell>Start at</TableCell>
              <TableCell>Finish at</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {indexTarget.quiz_result_list &&
              indexTarget.quiz_result_list.data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.quiz_id_data.title}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.point}
                  </TableCell>
                  <TableCell>
                    {row.start_at !== null
                      ? moment(row.start_at).format("DD-MM-YYYY, h:mm:ss a")
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {row.finish_at !== null
                      ? moment(row.finish_at).format("DD-MM-YYYY, h:mm:ss a")
                      : "-"}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDialog(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
