import React from "react";

import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  Create,
  required,
  Toolbar,
  SaveButton,
  DeleteButton,
  FileInput,
  useNotify,
  FileField,
  useInput,
  useRedirect,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import Button from "@material-ui/core/Button";
import forceLogout from "../utils/forceLogout";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { VideoItemRenderer } from "../component/DrmVideo";
import LinearProgress from "@material-ui/core/LinearProgress";
import client from "../utils/mococlient";
import { getContentItemsById } from "../graphql/content";
import isProd from "../utils/variabels";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SketchPicker } from "react-color";

const redirect = (basePath, id, data) => `/contents`;

const SaveWithNoteButton = (props) => {
  const redirectTo = useRedirect();
  const notify = useNotify();

  const handleSave = async (values, redirect) => {
    try {
      // console.log(values);
      props.setProgress(true);
      const params = { data: values };
      let dataUpdate = {
        ...params.data,
        priority: parseInt(params.data.priority),
      };

      // console.log(data);
      if (params.data.priorityValue) {
        const { position } = params.data;
        const query = {
          query: getContentItemsById,
          variables: {
            id: params.data.priorityValue,
          },
        };
        const gql = await client.gql.query(query);
        const prioversus = gql.getContentItemsById.priority;

        const modifier = position ? (position === "before" ? -1 : 1) : 0;
        const modScale = Math.ceil(Math.random() * 5);
        dataUpdate = {
          ...params.data,
          priority: prioversus + Number(modifier * modScale),
        };
      }

      if (params.data.files) {
        const table = client.getTable("content_videos");

        if (params.data.video.data[0]) {
          console.log("Remove previous video");
          const removePrevData = {
            id: params.data.video.data[0].id,
            data: { item_id: null },
          };
          const removePREV = await table.update(removePrevData);
          console.log(removePREV);
        }
        console.log("Replace ");
        const upload = await client.storage.upload({
          params: {
            filename: params.data.files.title,
            bucket: isProd.privateBucket,
            expire: "600",
          },
          file: params.data.files.rawFile,
          progressCallback: (progressVal) => props.setLoading(progressVal),
        });
        console.log(upload);
        const dataFixCreate = {
          item_id: params.data.id,
          raw_file: upload.data.id,
        };
        const createVid = await table.insert(dataFixCreate);
        console.log(createVid);
      }

      if (params.data.watermark_color) {
        const table = client.getTable("content_videos");
        const removePrevData = {
          id: params.data.video.data[0].id,
          data: { watermark_color: params.data.watermark_color },
        };
        const changeColor = await table.update(removePrevData);
        console.log(changeColor);
      }

      const table = client.getTable("content_items");
      const dataFixUpdate = {
        id: params.data.id,
        data: dataUpdate,
      };
      const update = await table.update(dataFixUpdate);
      console.log(update);
      // return update;

      props.setProgress(false);

      notify("ra.notification.updated", "info", {
        smart_count: 1,
      });
      redirectTo(redirect);
    } catch (e) {
      props.setProgress(false);
      console.log(e);
      await forceLogout(e);
    }
  };
  return (
    <SaveButton {...props} onSave={handleSave} disabled={props.disabled} />
  );
};

export const ContentItemEdit = (props) => {
  const [loading, setLoading] = React.useState(0);
  const [progress, setProgress] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [data, setData] = React.useState({});
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const fetchVideo = async (record) => {
    console.log("set data");
    console.log(record);
    setData(record);
    handleClickOpenDialog();
  };

  const CustomToolbar = (props) => (
    <Toolbar
      {...props}
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      {progress ? (
        <Button
          color="default"
          disabled
          style={{ bakcgroundColor: "#D7D7D7" }}
          variant="contained"
        >
          <CircularProgress style={{ width: "25px", height: "25px" }} />
        </Button>
      ) : (
        <SaveWithNoteButton
          setLoading={setLoading}
          setProgress={setProgress}
          disabled={props.pristine}
        />
      )}
      <DeleteButton undoable={false} redirect={redirect} />
    </Toolbar>
  );

  return (
    <React.Fragment>
      <Edit {...props} undoable={false}>
        <SimpleForm redirect={redirect} toolbar={<CustomToolbar />}>
          <TextInput label="Title" source="title" />
          {/* <TextInput label="Priority" source="priority" /> */}
          <SelectInput
            label="Priority Position"
            source="position"
            choices={[
              { id: "before", name: "Before" },
              { id: "after", name: "After" },
            ]}
          />
          <ReferenceInput
            label="Priority Anchor"
            source="priorityValue"
            reference="content_items"
            perPage={100000}
            filter={{
              content_id: props.history.location.state.record.content_id,
            }}
            sort={{ field: "priority", order: "ASC" }}
          >
            <SelectInput optionText="title" />
          </ReferenceInput>

          <SingleField fetchVideo={fetchVideo} />
          <FileInput
            source="files"
            label="Replace Video"
            multiple={false}
            accept="video/*"
          >
            <FileField source="src" title="title" />
          </FileInput>

          {loading !== 0 && (
            <div style={{ width: "100%", marginBottom: "10px" }}>
              <LinearProgress variant="determinate" value={loading} fullWidth />
              <div>{`Upload process : ${loading}%`}</div>
            </div>
          )}
          <ColorWater
            onChange={(value) => console.log(value)}
            source="watermark_color"
            label="Watermark Color (Click to change)"
          />

          <RichTextInput source="theory" />
        </SimpleForm>
      </Edit>
      <Dialog
        open={openDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">Video content item</DialogTitle>
        <DialogContent>
          <VideoItemRenderer data={data} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const CreateWithNoteButton = (props) => {
  const notify = useNotify();
  const redirectTo = useRedirect();

  // console.log(props);
  let data;
  const handleSave = async (values, redirect) => {
    try {
      // console.log("CREATE");
      // console.log(props);
      // console.log(values);
      const params = { data: values };
      props.setProgress(true);

      //process
      if (params.data.priorityValue) {
        const { position } = params.data;
        const query = {
          query: getContentItemsById,
          variables: {
            id: params.data.priorityValue,
          },
        };
        const gql = await client.gql.query(query);
        const prioversus = gql.getContentItemsById.priority;
        const modifier = position ? (position === "before" ? -1 : 1) : 0;
        const modScale = Math.ceil(Math.random() * 5);
        data = {
          ...params.data,
          priority: prioversus + Number(modifier * modScale),
        };
      } else {
        data = {
          ...params.data,
          priority: 1000,
        };
      }

      console.log(data);
      const table = client.getTable("content_items");
      const add = await table.insert(data);

      if (params.data.files) {
        console.log("UPLOAD");
        const upload = await client.storage.upload({
          params: {
            filename: params.data.files.title,
            bucket: isProd.privateBucket,
            expire: "600",
          },
          file: params.data.files.rawFile,
          progressCallback: (progressVal) => props.setLoading(progressVal),
        });
        console.log(upload);
        const table = client.getTable("content_videos");
        const dataFixCreate = {
          item_id: add.data.id,
          raw_file: upload.data.id,
          watermark_color: params.data.watermark_color
            ? params.data.watermark_color
            : "#e8e8e8",
        };
        const createVid = await table.insert(dataFixCreate);
        console.log(createVid);
      }
      props.setProgress(false);
      notify("ra.notification.created", "info", {
        smart_count: 1,
      });
      redirectTo(redirect);
    } catch (e) {
      props.setProgress(false);
      console.log(e);
      await forceLogout(e);
    }
  };
  return <SaveButton {...props} onSave={handleSave} />;
};

export const ContentItemsCreate = (props) => {
  const [loading, setLoading] = React.useState(0);
  const [progress, setProgress] = React.useState(false);

  const CustomToolbar = (props) => (
    <Toolbar
      {...props}
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      {progress ? (
        <Button
          color="default"
          disabled
          style={{ bakcgroundColor: "#D7D7D7" }}
          variant="contained"
        >
          <CircularProgress style={{ width: "25px", height: "25px" }} />
        </Button>
      ) : (
        <CreateWithNoteButton
          setLoading={setLoading}
          setProgress={setProgress}
        />
      )}
      <DeleteButton undoable={false} redirect={redirect} />
    </Toolbar>
  );

  return (
    <Create {...props}>
      <SimpleForm redirect={redirect} toolbar={<CustomToolbar />}>
        <TextInput source="title" validate={[required()]} />
        <SelectInput
          label="Priority Position"
          source="position"
          choices={[
            { id: "before", name: "Before" },
            { id: "after", name: "After" },
          ]}
        />

        <ReferenceInput
          label="Priority Anchor"
          source="priorityValue"
          reference="content_items"
          perPage={100000}
          sort={{ field: "priority", order: "ASC" }}
          filter={{
            content_id: props.history.location.state.record.content_id,
          }}
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <ColorWaterCreate
          onChange={(value) => console.log(value)}
          source="watermark_color"
          label="Watermark Color (Click to change)"
        />
        <FileInput
          source="files"
          label="Video"
          multiple={false}
          accept="video/*"
        >
          <FileField source="src" title="title" />
        </FileInput>
        {loading !== 0 && (
          <div style={{ width: "100%", marginBottom: "10px" }}>
            <LinearProgress variant="determinate" value={loading} fullWidth />
            <div>{`Upload process : ${loading}%`}</div>
          </div>
        )}
        <RichTextInput source="theory" />
      </SimpleForm>
    </Create>
  );
};

const SingleField = ({ record = {}, fetchVideo }) => {
  return (
    <span>
      {record.video
        ? record.video.count === 0
          ? "-"
          : record.video.data[0].raw_file === null
          ? record.video.data[0].item_id
          : record.video.data[0].raw_file
        : "-"}
      <IconButton
        color="primary"
        aria-label="play"
        onClick={() => fetchVideo(record)}
      >
        <PlayCircleFilledIcon />
      </IconButton>
    </span>
  );
};
SingleField.defaultProps = { label: "Current Video", addLabel: true };

const ColorWater = (props) => {
  const [color, setColor] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const fs = useInput(props);
  // React.useState(() => {
  //   console.log("change");
  //   setColor(props.record.video.data[0]?.watermark_color);
  //   fs.input.onChange({
  //     target: { value: props.record.video.data[0]?.watermark_color },
  //   });
  // }, [props.record.video.data[0]?.watermark_color]);

  const handleChangeComplete = (colorpick, event) => {
    setColor(colorpick.hex);
    fs.input.onChange({ target: { value: colorpick.hex } });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "50px",
          height: "50px",
          backgroundColor:
            color !== "" ? color : props.record.video.data[0]?.watermark_color,
        }}
        onClick={() => {
          setColor(props.record.video.data[0]?.watermark_color);
          setOpen(true);
        }}
      ></div>
      <div style={{ marginLeft: "10px" }}>
        {color !== "" ? color : props.record.video.data[0]?.watermark_color}
      </div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        scroll="paper"
        onClose={() => {
          setOpen(false);
        }}
        // fullWidth={true}
      >
        <SketchPicker color={color} onChangeComplete={handleChangeComplete} />
      </Dialog>
    </div>
  );
};
ColorWater.defaultProps = { label: "Current Video", addLabel: true };

const ColorWaterCreate = (props) => {
  const [color, setColor] = React.useState("#e8e8e8");
  const [open, setOpen] = React.useState(false);
  const fs = useInput(props);
  // React.useState(() => {
  //   console.log("change");
  //   setColor(props.record.video.data[0]?.watermark_color);
  //   fs.input.onChange({
  //     target: { value: props.record.video.data[0]?.watermark_color },
  //   });
  // }, [props.record.video.data[0]?.watermark_color]);

  const handleChangeComplete = (colorpick, event) => {
    setColor(colorpick.hex);
    fs.input.onChange({ target: { value: colorpick.hex } });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "50px",
          height: "50px",
          backgroundColor: color,
        }}
        onClick={() => {
          setOpen(true);
        }}
      ></div>
      <div style={{ marginLeft: "10px" }}>{color}</div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        scroll="paper"
        onClose={() => {
          setOpen(false);
        }}
        // fullWidth={true}
      >
        <SketchPicker color={color} onChangeComplete={handleChangeComplete} />
      </Dialog>
    </div>
  );
};
ColorWaterCreate.defaultProps = { label: "Current Video", addLabel: true };
