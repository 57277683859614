import React, { useEffect, useState } from "react";
import client from "../utils/mococlient";
// import randomstring from "crypto-random-string";
import logo from "../gca_1.png";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { redirectOnRoles } from "../utils/variabels";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import auth from "../Provider/authProvider";
import Snackbar from "@material-ui/core/Snackbar";
import isProd from "../utils/variabels";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function getparam(parameterName) {
  let result = null;
  let tmp = [];
  window.location.search
    .substr(1)
    .split("&")
    .forEach(function (item) {
      tmp = item.split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
}

const LoginPage = ({ theme }) => {
  // const client = new moco.init("http://localhost:3000");
  const cbUrl =
    window.location.host === "localhost:8080"
      ? `${window.location.protocol}//${window.location.host}/login/`
      : `${window.location.protocol}//${window.location.host}/login`;
  console.log(cbUrl);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  // const idfs = async () => {
  //   const code_verifier = randomstring({ type: "url-safe", length: 50 });
  //   sessionStorage.setItem("MyApp.code_verifier", code_verifier);

  //   const res = await client.auth.oauthRedirect(cbUrl, {
  //     code_verifier: code_verifier,
  //     open_select_account: true,
  //   });
  //   console.log(res);
  // };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const verify = async () => {
    const code = getparam("code");
    const at = getparam("at");
    const rt = getparam("rt");
    // console.log(code);
    // console.log(at);
    // console.log(rt);

    const thisUrl = new URL(window.location.href);

    if (code !== null) {
      setLoading(true);
      try {
        const data = await client.auth.verifyOauth(
          cbUrl,
          thisUrl.searchParams.get("code"),
          {
            code_verifier: sessionStorage.getItem("MyApp.code_verifier"),
            include_userinfo_claims: true,
          },
          async (data) => {
            const { local_profile, tokenset } = data.data.attributes;
            const hasAccess = (elem) => ["Tutor"].includes(elem);
            const hasAccess2 = (elem) => ["Student"].includes(elem);

            if (local_profile.created && local_profile.roles.some(hasAccess)) {
              window.location.href = `${isProd.studentWeb}/verify?at=${tokenset.access_token}&rt=${tokenset.refresh_token}`;
              console.log("TUTOR");
            } else if (
              local_profile.created &&
              local_profile.roles.some(hasAccess2)
            ) {
              window.location.href = `${isProd.studentWeb}/verify?at=${tokenset.access_token}&rt=${tokenset.refresh_token}`;
              console.log("STUDENT");
            }
            return true;
          }
        );

        console.log(data);
        if (!data.error) {
          const { data: profileData } = await client.user.get({
            include_userinfo_claims: true,
          });

          console.log(profileData);

          if (profileData) {
            localStorage.setItem("permission", JSON.stringify(profileData));
          }
        } else {
          console.log("data null");
        }
        window.location.replace("/");
        // if (window.location.host === "localhost:8080") {
        //   window.location.replace("/");
        // } else {
        // const { roles } = profileData.local_profile;
        // const role = roles[0];
        // console.log(role);
        // const redirected = redirectOnRoles(role);
        // console.log(redirected);
        // if (redirected === false) {
        //   // login();
        //   if (profileData) {
        //     localStorage.setItem("permission", JSON.stringify(profileData));
        //   }
        //   window.location.replace("/");
        // }
        setLoading(false);
      } catch (e) {
        console.log(e);
        // setCallbackMode(false);
        // idfs();
        setLoading(false);
      }
    } else if (at !== null && rt !== null) {
      setLoading(true);

      client.saveTokens(at, rt);
      localStorage.setItem("MBaaS.auth.accessToken", at);
      localStorage.setItem("MBaaS.auth.refreshToken", rt);

      // try get profile
      const { data: profileData } = await client.user.get();
      console.log("profile =>" + profileData);

      if (profileData) {
        localStorage.setItem("permission", JSON.stringify(profileData));
        window.location.replace("/");
      } else {
        // eslint-disable-next-line no-unused-vars
        setError(true);
        const logout = await auth.logout();
        console.log(logout);
        // idfs();
      }

      setLoading(false);
    } else {
      //if user already login and try access again login page
      if (localStorage.getItem("permission")) {
        window.location.replace("/");
      } else {
        //redirect to idfs page
        // idfs();
      }
    }
  };

  useEffect(() => {
    verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const newLogin = async () => {
    if (window.location.hostname === "localhost") {
      setLoading(true);
      const login = await client.auth.login("local", {
        email: "staff1@mylib.id",
        password: "12QWaszx",
      });
      console.log(login);
      const { data: profileData } = await client.user.get();
      localStorage.setItem("permission", JSON.stringify(profileData));
      setLoading(false);
      window.location.replace("/");
    } else {
      window.location.replace(isProd.loginUI);
    }
  };
  return (
    <div>
      <Snackbar
        open={error}
        autoHideDuration={3000}
        onClose={() => setError(false)}
      >
        <Alert onClose={() => setError(false)} severity="error">
          Invalid Login Access
        </Alert>
      </Snackbar>
      <Grid
        container
        direction="column"
        alignItems="center"
        alignContent="center"
        style={{ height: "200px", marginTop: "200px" }}
      >
        <Grid item>
          <img src={logo} alt="logo" style={{ width: "300px" }} />
        </Grid>
        <Grid item style={{ margin: "10px" }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              color="primary"
              variant="contained"
              // onClick={idfs}
              onClick={newLogin}
              startIcon={
                <FontAwesomeIcon
                  icon={faSignInAlt}
                  style={{ color: "white" }}
                />
              }
            >
              Login
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginPage;
